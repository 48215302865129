import umbraco from '~/lib/api/umbraco';
import { UmbracoTypes } from '~/lib/data-contract';
import { Umbraco } from '~/services';

export default function getMitFogNavigation(
    signal: AbortSignal | undefined,
    token: string | null,
    customerId: string | null,
) {
    const url = Umbraco.utils.getUrl(`/content/frame/navigation/mit-fog`);
    return umbraco
        .get(url, {
            signal,
            searchParams: {
                ...(customerId && { customerId }),
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .json<UmbracoTypes.INavigationNode>();
}
