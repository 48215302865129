import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { UmbracoTypes } from '~/lib/data-contract';
import { useNavigation } from '~/services';
import { Link } from '~/shared/components/Link';
import { Container, List, ListItem } from './styled';

const Navigation = () => {
    const { data: navigation } = useNavigation();
    const { query } = useRouter();
    const slug = query?.slug as string[];
    const activeUrl = `/${slug?.join('/')}`;

    const items = useMemo(() => {
        if (navigation) {
            const levelNodesToDisplay = findNodesToDisplay(navigation, activeUrl, slug);
            if (levelNodesToDisplay) {
                return levelNodesToDisplay;
            }
        }
    }, [navigation, slug, activeUrl]);

    return (
        <Container>
            <List>
                {items?.map((item, index) => (
                    <ListItem key={`${item.id}-${index}`} isActive={item.link.url === activeUrl}>
                        <NextLink prefetch={false} href={item.link.url} passHref legacyBehavior>
                            <Link
                                animation={item.link.url === activeUrl ? 'static' : 'reverse'}
                                title={item.link.title}
                            >
                                {item.link.text}
                            </Link>
                        </NextLink>
                    </ListItem>
                ))}
            </List>
        </Container>
    );
};

export default Navigation;

function findNodesToDisplay(
    navigation: UmbracoTypes.INavigationNode,
    activeUrl: string,
    slug: string[],
) {
    const activeLevel = slug.length;
    const level3Nodes = navigation.children;

    if (!level3Nodes) {
        return;
    }

    switch (activeLevel) {
        case 2:
            return level3Nodes;
        case 3: {
            const level4Nodes = level3Nodes.find((item) => item.link.url === activeUrl)?.children;
            const level3NodesHaveChildren = level4Nodes?.length;

            if (!level3NodesHaveChildren) {
                return level3Nodes;
            }

            return level4Nodes;
        }

        case 4: {
            const level5Nodes = level3Nodes
                .flatMap((level4Node) => {
                    if (level4Node.children?.length) {
                        const foundGrandChild = level4Node.children.find(
                            (level4Node) => level4Node.link.url === activeUrl,
                        );

                        if (foundGrandChild) {
                            return foundGrandChild.children;
                        }
                    }
                })
                // This is a way to make TypeScript happy
                .filter((item): item is UmbracoTypes.INavigationNode => !!item);
            const level4NodesHaveChildren = level5Nodes?.length;

            if (!level4NodesHaveChildren) {
                const level3NodeUrl = activeUrl.split('/').splice(0, 4).join('/');
                const level4Nodes = level3Nodes.find(
                    (item) => item.link.url === level3NodeUrl,
                )?.children;
                return level4Nodes;
            }

            return level5Nodes;
        }

        case 5: {
            const level4NodeUrl = activeUrl.split('/').splice(0, 5).join('/');
            const level5Nodes = level3Nodes
                .flatMap((level4Node) => {
                    if (level4Node.children?.length) {
                        const foundGrandChild = level4Node.children.find(
                            (level4Node) => level4Node.link.url === level4NodeUrl,
                        );

                        if (foundGrandChild) {
                            return foundGrandChild.children;
                        }
                    }
                })
                // This is a way to make TypeScript happy
                .filter((item): item is UmbracoTypes.INavigationNode => !!item);

            return level5Nodes;
        }

        default:
            return;
    }
}
