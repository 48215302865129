import { HTTPError } from 'ky';
import { useQuery } from '@tanstack/react-query';
import { ShopTypes } from '~/lib/data-contract';
import { Prefetch } from '~/services';
import { addHours, differenceInMilliseconds, startOfTomorrow } from 'date-fns';
import pricesBatcher from '../batchers/price';

export default function usePrice(
    id: string,
    enabled = true,
    customerId?: string,
    token?: string | null,
) {
    // tomorrow at 5 AM if logged in
    const cacheTime =
        !!customerId && !!token
            ? differenceInMilliseconds(addHours(startOfTomorrow(), 6), new Date())
            : undefined;

    return useQuery<ShopTypes.PriceWithCampaigns, HTTPError | Error>(
        ['price', { customerId, id }],
        () => pricesBatcher.fetch({ sku: id, customerId:  customerId || null, token }),
        {
            enabled: enabled && Boolean(id),
            retry: 1,
            cacheTime,
        },
    );
}

export function prefetchPrices({ ids, queryClient }: Props) {
    if (!ids) return Promise.reject();

    return Promise.all(
        ids?.map((id) =>
            queryClient.prefetchQuery(['price', { id, customerId: null }], () =>
                pricesBatcher.fetch({ sku: id, customerId: null, token: null }),
            ),
        ),
    );
}

interface Props extends Prefetch {
    ids: string[] | undefined;
}
