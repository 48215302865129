import { Loop54 } from '~/services';
import { useUserId } from '~/shared/utils/cookie';
import useRecentEntities from '../useRecentEntities';

export default function useRecentResults() {
    const [userId] = useUserId();
    return useRecentEntities(
        {
            behaviorType: 'click',
            entityType: ['Product'],
            forUserId: userId,
            resultsOptions: {
                skip: 0,
                take: 10,
            },
        },
        (data) => ({
            ...data,
            results: Loop54.utils.reduceLoop54Product(data.results),
        }),
    );
}
