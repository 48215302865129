import { useEffect } from 'react';
import { getCookieConsent } from '~/shared/utils/cookie/consent';
import { useCookieConsentState } from '~/state/useCookieConsentState';
import { Cookie } from '../cookie';
import { useCookie } from '../utils';

export const useWatchCookieConsent = () => {
    const { setConsent } = useCookieConsentState();
    const [consentCookie] = useCookie({ key: Cookie.cookieConsent });

    return useEffect(() => {
        setConsent(getCookieConsent());
    }, [consentCookie, setConsent]);
};
