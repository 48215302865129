import { ShopTypes } from '~/lib/data-contract';
import { getGABasketItems } from '../utils/getGABasketItems';
import { pushToDataLayer } from '../utils/pushToDataLayer';
import { GAEcommerceEvent } from '../utils/types';

export default function addPaymentInfo(
    basket: ShopTypes.BasketResponse | undefined,
    paymentType?: string,
) {
    if (!basket || !paymentType) {
        return;
    }

    pushToDataLayer({ ecommerce: null });
    pushToDataLayer({
        event: GAEcommerceEvent.AddPaymentInfo,
        ecommerce: {
            currency: 'DKK',
            payment_type: paymentType,
            value: basket.subTotal.priceInclVat,
            items: getGABasketItems(basket.contexts),
        },
    });
}
