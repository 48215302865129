import { useEffect, useRef, useState } from 'react';
import ChevronDown from '~/icons/iconography/chevron/down.svg';
import { Loop54Types } from '~/lib/data-contract';
import { useTranslations } from '~/services';
import { Button } from '~/shared/components/Button';
import { MultipleSelector } from '~/shared/components/MultipleSelector';
import { useUrlQueryState } from '~/shared/hooks';
import { PriceRange } from '../PriceRange';
import { getTranslatedFacetName, getTranslatedFacetValue } from '../helpers/filterHelper';
import {
    Container,
    FilterItem,
    RangeToggleButton,
    ShowAllFiltersButton,
    SkeletonWrapper,
    SkeletonWrapperTablet,
} from './styled';
import gtm from '~/shared/tracking/gtm';
import { Skeleton } from '~/shared/components/SkeletonLoader/styled';
import Plus from '~/icons/iconography/plus.svg';
import { useSession } from 'next-auth/react';

export type FilterProps = {
    label: string;
    value: string;
    count?: number;
};

type Props = {
    className?: string;
    facets: (Loop54Types.DistinctFacet | Loop54Types.RangeFacet)[] | undefined;
    showCount?: boolean;
};

export default function Filters({ className, facets, showCount = true }: Props) {
    const { status } = useSession();
    const { translate } = useTranslations();
    const [showAllFilters, setShowAllFilters] = useState(false);
    const [openRangeFacetName, setOpenRangeFacetName] = useState<string | undefined>();
    const rangeFacetRef = useRef<HTMLLIElement>(null);
    const [selectedFilters, setSelectedFilters] = useUrlQueryState();

    // Close facet if start of mouse click detected outside open facet
    useEffect(() => {
        const clickOutsideHandler = ({ target }: MouseEvent) => {
            if (!rangeFacetRef?.current?.contains(target as Element)) {
                setOpenRangeFacetName(undefined);
            }
        };

        window.addEventListener('mousedown', clickOutsideHandler);

        return () => {
            window.removeEventListener('mousedown', clickOutsideHandler);
        };
    }, [rangeFacetRef]);

    const onSelectedFilterHandler =
        (facetName?: string, facetDisplayName?: string) => (selected: FilterProps[]) => {
            if (facetName) {
                if (
                    (selectedFilters[facetName]?.length ?? 0) < selected.length &&
                    facetDisplayName
                ) {
                    gtm.custom.filters(facetDisplayName);
                }

                setSelectedFilters(
                    facetName,
                    selected.map((filter) => filter.value),
                );
            }
        };

    // Remove Price filter when logged in
    const filteredFacets =
        status === 'unauthenticated'
            ? facets
            : facets?.filter((facet) => facet.name !== 'PriceInclVat');

    const facetElementList = filteredFacets?.map((facet, index) => {
        switch (facet.type) {
            case 'distinct': {
                const distinctFacet = facet as Loop54Types.DistinctFacet;
                const facetDisplayName = getTranslatedFacetName(distinctFacet.name, translate);
                const items =
                    distinctFacet.items?.map((item) => ({
                        count: showCount ? item.count : 0,
                        label:
                            getTranslatedFacetValue(
                                distinctFacet.name,
                                item.item?.toString(),
                                translate,
                            ) ?? '',
                        value: item.item?.toString() ?? '',
                    })) ?? [];

                return (distinctFacet?.items?.length || 0) < 1 ? null : (
                    <FilterItem showAllFilters={showAllFilters} key={distinctFacet.name}>
                        <MultipleSelector
                            id={distinctFacet.name ?? `multiple-selector-${index}`}
                            options={items}
                            initialSelectedItems={
                                distinctFacet.name ? selectedFilters[distinctFacet.name] : []
                            }
                            label={facetDisplayName ?? 'distinct'}
                            onSelectedItemsChange={onSelectedFilterHandler(
                                distinctFacet.name,
                                facetDisplayName,
                            )}
                        />
                    </FilterItem>
                );
            }

            case 'range': {
                const rangeFacet = facet as Loop54Types.RangeFacet;
                const isOpen = openRangeFacetName === rangeFacet.name;
                const facetDisplayName = getTranslatedFacetName(rangeFacet.name, translate);
                const onClick = () =>
                    isOpen
                        ? setOpenRangeFacetName(undefined)
                        : setOpenRangeFacetName(rangeFacet.name);
                const onClose = (selectedFilter?: string) => {
                    setOpenRangeFacetName(undefined);

                    if (selectedFilter && facetDisplayName) {
                        gtm.custom.filters(facetDisplayName);
                    }
                };

                return (
                    <FilterItem
                        ref={rangeFacetRef}
                        showAllFilters={showAllFilters}
                        key={rangeFacet.name}
                    >
                        <RangeToggleButton
                            color="#101820"
                            customIcon={<ChevronDown />}
                            isOpen={isOpen}
                            onClick={onClick}
                            type="button"
                            variant="stroke"
                        >
                            {facetDisplayName}
                        </RangeToggleButton>

                        {!isOpen ? null : (
                            <PriceRange
                                min={rangeFacet.min as number}
                                max={rangeFacet.max as number}
                                name={rangeFacet.name ?? 'range'}
                                onClose={onClose}
                            />
                        )}
                    </FilterItem>
                );
            }

            default: {
                return null;
            }
        }
    });

    return (
        <Container className={className}>
            {facetElementList ? facetElementList : <FilterSkeleton />}
            {!showAllFilters ? (
                <ShowAllFiltersButton totalAmountOfFilters={facets?.length}>
                    <Button
                        onClick={() => {
                            setShowAllFilters(true);
                        }}
                        fullWidth
                        customIcon={<Plus />}
                    >
                        {translate('navigation.mobile.mainMenu.viewAll')}{' '}
                        {translate('navigation.filters')}
                    </Button>
                </ShowAllFiltersButton>
            ) : null}
        </Container>
    );
}

function FilterSkeleton() {
    return (
        <>
            {Array.from({ length: 2 }).map((_, index) => (
                <SkeletonWrapper key={index}>
                    <FilterItem showAllFilters={true} key={index}>
                        <Skeleton width={187} height={40} />
                    </FilterItem>
                </SkeletonWrapper>
            ))}
            {Array.from({ length: 2 }).map((_, index) => (
                <SkeletonWrapperTablet key={index}>
                    <FilterItem showAllFilters={true} key={index}>
                        <Skeleton width={187} height={40} />
                    </FilterItem>
                </SkeletonWrapperTablet>
            ))}
        </>
    );
}
