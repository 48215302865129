import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { Text } from '$shared/components/Text';
import { breakpoints } from '~/theme';

export const PriceWrapper = styled.span(() => ({
    display: 'flex',
    flexDirection: 'column',

    [breakpoints.md]: {
        alignItems: 'flex-start',
    },
}));

export const Amount = styled.span(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.space[2],
    alignItems: 'baseline',

    [breakpoints.md]: {
        flexDirection: 'row-reverse',
    },
}));

export const NormalPrice = styled(Text)(({ theme }) => ({
    textAlign: 'right',
    textDecoration: 'line-through',
    fontSize: theme.fontSizes.beetle,
    lineHeight: theme.lineHeights.chicken,
    letterSpacing: -0.2,
}));

export const BuyPrice = styled(Text)<{ discounted?: boolean }>(
    ({ theme }) => ({
        fontSize: theme.fontSizes.chicken,
        fontWeight: theme.fontWeights.medium,
        lineHeight: theme.lineHeights.dog,
        letterSpacing: -0.46,

        [breakpoints.md]: {
            fontSize: theme.fontSizes.cat,
            lineHeight: theme.lineHeights.wolf,
        },
    }),
    ifProp('discounted', ({ theme }) => ({
        color: theme.colors.alert,
    })),
);

export const SalesUnit = styled.p(({ theme }) => ({
    opacity: 0.5,
    marginTop: -2,
    color: theme.colors.thunderBlack,
    fontSize: theme.fontSizes.beetle,
    lineHeight: theme.lineHeights.mouse,
    display: 'flex',
    alignItems: 'center',
    maxWidth: 90,
    minHeight: 25,

    [breakpoints.md]: {
        marginTop: 3,
        maxWidth: 100,
    },
}));

export const Stock = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: 22,

    [breakpoints.md]: {
        gap: theme.space[1],
        flexDirection: 'column',
    },
}));

export const SplashesWrapper = styled.span({
    position: 'absolute',
    overflow: 'hidden',
    left: 0,
    top: 0,
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
});

export const Splash = styled.span(({ theme }) => ({
    whiteSpace: 'nowrap',
    fontSize: theme.fontSizes.ant,
    lineHeight: theme.lineHeights.beetle,
    padding: '3px 8px',
    letterSpacing: -0.23,

    [breakpoints.md]: {
        fontSize: theme.fontSizes.beetle,
        lineHeight: theme.lineHeights.mouse,
        padding: '4px 10px',
        letterSpacing: -0.29,
    },
}));
