import loop54 from '~/lib/api/loop54';
import { Loop54Types } from '~/lib/data-contract';
import { Loop54 } from '~/services';

export default function getSearch(
    signal: AbortSignal | undefined,
    params: Loop54Types.SearchRequest
) {
    return loop54
        .post(Loop54.utils.getUrl('/search'), {
            json: params,
            signal,
        })
        .json<Loop54Types.SearchResponse>();
}
