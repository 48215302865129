import { useCombobox } from 'downshift';
import { useEffect, useMemo, useState } from 'react';
import ChevronDown from '~/icons/iconography/chevron/down.svg';
import ClearIcon from '~/icons/iconography/close.svg';
import LoopIcon from '~/icons/search.svg';
import { DropdownItem } from './models';
import {
    ClearButton,
    Input,
    InputContainer,
    Item,
    ItemCheckbox,
    ItemCount,
    ItemLabel,
    ItemLeftContainer,
    List,
    SearchFilter,
    StyledButton,
} from './styled';

type MultipleSelectorProps = {
    options: DropdownItem[];
    initialSelectedItems?: string[];
    label: string;
    noLabel?: boolean;
    id: string;
    onSelectedItemsChange?: (items: DropdownItem[]) => void;
};

export default function MultipleSelector({
    options,
    label,
    id,
    initialSelectedItems,
    noLabel = false,
    onSelectedItemsChange,
}: MultipleSelectorProps) {
    const [inputValue, setInputValue] = useState<string>('');
    const [selectedItems, setSelectedItems] = useState<DropdownItem[]>(
        options.filter((item) => initialSelectedItems?.includes(item.value)),
    );

    const items = useMemo(() => {
        return options.filter((item) =>
            item.label.toLowerCase().includes(inputValue?.toLowerCase() ?? ''),
        );
    }, [options, inputValue]);

    const {
        getItemProps,
        getMenuProps,
        getToggleButtonProps,
        getLabelProps,
        getInputProps,
        highlightedIndex,
        isOpen,
    } = useCombobox({
        items,
        itemToString: (item) => item?.label ?? '',
        id,
        inputValue,
        stateReducer: ({ highlightedIndex }, { changes, type }) => {
            switch (type) {
                case useCombobox.stateChangeTypes.InputClick:
                    return {
                        ...changes,
                        isOpen: true,
                    };
                case useCombobox.stateChangeTypes.ItemClick:
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                    return {
                        ...changes,
                        isOpen: true,
                        highlightedIndex,
                    };
                default:
                    return changes;
            }
        },
        onStateChange({ inputValue: newInputValue, type, selectedItem: newSelectedItem }) {
            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                    if (!newSelectedItem) {
                        break;
                    }
                    const itemIsDeselected = selectedItems.some(
                        (item) => item.value === newSelectedItem.value,
                    );

                    const newSelectedItemList = itemIsDeselected
                        ? selectedItems.filter((item) => item.value !== newSelectedItem.value)
                        : [...selectedItems, newSelectedItem];

                    setSelectedItems(newSelectedItemList);
                    onSelectedItemsChange?.(newSelectedItemList);
                    break;

                case useCombobox.stateChangeTypes.InputChange:
                    setInputValue(newInputValue ?? '');
                    break;
                default:
                    break;
            }
        },
    });

    // Update selectedItems state on props change
    useEffect(() => {
        setSelectedItems(items.filter((item) => initialSelectedItems?.includes(item.value)));
    }, [initialSelectedItems, items]);

    const isListHidden = !isOpen && !noLabel;
    const isSearchFilterHidden = options.length < 8 || isListHidden;

    return (
        <>
            <StyledButton
                {...getToggleButtonProps()}
                isOpen={isOpen}
                noLabel={noLabel}
                tabIndex={0}
            >
                <span {...getLabelProps()}>{label}</span>
                <ChevronDown />
            </StyledButton>

            <List
                aria-hidden={isListHidden}
                {...getMenuProps()}
                isHidden={isListHidden}
                noLabel={noLabel}
            >
                <SearchFilter aria-hidden={isSearchFilterHidden} isHidden={isSearchFilterHidden}>
                    <InputContainer>
                        <LoopIcon />
                        <Input type="text" placeholder={`Søg i ${label}`} {...getInputProps()} />
                        {inputValue ? (
                            <ClearButton onClick={() => setInputValue('')}>
                                <ClearIcon />
                            </ClearButton>
                        ) : null}
                    </InputContainer>
                </SearchFilter>
                {items.map((item, index) => (
                    <Item
                        highlighted={highlightedIndex === index}
                        key={item.label}
                        {...getItemProps({
                            item,
                            index,
                        })}
                    >
                        <ItemLeftContainer>
                            <ItemCheckbox
                                type="checkbox"
                                checked={selectedItems.some(
                                    (selected) => selected.value === item.value,
                                )}
                                readOnly
                                value={item.value}
                            />
                            <ItemLabel>{item.label}</ItemLabel>
                        </ItemLeftContainer>

                        {item.count ? <ItemCount>({item.count})</ItemCount> : null}
                    </Item>
                ))}
            </List>
        </>
    );
}
