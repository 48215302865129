import { ShopTypes } from '~/lib/data-contract';
import { useBasket } from '~/services';

export default function useCustomerAddress() {
    return useBasket<ShopTypes.CustomerAddressDetails>((data) => ({
        ...data?.customerAddress,
        newsletterSignup: data?.newsletterSignUp,
        termsAccepted: data?.termsAccepted,
    }));
}
