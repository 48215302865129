import { transform } from 'framer-motion';
import { FunctionComponent } from 'react';
import { theme } from '~/theme';
import { settings } from '../../settings';
import { RangeValue } from '../../types';
import { Background, Container } from './styled';

export type ColorOptions = typeof theme.colors.grey40 | typeof theme.colors.thunderBlack;

export const ProgressBar: FunctionComponent<{
    color: ColorOptions;
    value: RangeValue;
    min: number;
    max: number;
    isBg?: boolean;
}> = ({ color, value, min, max, isBg }) => {
    const [left, right] = value.map((n) => transform(n, [min, max], [0, 100]));

    return (
        <Container
            style={{
                left: isBg ? `calc(${left}% - ${settings.buttonSize / 2}px)` : `${left}%`,
                right: isBg
                    ? `calc(${100 - right}% - ${settings.buttonSize / 2}px)`
                    : 100 - right + '%',
            }}
        >
            <Background color={color} />
        </Container>
    );
};
