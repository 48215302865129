import umbraco from '~/lib/api/umbraco';
import { UmbracoTypes } from '~/lib/data-contract';
import { Umbraco } from '~/services';

export default function getFrameNavigation(signal: AbortSignal | undefined, levels: number) {
    const url = Umbraco.utils.getUrl('/content/frame/frame-navigation');
    url.searchParams.append('levels', String(levels));
    url.searchParams.append('url', '/');

    return umbraco.get(url, { signal }).json<UmbracoTypes.INavigation>();
}
