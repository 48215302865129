import { HTTPError } from 'ky';
import { useQuery } from '@tanstack/react-query';
import { Loop54Types } from '~/lib/data-contract';
import { Loop54, Prefetch } from '~/services';
import { hours, seconds } from '~/shared/utils/helpers';

export default function useComplementaryEntities<SelectType = Response>(
    params: Loop54Types.GetComplementaryEntitiesRequest,
    select?: (data: Response) => SelectType,
) {
    return useQuery<Response, HTTPError | Error, SelectType>(
        ['complementaryEntities', params],
        ({ signal }) => Loop54.operations.getComplementaryEntities(signal, params),
        {
            enabled: !!params,
            select,
            retry: 1,
            keepPreviousData: true,
            cacheTime: 0, // Disable cache so that we get personalized result every time page is requested
            staleTime: hours(1), // Keep personalized result for 1 hour
            refetchOnWindowFocus: false, // Never refetch on window focus since that will cause the products to change position
        },
    );
}

export function prefetchComplementaryEntities({ queryClient }: Prefetch, productId: string) {
    const params: Loop54Types.GetComplementaryEntitiesRequest = {
        entity: {
            type: 'Product',
            id: productId,
        },
        resultsOptions: {
            sortBy: [{ type: 'relevance' }],
            take: 10,
        },
    };

    return queryClient.prefetchQuery(
        ['complementaryEntities', params],
        ({ signal }) => Loop54.operations.getComplementaryEntities(signal, params),
        {
            cacheTime: seconds(10),
            staleTime: hours(1),
        },
    );
}

type Response = Loop54Types.GetComplementaryEntitiesResponse;
