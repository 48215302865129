import { getProductFilter } from '~/features/ProductList/utils';
import { UmbracoTypes } from '~/lib/data-contract';
import { Loop54, Loop54ProductAttributes, useCampaignEntities } from '~/services';

type Props = Pick<
    UmbracoTypes.IM30CampaignProductCarouselModule,
    'amountOfProducts' | 'campaignID' | 'sorting' | 'ascendingOrder' | 'onlyShowInStock'
>;

type filter = {
    type: string;
    attributeName?: Loop54ProductAttributes;
    value?: string | boolean;
};

export default function useCampaignResults({
    amountOfProducts,
    campaignID,
    sorting,
    ascendingOrder,
    onlyShowInStock,
}: Props) {
    const filters: filter[] = [
        getProductFilter(),
        {
            type: 'attribute',
            attributeName: Loop54ProductAttributes.CampaignLabelDescription,
            value: campaignID,
        },
    ];

    if (onlyShowInStock) {
        filters.push({
            type: 'attribute',
            attributeName: Loop54ProductAttributes.InStock,
            value: onlyShowInStock,
        });
    }

    return useCampaignEntities(
        {
            resultsOptions: {
                filter: {
                    and: filters,
                },
                sortBy: [
                    {
                        type: sorting,
                        order: ascendingOrder ? 'asc' : 'desc',
                        ...(sorting === 'attribute' && {
                            attributeName: Loop54ProductAttributes.ItemName,
                        }),
                    },
                ],
                take: amountOfProducts ?? 10,
            },
        },
        (data) => ({
            ...data,
            results: Loop54.utils.reduceLoop54Product(data.results),
        }),
    );
}
