import { useTheme } from '@emotion/react';
import { useMemo } from 'react';
import { getPotentialThemeColor } from '../utils/getPotentialThemeColor';
import type { PotentialThemeColorType } from '../model/themeModel';

/**
 * Hook for looking up colors in the theme colors.
 * If color not a valid color token it will return the color
 */
export const usePotentialThemeColor = (color: PotentialThemeColorType) => {
    const theme = useTheme();
    return useMemo(() => getPotentialThemeColor(color, theme), [color]);
};
