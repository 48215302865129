import { getProductFilter } from '~/features/ProductList/utils';
import { UmbracoTypes } from '~/lib/data-contract';
import { Prefetch } from '~/services/types';
import { Loop54ProductAttributes } from '../types';
import {
    buildPreFetcherForEntities,
    useEntitiesOrEntitiesByAttribute,
    type EntitiesOrEntitiesByAttributeRequest,
    type EntitiesOrEntitiesByAttributeResponse,
} from './useEntitiesOrEntitiesByAttributes';

const queryKey = 'campaignEntities';

export default function useCampaignEntities<SelectType = EntitiesOrEntitiesByAttributeResponse>(
    params: EntitiesOrEntitiesByAttributeRequest | undefined,
    select?: (data: EntitiesOrEntitiesByAttributeResponse) => SelectType
) {
    return useEntitiesOrEntitiesByAttribute(queryKey, params, select);
}

type PrefetchCampaignEntities = Pick<
    UmbracoTypes.IM30CampaignProductCarouselModule,
    'amountOfProducts' | 'campaignID' | 'sorting' | 'ascendingOrder'
> &
    Prefetch;

export function prefetchCampaignEntities({
    queryClient,
    amountOfProducts,
    campaignID,
    ascendingOrder,
    sorting,
}: PrefetchCampaignEntities) {
    const params: EntitiesOrEntitiesByAttributeRequest = {
        resultsOptions: {
            filter: {
                and: [
                    getProductFilter(),
                    {
                        type: 'attribute',
                        attributeName: Loop54ProductAttributes.InStock,
                        value: true,
                    },
                    {
                        type: 'attribute',
                        attributeName: Loop54ProductAttributes.CampaignLabelDescription,
                        value: campaignID,
                    },
                ],
            },
            sortBy: [
                {
                    type: sorting,
                    order: ascendingOrder ? 'asc' : 'desc',
                    ...(sorting === 'attribute' && {
                        attributeName: Loop54ProductAttributes.ItemName,
                    }),
                },
            ],
            take: amountOfProducts ?? 10,
        },
    };

    return buildPreFetcherForEntities({ queryKey, params, queryClient });
}
