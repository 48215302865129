import { useQuery } from '@tanstack/react-query';
import { HTTPError } from 'ky';
import { ShopTypes } from '~/lib/data-contract';
import { Prefetch, Shop } from '~/services';
import { hours, seconds } from '~/shared/utils/helpers';

export default function useCategories() {
    return useQuery<ShopTypes.CategoryQueryResult, HTTPError | Error>(
        ['categories'],
        () => Shop.operations.getCategories(),
        {
            enabled: true,
            staleTime: hours(1),
            cacheTime: seconds(30),
            retry: 1,
        },
    );
}

export async function prefetchCategories({ queryClient }: Prefetch) {
    return queryClient.fetchQuery(['categories'], () => Shop.operations.getCategories());
}
