import shop from '~/lib/api/shop';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';

type Args = {
    signal: AbortSignal | undefined;
    token: string | null;
};

export default function getUserCustomers({ signal, token }: Args) {
    const url = Shop.utils.getUrl('/customers/me');
    return shop
        .get(url, {
            signal,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .json<ShopTypes.SimpleCustomerListResponse>();
}
