import shop from '~/lib/api/shop';
import { Shop } from '~/services';

type Args = {
    customerId: string | null;
    userId: string | null;
    token: string | null;
};

export default function deleteUser({ userId, customerId, token }: Args) {
    const url = Shop.utils.getUrl(`/customers/${customerId}`);

    if (userId) {
        url.searchParams.append('userId', userId);
    }

    return shop
        .delete(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .json<string>();
}
