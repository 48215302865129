import dawa from '~/lib/api/dawa';
import { DawaTypes } from '~/lib/data-contract';
import { Dawa } from '~/services';

export default function getPostalCodeInformation(postalCode: number) {
    if (!postalCode) {
        return Promise.reject(new Error(Dawa.errors.missingPostalCode.message));
    }

    const url = Dawa.utils.getUrl(`/postnumre/${postalCode}`);

    return dawa.get(url).json<DawaTypes.PostnumreListParams>();
}
