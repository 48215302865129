import { ShopTypes } from '~/lib/data-contract';
import { GAEcommerceItem } from './types';
import { getGABasketItem } from './getGABasketItem';

export function getItemsByFreight(freight: ShopTypes.BasketFreightResponse | undefined) {
    const items: GAEcommerceItem[] = [];
    let index = 0;

    freight?.brands?.forEach(
        (brand) =>
            brand.items?.forEach((item) => {
                items.push(getGABasketItem(item, brand.name || '', index++));
            }),
    );

    return items;
}
