import { HTTPError } from 'ky';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { UmbracoTypes } from '~/lib/data-contract';
import { Umbraco, Prefetch } from '~/services';
import { minutes } from '~/shared/utils/helpers';

const defaultLevels = 3;

export default function useNavigation<SelectType = Navigation>(
    levels = defaultLevels,
    select?: (data: Navigation) => SelectType,
) {
    const { locale, query } = useRouter();
    const asPath = Umbraco.utils.getPathLevel(query.slug as string[], levels);

    return useQuery<Navigation, HTTPError | Error, SelectType>(
        ['navigation', { locale, asPath }],
        ({ signal }) => Umbraco.operations.getNavigation(signal, asPath),
        {
            retry: false,
            select,
            keepPreviousData: true,
            staleTime: minutes(10),
            cacheTime: minutes(1),
            refetchOnWindowFocus: true,
        },
    );
}

export async function prefetchNavigation({ locale, slug, queryClient }: PrefetchProps) {
    const asPath = Umbraco.utils.getPathLevel(slug, defaultLevels);

    return queryClient.fetchQuery(['navigation', { locale, asPath }], ({ signal }) =>
        Umbraco.operations.getNavigation(signal, asPath),
    );
}

interface PrefetchProps extends Prefetch {
    slug: string[] | undefined; // When supporting multiple languages, this will need to be changed
    levels?: number;
    locale: string | undefined;
}

type Navigation = UmbracoTypes.INavigationNode;
