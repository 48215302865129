import { useQuery } from '@tanstack/react-query';
import { HTTPError } from 'ky';
import { useSession } from 'next-auth/react';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';
import useActiveCustomerId from '~/shared/utils/cookie/hooks/useActiveCustomerId';

export default function useUserProfile() {
    const { data, update } = useSession();
    const [storedCustomerId, setStoredCustomerId] = useActiveCustomerId();
    const token = data?.accessToken ?? null;
    const customerId = storedCustomerId ?? null;

    return useQuery<ShopTypes.UserCustomerResponse, HTTPError | Error>(
        ['userProfile', { token, customerId }],
        ({ signal }) =>
            Shop.operations.getUserProfile({
                signal,
                customerId,
                token,
            }),
        {
            enabled: Boolean(data?.accessToken),
            onSuccess: (onSuccessData) => {
                if (onSuccessData?.customer?.id && !storedCustomerId) {
                    setStoredCustomerId(onSuccessData?.customer?.id);
                }
                update({ activeCustomerId: onSuccessData?.customer?.id });
            },
        },
    );
}
