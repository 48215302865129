import { ShopTypes } from '~/lib/data-contract';
import { GAEcommerceItem } from './types';
import { getGABasketItem } from './getGABasketItem';

export function getGABasketItems(basket: ShopTypes.BasketContextResponse[] | undefined) {
    const items: GAEcommerceItem[] = [];
    let index = 0;

    basket?.forEach(
        (context) =>
            context.freights?.forEach((freight) => {
                freight.brands?.forEach(
                    (brand) =>
                        brand.items?.forEach((item) => {
                            items.push(getGABasketItem(item, brand.name || '', index++));
                        }),
                );
            }),
    );

    return items;
}
