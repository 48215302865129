import { HTTPError } from 'ky';
import { useQuery } from '@tanstack/react-query';
import { Loop54Types } from '~/lib/data-contract';
import { Loop54 } from '~/services';
import { hours } from '~/shared/utils/helpers';

export const searchOptions = {
    keepPreviousData: true,
    cacheTime: 0, // Disable cache so that we get personalized result every time page is requested
    staleTime: hours(1), // Keep personalized result for 1 hour
    refetchOnWindowFocus: false, // Never refetch on window focus since that will cause the products to change position
};

export default function useSearch<SelectType = Response>(
    params: Loop54Types.SearchRequest,
    select?: (data: Response) => SelectType,
) {
    return useQuery<Response, HTTPError | Error, SelectType>(
        ['search', params],
        ({ signal }) => Loop54.operations.getSearch(signal, params),
        {
            enabled: Boolean(params.query),
            select,
            retry: 1,
            ...searchOptions,
        },
    );
}

type Response = Loop54Types.SearchResponse;
