import shop from '~/lib/api/shop';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';

type Args = {
    name: string | null;
    phoneNumber: string | null;
    token: string | null;
};

export default function setCustomUserProfile({ name, phoneNumber, token }: Args) {
    if (!name && !phoneNumber) {
        return Promise.reject(new Error(Shop.errors.referenceNotFound.message));
    }

    const url = Shop.utils.getUrl('/customers/users/me');

    return shop
        .put(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            json: { name, phoneNumber },
        })
        .json<ShopTypes.UserInformationDto>();
}
