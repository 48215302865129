import { Loop54 } from '~/services';
import useComplementaryEntities from '../useComplementaryEntities';

export default function useComplementaryResults(productId: string) {
    return useComplementaryEntities(
        {
            entity: {
                type: 'Product',
                id: productId,
            },
            resultsOptions: {
                sortBy: [{ type: 'relevance' }],
                take: 10,
            },
        },
        (data) => ({
            ...data,
            results: Loop54.utils.reduceLoop54Product(data.results),
        }),
    );
}
