// Default label formatter to account for float point imprecision.
// It will return number with fractional length matching step. See tests for details.
export const removeFloatPointImprecision =
    (step: number) =>
    (value: number): string => {
        // Get the fraction part of the passed number as string
        const fraction = String(step).split('.')[1] ?? '';

        // Fix value to fraction length
        const formattedNumberString = value.toFixed(fraction.length);

        return formattedNumberString;
    };
