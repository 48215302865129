import Cookies from 'js-cookie';
import shop from '~/lib/api/shop';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';
import { Cookie } from '~/shared/utils/cookie';

export default function getBasket(reference: string | undefined) {
    if (!reference) {
        return Promise.reject(new Error(Shop.errors.referenceNotFound.message));
    }

    return shop
        .get(Shop.utils.getUrl(`/baskets/${reference}`), {
            headers: {
                ...getCookieHeadersForBasket(),
            },
        })
        .json<ShopTypes.BasketResponse>();
}

function getCookieHeadersForBasket() {
    const clientId = Cookies.get(Cookie.ga4ClientId);
    const sessionId =
        Cookies.get(Cookie.ga4SessionIdProduction) ??
        Cookies.get(Cookie.ga4SessionIdQA) ??
        Cookies.get(Cookie.ga4SessionIdTest);

    if (!clientId || !sessionId) {
        return {};
    }

    return {
        'Client-Id': clientId,
        'Session-Id': sessionId,
    };
}
