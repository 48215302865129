import shop from '~/lib/api/shop';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';

export default async function getProduct(itemNo: string | undefined) {
    if (!itemNo) {
        return Promise.reject(new Error(Shop.errors.invalidProductItemNumber.message));
    }

    return shop
        .get(Shop.utils.getUrl(`/products/get/${itemNo}`), {
            hooks: {
                afterResponse: [
                    (_request, _options, response) => {
                        if (response.status === 404) {
                            return new Response(response.body, {
                                status: 200,
                                statusText: response.statusText,
                                headers: response.headers,
                            });
                        }

                        return response;
                    },
                ],
            },
        })
        .json<ShopTypes.ProductQueryResult & ShopTypes.ApiExceptionResponse>();
}
