import Script from 'next/script';
import { useEffect } from 'react';
import { useConfig } from '~/shared/hooks';

export function SmartSuppScript({ hide }: { hide: boolean }) {
    const { SMART_SUPP_KEY } = useConfig();

    useEffect(() => {
        const smartSupp = document.getElementById('smartsupp-widget-container');
        if (smartSupp) {
            smartSupp.style.display = hide ? 'none' : 'block';
        }
    }, [hide]);

    return (
        <>
            <Script type="text/javascript" strategy="lazyOnload" id="SmartSuppSetup">
                {`var _smartsupp = _smartsupp || {};
                _smartsupp.key = '${SMART_SUPP_KEY}';`}
            </Script>

            <Script
                id="SmartSuppScript"
                type="text/javascript"
                strategy="lazyOnload"
                async
                onLoad={() => smartsupp('group', 'WmU4DfUal3')}
                src="//www.smartsuppchat.com/loader.js"
            />
        </>
    );
}

declare function smartsupp(a: string, b: string): void;
