export type { ThemeType } from './theme';
export { theme } from './theme';

export {
    black,
    white,
    brandBlue,
    darkBlue,
    nightBlack,
    offWhite,
    grey90,
    grey70,
    grey60,
    grey50,
    grey40,
    grey30,
    grey10,
    grey05,
    steelBlue,
    petrolGreen,
    orange,
    mossDarkBrown,
    steelGrey,
    petrolGrey,
    mossLightBrown,
    thunderBlack,
    primary,
    secondary,
    success,
    warning,
    alert,
    campaign,
} from './colors';

export { breaks, breakpoints, queries } from './breakpoints';

export { ThemeProvider } from './context/ThemeProvider';

export { GlobalStyles } from './components/GlobalStyles/GlobalStyles';

export { usePotentialThemeColor } from './hooks/usePotentialThemeColor';

export { getTextColor } from './utils/getTextColor';

export { getPotentialThemeColor } from './utils/getPotentialThemeColor';

export type { PotentialThemeColorType } from './model/themeModel';
