import React from 'react';
import { ShopTypes } from '~/lib/data-contract';
import { StockStatus } from '~/shared/components/StockStatus';
import { StyledOnlineText } from './styled';

export interface ProductOnlineProps {
    text?: string;
    status?: ShopTypes.StockStatus;
}

export const ProductOnlineStatus = ({
    text,
    status = ShopTypes.StockStatus.Unavailable,
}: ProductOnlineProps) => (
    <StyledOnlineText variant="caption">
        <StockStatus status={status} />
        {text}
    </StyledOnlineText>
);
