import loop54 from '~/lib/api/loop54';
import { Loop54Types } from '~/lib/data-contract';
import { Loop54 } from '~/services';

export default function createEvents(event: Loop54Types.Event) {
    return loop54
        .post(Loop54.utils.getUrl('/createEvents'), {
            json: {
                events: [event],
            },
        })
        .json<Loop54Types.Response>();
}
