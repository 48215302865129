import { transform } from 'framer-motion';
import { useRef } from 'react';
import { theme } from '~/theme';
import { Knob, ProgressBar } from './components';
import { settings } from './settings';
import { Container } from './styled';
import { RangeValue } from './types';
import * as utils from './utils';

interface RangeProps {
    min?: number;
    max?: number;
    step?: number;
    valueRange: RangeValue;
    setValueRange: (valueRange: RangeValue) => void;
    labelledby?: string;
    labelFormatter?: (progress: number) => string;
}

export const RangeSlider = ({
    min = 0,
    max = 100,
    step = 1,
    valueRange,
    setValueRange,
    labelFormatter = utils.removeFloatPointImprecision(step),
}: RangeProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const refs = [useRef<HTMLButtonElement>(null), useRef<HTMLButtonElement>(null)];

    // Store index of currently active (focused) slider knob.
    const activeKnobIndex = useRef(-1);

    // Get numeric progress value [min-max] from pointer x value [0-window.innerWidth]
    const getProgress = (clientX: number): number => {
        if (!containerRef.current) {
            throw Error('No Container Ref Object');
        }

        const offsetLeft = containerRef.current.getBoundingClientRect().x;
        const { offsetWidth } = containerRef.current;

        // Get relative value [0-1]
        const containerLeft = clientX - offsetLeft - settings.buttonSize / 2;
        const relativeValue = containerLeft / (offsetWidth - settings.buttonSize);

        // Get absolute value [min-max]
        const absoluteValue = transform(relativeValue, [0, 1], [min, max]);

        return absoluteValue;
    };

    const updateValue = (index: number) => (progress: number) => {
        const value = valueRange.map((number, i) => {
            if (i !== index) {
                return number;
            }

            return utils.pipe(
                utils.preventOverlap(valueRange, i, min, max, step),
                utils.roundValue(step),
                (progress: number) => parseFloat(utils.removeFloatPointImprecision(step)(progress))
            )(progress);
        }) as RangeValue;

        setValueRange(value);
    };

    const updateProgress = (progress: number) => {
        const index = activeKnobIndex.current;
        if (index < 0) {
            throw Error('No Active Knob');
        }

        const activeRef = refs[index].current;
        if (!activeRef) {
            throw Error('No Knob Ref');
        }

        // Focus knob button element upon interaction
        activeRef?.focus();

        // Update value range
        updateValue(index)(progress);
    };

    const handleTapStart = ({ clientX }: PointerEvent) => {
        const progress = getProgress(clientX);
        const closestIndex = utils.getClosestIndex(progress, valueRange);
        activeKnobIndex.current = closestIndex;
        updateProgress(progress);
    };

    const handleClientX = ({ clientX }: PointerEvent) => {
        const progress = getProgress(clientX);
        updateProgress(progress);
    };

    return (
        <Container
            onTapStart={handleTapStart}
            onPan={handleClientX}
            onTap={handleClientX}
            ref={containerRef}
        >
            <ProgressBar color={theme.colors.grey40} value={[min, max]} min={min} max={max} isBg />
            <ProgressBar color={theme.colors.thunderBlack} value={valueRange} min={min} max={max} />

            {valueRange.map((value, index) => (
                <Knob
                    key={index}
                    progress={value}
                    setProgress={updateValue(index)}
                    ref={refs[index]}
                    min={min}
                    max={max}
                    step={step}
                    labelFormatter={labelFormatter}
                />
            ))}
        </Container>
    );
};
