import { useQuery } from '@tanstack/react-query';
import { HTTPError } from 'ky';
import { useRouter } from 'next/router';
import { UmbracoTypes } from '~/lib/data-contract';
import { Prefetch, Umbraco } from '~/services';
import { minutes } from '~/shared/utils/helpers';

const defaultLevels = 3;

export default function useFrameNavigation<SelectType = UmbracoTypes.INavigation>(
    select?: (data: UmbracoTypes.INavigation) => SelectType,
) {
    const { locale } = useRouter();

    return useQuery<UmbracoTypes.INavigation, HTTPError | Error, SelectType>(
        ['frameNavigation', { locale }],
        ({ signal }) => Umbraco.operations.getFrameNavigation(signal, defaultLevels),
        {
            retry: false,
            select,
            keepPreviousData: true,
            staleTime: minutes(10),
            cacheTime: minutes(1),
            refetchOnWindowFocus: true,
        },
    );
}
export function prefetchFrameNavigation({ locale, queryClient }: PrefetchProps) {
    return queryClient.fetchQuery(
        ['frameNavigation', { locale }],
        ({ signal }) => Umbraco.operations.getFrameNavigation(signal, defaultLevels),
        {
            retry: false,
            staleTime: minutes(10),
            cacheTime: minutes(1),
        },
    );
}

type PrefetchProps = {
    locale: string | undefined;
} & Prefetch;
