import { HTTPError } from 'ky';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { Loop54Types } from '~/lib/data-contract';
import { Loop54, Prefetch } from '~/services';
import { hours, seconds } from '../../../../shared/utils/helpers';
import { EntitiesByAttributeRequest, EntitiesRequest } from '../../types';

const OPTIONS = {
    cacheTime: seconds(10), // Cache 10 seconds to avoid filling up cache with filter requests
    staleTime: hours(1),
};

export default function useEntitiesOrEntitiesByAttribute<
    SelectType = EntitiesOrEntitiesByAttributeResponse,
>(
    queryKey: string,
    params: EntitiesOrEntitiesByAttributeRequest | undefined,
    select?: (data: EntitiesOrEntitiesByAttributeResponse) => SelectType,
) {
    const { isReady } = useRouter();

    return useQuery<EntitiesOrEntitiesByAttributeResponse, HTTPError | Error, SelectType>(
        [queryKey, params],
        ({ signal }) =>
            isIndexedRequest(params)
                ? Loop54.operations.getEntitiesByAttribute(params)
                : Loop54.operations.getEntities(signal, params),
        {
            ...OPTIONS,
            enabled: !!params,
            select,
            retry: false,
            keepPreviousData: true,
            refetchOnMount: isReady ? 'always' : false, // The data change over time, so we need to refetch it every time the component is mounted, but only when router is ready or else we end up with double requests on initial hydration
            refetchOnWindowFocus: false, // Never refetch on window focus since that will cause the products to change position
        },
    );
}
export type EntitiesOrEntitiesByAttributeRequest = EntitiesByAttributeRequest | EntitiesRequest;
export type EntitiesOrEntitiesByAttributeResponse =
    | Loop54Types.GetEntitiesByAttributeResponse
    | Loop54Types.GetEntitiesResponse;

type PrefetchEntities = Prefetch & {
    queryKey: string;
    params: EntitiesRequest;
};

export function buildPreFetcherForEntities({ queryKey, queryClient, params }: PrefetchEntities) {
    return queryClient.fetchQuery(
        [queryKey, params],
        ({ signal }) =>
            isIndexedRequest(params)
                ? Loop54.operations.getEntitiesByAttribute(params)
                : Loop54.operations.getEntities(signal, params),
        OPTIONS,
    );
}

const isIndexedRequest = (
    request: EntitiesOrEntitiesByAttributeRequest | undefined,
): request is EntitiesByAttributeRequest => {
    return !!(<EntitiesByAttributeRequest>request).attribute;
};
