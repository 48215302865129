import { useQuery } from '@tanstack/react-query';
import { HTTPError } from 'ky';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { UmbracoTypes } from '~/lib/data-contract';
import { Prefetch, Umbraco } from '~/services';
import { minutes } from '~/shared/utils/helpers';

export default function useMitFogNavigation<SelectType = UmbracoTypes.INavigationNode>(
    select?: (data: UmbracoTypes.INavigationNode) => SelectType,
) {
    const { locale } = useRouter();
    const { data } = useSession();
    const token = data?.accessToken ?? null;
    const customerId = data?.activeCustomerId ?? null;

    return useQuery<UmbracoTypes.INavigationNode, HTTPError | Error, SelectType>(
        ['mitFogNavigation', { locale, token, customerId }],
        ({ signal }) => Umbraco.operations.getMitFogNavigation(signal, token, customerId),
        {
            retry: false,
            select,
            keepPreviousData: true,
            staleTime: minutes(10),
            cacheTime: minutes(1),
            enabled: Boolean(token),
            refetchOnWindowFocus: true,
        },
    );
}

export function prefetchMitFogNavigation({
    locale,
    queryClient,
    accessToken,
    customerId,
}: PrefetchProps) {
    const token = accessToken ?? null;

    return queryClient.fetchQuery(
        ['mitFogNavigation', { locale, token, customerId }],
        ({ signal }) => Umbraco.operations.getMitFogNavigation(signal, token, customerId),
        {
            retry: false,
            staleTime: minutes(10),
            cacheTime: minutes(1),
        },
    );
}

type PrefetchProps = {
    locale: string | undefined;
    accessToken?: string | undefined;
    customerId: string | null;
} & Prefetch;
