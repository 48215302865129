import { useQuery } from '@tanstack/react-query';
import { HTTPError } from 'ky';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { UmbracoTypes } from '~/lib/data-contract';
import { Prefetch, Umbraco } from '~/services';
import { useRewritePaths } from '~/shared/hooks';
import { minutes } from '~/shared/utils/helpers';

export default function usePage<SelectType = AllPages>(select?: (data: AllPages) => SelectType) {
    const { data } = useSession();
    const { asPath, locale, isReady } = useRouter();
    const rewrittenPath = useRewritePaths(isReady ? asPath : '/', data);
    const token = data?.accessToken ?? null;
    const customerId = data?.activeCustomerId ?? null;
    const path = decodeURIComponent(rewrittenPath.split(/[?#]/)[0]);

    return useQuery<AllPages, HTTPError | Error, SelectType>(
        ['page', { locale, path, token, customerId }],
        ({ signal }) => Umbraco.operations.getPage(signal, path, token, customerId),
        {
            retry: false,
            staleTime: minutes(10),
            cacheTime: minutes(1),
            refetchOnWindowFocus: true,
            keepPreviousData: true,
            enabled: isReady,
            select,
        },
    );
}

export function prefetchPage({
    locale,
    queryClient,
    asPath,
    accessToken,
    customerId,
}: PrefetchProps) {
    const path = decodeURIComponent(asPath.split(/[?#]/)[0]);
    const token = accessToken ?? null;

    return queryClient.fetchQuery(
        ['page', { locale, path, token, customerId }],
        ({ signal }) => Umbraco.operations.getPage(signal, path, token, customerId),
        {
            retry: false,
            staleTime: minutes(10),
            cacheTime: minutes(1),
        },
    );
}

type AllPages = UmbracoTypes.IPages | UmbracoTypes.IRedirect;

type PrefetchProps = {
    asPath: string;
    locale: string | undefined;
    accessToken?: string | undefined;
    customerId: string | null;
} & Prefetch;
