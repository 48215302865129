import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';
import { useBasketReference } from '~/shared/utils/storage/hooks';
import useBasket from './useBasket';

export default function useDeleteBasket() {
    const queryClient = useQueryClient();
    const [, , deleteStoredReference] = useBasketReference();
    const { data } = useBasket();

    return useMutation<string, Error>(() => Shop.operations.deleteBasket(data?.basketReference), {
        onMutate: async () => {
            if (data?.basketReference) {
                return;
            }

            // Cancel any outgoing queries (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(['basket']);

            // Snapshot the previous basket
            const previousBasket = queryClient.getQueryData<ShopTypes.BasketResponse>(['basket']);

            // queryClient.removeQueries(Shop.keys.basket.reference(data.basketReference), {
            //     exact: true,
            // });

            // Optimistically remove items from basket
            if (previousBasket) {
                queryClient.setQueryData<ShopTypes.BasketResponse>(['basket'], {
                    ...previousBasket,
                    total: { priceExVat: 0, priceInclVat: 0, vatPercent: 0 },
                    contexts: [],
                });
            }

            return { previousBasket };
        },

        // If the mutation fails, use the context returned from onMutate to roll back
        // onError: (_error, _variables, context) => {
        //     if (context?.previousBasket) {
        //         queryClient.setQueryData<ShopTypes.BasketResponse>(
        //             ['basket'],
        //             context.previousBasket
        //         );
        //     }
        // },

        onSuccess: () => {
            deleteStoredReference();
            queryClient.removeQueries(['basket'], {
                exact: true,
            });
        },

        // Refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(['basket']);
        },
    });
}
