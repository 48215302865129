import { useRouter } from 'next/router';
import { useCulture } from '~/services';

const currency = 'DKK'; // TODO: Get currency from BE
// showFraction is used to show fraction if the number is not a whole number

export const useCurrency = (showFraction = true) => {
    const { locale } = useRouter();
    const { data: culture = locale || 'da-dk' } = useCulture();

    return (value: number) => currencyFormatter(culture, currency, value, showFraction);
};

const currencyFormatter = (
    locale: string,
    currency: string,
    numberToFormat: number,
    showFraction: boolean,
) => {
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
        currencyDisplay: 'code',
    })
        .formatToParts(numberToFormat)
        .map(({ type, value }) => {
            switch (type) {
                case 'currency':
                    // Remove currency code
                    return '';
                case 'fraction':
                    // Always show fraction with two decimal places
                    return showFraction ? (numberToFormat % 1 === 0 ? '00' : value) : value;
                default:
                    return value;
            }
        })
        .join('')
        .trim();
};
