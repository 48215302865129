import shop from '~/lib/api/shop';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';

export default function getStockDetails(
    itemNo: string | undefined,
    postalCode: string | undefined
) {
    if (!itemNo) {
        return Promise.reject(new Error(Shop.errors.invalidProductItemNumber.message));
    }

    const url = Shop.utils.getUrl(`/stock/details/${itemNo}`);

    if (postalCode) {
        url.searchParams.set('postalCode', postalCode);
    }

    return shop.get(url).json<ShopTypes.ProductStockDetailsResponse>();
}
