import { Loop54, useRelatedEntities } from '~/services';

export default function useRelatedResults(productId = '') {
    return useRelatedEntities(
        {
            entity: {
                id: productId,
                type: 'Product',
            },
            resultsOptions: {
                take: 10,
            },
        },
        (data) => ({
            ...data,
            results: Loop54.utils.reduceLoop54Product(data.results),
        }),
    );
}
