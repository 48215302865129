import { useQuery } from '@tanstack/react-query';
import { Dawa } from '~/services';
import { Validation } from '~/shared/utils/validation';

export default function usePostalCodeInformation(postalCode: number) {
    return useQuery(
        ['postalCodeInformation', { postalCode }],
        () => Dawa.operations.getPostalCodeInformation(postalCode),
        {
            enabled:
                Boolean(postalCode) && Boolean(postalCode.toString().match(Validation.PostalCode)),
            cacheTime: 1 * 60 * 1000, // 1 minute
            retry: 1,
            keepPreviousData: true,
        },
    );
}
