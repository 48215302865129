import loop54 from '~/lib/api/loop54';
import { Loop54Types } from '~/lib/data-contract';
import { Loop54 } from '~/services';
import { RelatedEntitiesRequest } from '../types';

export default function getRelatedEntities(
    signal: AbortSignal | undefined,
    params: RelatedEntitiesRequest | undefined
) {
    if (!params) {
        return Promise.reject(new Error(Loop54.errors.missingParams.message));
    }

    return loop54
        .post(Loop54.utils.getUrl('/getRelatedEntities'), {
            json: params,
            signal,
        })
        .json<Loop54Types.GetRelatedEntitiesResponse>();
}
