import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { Link as GenericLink } from '~/shared/components/Link';
import { Text } from '~/shared/components/Text';
import { breakpoints } from '~/theme';

export const List = styled.ul({
    display: 'flex',
    flexDirection: 'column',

    ['ul']: {
        marginLeft: 16,
    },
});

export const MenuTitle = styled(Text)(({ theme }) => ({
    display: 'none',

    [breakpoints.sm]: {
        display: 'block',
        marginBottom: theme.space[4],
        a: {
            color: theme.colors.grey70,
        },
    },
}));

export const ListItem = styled.li<{ isActive?: boolean }>(
    ({ theme }) => ({
        whiteSpace: 'nowrap',
        fontSize: `${theme.fontSizes.mouse}`,
        lineHeight: `${theme.lineHeights.fox}`,

        [breakpoints.sm]: {
            fontSize: `${theme.fontSizes.rat}`,
            lineHeight: `${theme.lineHeights.dog}`,
        },
    }),
    ifProp('isActive', () => ({
        listStyle: 'square',
    })),
);

type LinkProps = {
    isActive: boolean;
};

export const LinkLevelThree = styled(GenericLink)<LinkProps>(
    ({ theme }) => ({
        display: 'block',
        color: theme.colors.nightBlack,
        padding: `${theme.space[1]} 0`,
    }),
    ifProp('isActive', ({ theme }) => ({
        fontWeight: theme.fontWeights.bold,
    })),
);

export const LinkLevelFour = styled(GenericLink)<LinkProps>(
    ({ theme }) => ({
        display: 'block',
        color: theme.colors.nightBlack,
        padding: `${theme.space[1]} 0 ${theme.space[1]} 0`,
    }),
    ifProp('isActive', ({ theme }) => ({
        fontWeight: theme.fontWeights.medium,
    })),
);

export const LinkLevelFive = styled(GenericLink)<LinkProps>(
    ({ theme }) => ({
        display: 'block',
        color: theme.colors.nightBlack,
        padding: `${theme.space[1]} 0 ${theme.space[1]} 0`,
    }),
    ifProp('isActive', ({ theme }) => ({
        fontWeight: theme.fontWeights.medium,
    })),
);

type IconProps = {
    isActive: boolean;
};

export const Icon = styled.span<IconProps>(({ theme, isActive }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: !isActive ? 'rotate(180deg)' : 'rotate(0deg)',
    marginLeft: theme.space[2],

    svg: {
        height: `${theme.space[4]}`,
        width: `${theme.space[4]}`,
    },
}));

export const NavigationFilterWrapper = styled.div(() => ({
    display: 'none',

    [breakpoints.sm]: {
        display: 'block',
    },
}));
