import { useQuery } from '@tanstack/react-query';
import { HTTPError } from 'ky';
import { useSession } from 'next-auth/react';
import { ShopTypes } from '~/lib/data-contract';
import { Shop, useUserProfile } from '~/services';

export default function useMyOrderDetails(orderId: string) {
    const { data: profile } = useUserProfile();
    const { data } = useSession();
    const customerId = profile?.customer?.id ?? null;
    const token = data?.accessToken ?? null;

    return useQuery<ShopTypes.OrderDetailsResponse, HTTPError | Error>(
        ['myOrderDetails', { orderId, customerId, token }],
        ({ signal }) => Shop.operations.getMyOrder({ signal, orderId, customerId, token }),
        {
            enabled: Boolean(data?.accessToken),
        },
    );
}
