import { useCallback, useEffect, useRef } from 'react';

type DimensionsRefType = Pick<
    DOMRectReadOnly,
    'x' | 'y' | 'top' | 'left' | 'right' | 'bottom' | 'height' | 'width'
>;

const defaultState: DimensionsRefType = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
};

/**
 * keeps track of the dimensions of a DOM element without triggering a re-render.
 *
 * This is particular useful when tracking elements that are being animated. Their constant
 * changing state will otherwise trigger a re-render for each frame.
 *
 * inspired by https://github.com/streamich/react-use/blob/master/src/useMeasure.ts
 *
 * @example
 *
 * // Will not re-render while resizing the browser window
 * const [dimensionsRef, setDimensionsRef] = useDimensionsRef();
 *
 * const onClickHandler = () => console.log(dimensionsRef.current);
 *
 * return (
 * <div ref={setDimensionsRef} style={{ height: '50vh'}}>
 *   <Button onClick={onClcikHandler}>Log dimensions<Button>
 * </div>
 * )
 */
const useDimensionsRef = <T extends HTMLElement = HTMLElement>() => {
    const dimensionsRef = useRef<DimensionsRefType>(defaultState);
    const observerRef = useRef<ResizeObserver | undefined>();

    const setElement = useCallback((element: T) => {
        observerRef?.current?.disconnect();

        if (element) {
            const observer = new window.ResizeObserver((entries) => {
                if (entries[0]) {
                    dimensionsRef.current = entries[0].contentRect;
                }
            });

            observer.observe(element);
            observerRef.current = observer;
        }
    }, []);

    useEffect(() => () => observerRef?.current?.disconnect(), []);

    return [dimensionsRef, setElement] as [typeof dimensionsRef, typeof setElement];
};

export default useDimensionsRef;
