import shop from '~/lib/api/shop';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';

export default async function deleteBasketVoucher(basketReference: string | undefined, couponCode: string) {
    if (!basketReference) {
        return Promise.reject(new Error(Shop.errors.referenceNotFound.message));
    }

    try {
        const voucherReturn = await shop
            .delete(Shop.utils.getUrl(`/baskets/${basketReference}/voucher/${couponCode}`), {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .json<ShopTypes.BasketResponse>();
        
        return voucherReturn;
    } catch (error) {
        console.error('Error deleting basket voucher:', error);
        throw error;
    }
}
