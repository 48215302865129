import { GlobalStyles, ThemeProvider } from '$theme';
import isValidProp from '@emotion/is-prop-valid';
import { Hydrate, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { MotionConfig } from 'framer-motion';
import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import { ReactElement, ReactNode } from 'react';
import { GoogleTagManagerScript } from '~/shared/scripts';
import { useSetupQueryClient } from '~/shared/utils/request/context/RequestProvider';
import { DynamicPageProps } from '~/templates/pages';
import AuthGuard from '../templates/blocks/guard/AuthGuard';

export default function App({
    Component,
    pageProps: { session, ...pageProps },
}: AppPropsWithLayout) {
    const queryClient = useSetupQueryClient();
    const getLayout = Component.getLayout ?? ((page) => page);

    return (
        <SessionProvider session={session}>
            {GlobalStyles}

            <QueryClientProvider client={queryClient}>
                <MotionConfig reducedMotion="user" isValidProp={isValidProp}>
                    <Hydrate state={pageProps.dehydratedState}>
                        <ThemeProvider>
                            <GoogleTagManagerScript />
                            <AuthGuard>{getLayout(<Component {...pageProps} />)}</AuthGuard>
                        </ThemeProvider>
                    </Hydrate>

                    <ReactQueryDevtools
                        initialIsOpen={false}
                        toggleButtonProps={{ className: 'fetch-debugger' }}
                    />
                </MotionConfig>
            </QueryClientProvider>
        </SessionProvider>
    );
}

type NextPageWithLayout = {
    getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps<DynamicPageProps & { session?: Session }> & {
    Component: NextPageWithLayout;
};
