import umbraco from '~/lib/api/umbraco';
import { UmbracoTypes } from '~/lib/data-contract';
import { Umbraco } from '~/services';

export default function getPage<Page = AllPages>(
    signal: AbortSignal | undefined,
    asPath: string,
    token: string | null,
    customerId: string | null,
) {
    const url = Umbraco.utils.getUrl('/content/page');

    // Temporary solution for hardcoded newspaper page
    if (asPath.startsWith('/avis/')) {
        return new Promise((resolve) =>
            resolve({
                type: 'newsPaper',
            } as UmbracoTypes.IPNewsPaper),
        ) as Promise<UmbracoTypes.IPNewsPaper>;
    }

    return umbraco
        .get(url, {
            signal,
            headers: {
                ...(token && { Authorization: `Bearer ${token}` }),
            },
            searchParams: {
                url: asPath || '/',
                ...(customerId && { customerId }),
            },
            hooks: {
                afterResponse: [
                    (_request, _options, response) => {
                        // Pages that are not found will return with a status code of 200 so we can check for "not found" error by the page type instead
                        if (
                            response.status === 404 ||
                            response.status === 401 ||
                            response.status === 403
                        ) {
                            return new Response(response.body, {
                                status: 200,
                                statusText: response.statusText,
                                headers: response.headers,
                            });
                        }

                        return response;
                    },
                ],
                beforeError: [
                    (error) => {
                        const { response } = error;

                        if (response && response.body) {
                            error.name = Umbraco.errors.pageFetch.name;
                            error.message = Umbraco.errors.pageFetch.message(asPath);
                        }

                        return error;
                    },
                ],
            },
        })
        .json<Page>();
}

type AllPages = UmbracoTypes.IPages | UmbracoTypes.IRedirect;
