import { Events, useEmitter, useListener } from '..';
import type { SignalEvent } from '../types';

type Value = string;

export function useBasketAddEmitter() {
    return useEmitter<Value>(Events.BasketAdd);
}

export function useBasketAddListener(onSignal: SignalEvent<Value>) {
    return useListener<Value>({
        eventName: Events.BasketAdd,
        onSignal,
    });
}
