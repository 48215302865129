import { useEffect } from 'react';
import type { Params } from '../types';

export default function useListener<T>({ eventName, onSignal }: Params<T>) {
    useEffect(() => {
        const handleSignal = (event: Event) => onSignal(event as CustomEvent);

        if (typeof onSignal === 'function') {
            globalThis.addEventListener(eventName, handleSignal);

            return () => {
                globalThis.removeEventListener(eventName, handleSignal);
            };
        }
    }, [eventName, onSignal]);
}
