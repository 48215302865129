import shop from '~/lib/api/shop';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';

export default function deleteDeliveryAddress(deliveryId: number, reference: string | undefined) {
    if (!reference) {
        return Promise.reject(new Error(Shop.errors.referenceNotFound.message));
    }

    return shop
        .delete(Shop.utils.getUrl(`/baskets/${reference}/delete/deliveryaddress/${deliveryId}`))
        .json<ShopTypes.BasketResponse>();
}
