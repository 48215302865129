import { create } from 'zustand';
import { wait } from '~/shared/utils/promises';
import { closePowerStepModal } from './usePowerStepState';

type ModalState = {
    freightQuickPriceModalId?: string;
    quickAddToBasketModalId?: string;
    freightPriceSuccessCallback?: (args?: unknown) => void;
    isOpen: boolean;
    itemList?: string;
};

const useQuickModalState = create<ModalState>(() => ({
    quickAddToBasketModalId: '',
    freightQuickPriceModalId: '',
    isOpen: false,
    freightPriceSuccessCallback: undefined,
    itemList: '',
}));

export default useQuickModalState;

export function setQuickFreightModalId(id: string) {
    useQuickModalState.setState({
        freightQuickPriceModalId: id,
    });
}

export async function hideQuickAddToBasketModal() {
    useQuickModalState.setState({
        isOpen: false,
        quickAddToBasketModalId: '',
        freightQuickPriceModalId: '',
        freightPriceSuccessCallback: undefined,
    });

    await wait(800);
}

export function closeModal() {
    useQuickModalState.setState({
        isOpen: false,
        quickAddToBasketModalId: '',
    });
}

export async function showQuickAddToBasketModal(sku: string, closePowerStep?: boolean) {
    if (closePowerStep) {
        await closePowerStepModal();
    }

    useQuickModalState.setState({
        quickAddToBasketModalId: sku,
        isOpen: true,
    });
}

export function resetBasketModalID() {
    useQuickModalState.setState({
        quickAddToBasketModalId: '',
    });
}

export function resetFreightModalID() {
    useQuickModalState.setState({
        freightQuickPriceModalId: '',
    });
}

export function setQuickFreightSuccessCallback(callback?: (args?: unknown) => void) {
    useQuickModalState.setState({
        freightPriceSuccessCallback: callback,
    });
}

export function setItemList(itemList: string) {
    useQuickModalState.setState({
        itemList: itemList,
    });
}
