import React from 'react';
import { Skeleton } from './styled';

export type SkeletonLoaderProps = (FullWidth | Width) & {
    height: number;
    isLight?: boolean;
    style?: React.CSSProperties;
};

type FullWidth = {
    isFullWidth?: boolean;
};

type Width = {
    width: number;
};

export const SkeletonLoader = (props: SkeletonLoaderProps) => <Skeleton aria-hidden {...props} />;
