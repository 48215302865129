import { getProductFilter } from '~/features/ProductList/utils';
import { Loop54, Loop54ProductAttributes, useBrandEntities } from '~/services';

type Arguments = {
    brand: string;
    onlyShowInStock?: boolean;
};

type filter = {
    type: string;
    attributeName?: Loop54ProductAttributes;
    value: string | boolean;
};

export default function useBrandResults({ brand, onlyShowInStock }: Arguments) {
    const filters: filter[] = [
        getProductFilter(),

        {
            type: 'attribute',
            attributeName: Loop54ProductAttributes.Brand,
            value: brand,
        },
    ];

    if (onlyShowInStock) {
        filters.push({
            type: 'attribute',
            attributeName: Loop54ProductAttributes.InStock,
            value: onlyShowInStock,
        });
    }

    return useBrandEntities(
        {
            resultsOptions: {
                filter: {
                    and: filters,
                },
                sortBy: [{ type: 'popularity' }],
                take: 10,
            },
        },
        (data) => ({
            ...data,
            results: Loop54.utils.reduceLoop54Product(data.results),
        }),
    );
}
