import { RefObject } from 'react';

export interface ModuleTheme {
    backgroundColor?: string;
    backgroundColorHEX?: string;
}

export interface ModuleSpacing {
    spacingTop?: ModuleSpace;
    spacingBottom?: ModuleSpace;
}

export type ModuleSpace = 'none' | 'small' | 'default';

export interface IM70MediaItem {
    image?: IImageMedia;
    video?: IVideoMedia;
}

export interface IMediaAndTextItem {
    alias?: 'contentArticle';
    image?: IImageMedia;
    video?: IVideoMedia;
    headline?: string;
    description?: RichText;
    label?: string;
    verticalAlignment?: VerticalAlignment;
    callToAction?: ILink;
}

export type ICategory = ITitle & { alias?: 'category'; image?: IImageMedia; callToAction?: ILink };

export type IModule = (ModuleTheme & ModuleSpacing) & { id: string; type: string };

export enum ModuleTypes {
    IM10HeroModule = 'm10HeroModule',
    IM11HeroSplitModule = 'm11HeroSplitModule',
    IM15HeroPromotionModule = 'm15HeroPromotionModule',
    IM20CategoriesModule = 'm20CategoriesModule',
    IM21ReferencedCategoriesModule = 'm21ReferencedCategoriesModule',
    IM30PopularProductCarouselModule = 'm30PopularProductCarouselModule',
    IM30CampaignProductCarouselModule = 'm30CampaignProductCarouselModule',
    IM30RelatedProductCarouselModule = 'm30RelatedProductCarouselModule',
    IM30RecentProductCarouselModule = 'm30RecentProductCarouselModule',
    IM30BrandProductCarouselModule = 'm30BrandProductCarouselModule',
    IM30SpecificProductNumbersCarouselModule = 'm30SpecificProductNumbersCarouselModule',
    IM30ComplementaryProductCarouselModule = 'm30ComplementaryProductCarouselModule',
    IM30BasketRecommendationProductCarouselModule = 'm30BasketRecommendationProductCarouselModule',
    IM31DynamicCategoryListBlock = 'DynamicCategoryListBlock',
    IM40ProductPromotionModule = 'm40ProductPromotionModule',
    IM45BrandOverviewModule = 'brandPicker',
    IM50ShopTheLookModule = 'm50ShopTheLookModule',
    IM60UspModule = 'm60UspModule',
    IM70MediaModule = 'm70MediaModule',
    IM75MediaAndTextModule = 'm75MediaAndTextModule',
    IM76ContentPromotionModule = 'm76ContentPromotionModule',
    IM77SpecificProductNumbersGridModule = 'm77SpecificProductNumbersGridModule',
    IM80MediaAndTextBundleModule = 'm80MediaAndTextBundleModule',
    IM100RichTextModule = 'm100RichTextModule',
    IM120AccordionListModule = 'm120AccordionListModule',
    IM200OrderHistory = 'm200OrderHistory',
    IM201OrderDetails = 'm201OrderDetails',
    IM202AccountInformation = 'm202AccountInfo',
    IM203AccountStatement = 'm203AccountStatement',
    IM204UserList = 'm204UserList',
    IM205UserProfile = 'm205UserProfile',
}

export type IModules =
    | IM10HeroModule
    | IM11HeroSplitModule
    | IM15HeroPromotionModule
    | IM20CategoriesModule
    | IM21ReferencedCategoriesModule
    | IM30PopularProductCarouselModule
    | IM30CampaignProductCarouselModule
    | IM30RelatedProductCarouselModule
    | IM30RecentProductCarouselModule
    | IM30BrandProductCarouselModule
    | IM30SpecificProductNumbersCarouselModule
    | IM31DynamicCategoryListBlock
    | IM40ProductPromotionModule
    | IM50ShopTheLookModule
    | IM60UspModule
    | IM70MediaModule
    | IM75MediaAndTextModule
    | IM76ContentPromotionModule
    | IM77SpecificProductNumbersGridModule
    | IM80MediaAndTextBundleModule
    | IM100RichTextModule
    | IM120AccordionListModule
    | IM200OrderHistoryModule
    | IM201OrderDetailsModule
    | IM202AccountInformationModule
    | IM203AccountStatementModule
    | IM204UserListModule
    | IM205UserProfileModule;

export enum IM10HeroModuleMaxHeight {
    Large = '650px',
    Small = '240px',
    SmallDesktop = '315px',
}

export type IM10HeroModule = IModule & {
    type: ModuleTypes.IM10HeroModule;
    image?: IImageMedia;
    imageMobile?: IImageMedia;
    video?: IVideoMedia;
    headline?: 'string';
    subHeadline?: 'string';
    callToAction?: ILink;
    callToActionTypeFill?: boolean;
    brandLogo?: IImageMedia;
    maxHeight?: IM10HeroModuleMaxHeight;
    mobileContentPosition?: 'above' | 'beneath';
    contentPosition?: 'top' | 'center' | 'bottom';
};

export type IM11HeroSplitModule = IModule & {
    type: ModuleTypes.IM11HeroSplitModule;
    id: string;
    hero: IM11HeroSplitItem[];
};

export type IM11HeroSplitItem = {
    alias: 'hero';
    image?: IImageMedia;
    imageMobile?: IImageMedia;
    brandLogo?: IImageMedia;
    video?: IVideoMedia;
    headline?: 'string';
    subHeadline?: 'string';
    callToAction?: ILink;
    callToActionTypeFill?: boolean;
    mobileContentPosition?: 'above' | 'beneath';
    contentPosition?: 'top' | 'center' | 'bottom';
    backgroundColorHEX?: string;
    backgroundColor?: string;
};

export type IM15HeroPromotionModule = (IModule & VerticalAlignment) & {
    type: ModuleTypes.IM15HeroPromotionModule;
};

export type IM20CategoriesModule = IModule & {
    type: ModuleTypes.IM20CategoriesModule;
    items?: ICategory[];
};

export type IM21ReferencedCategoriesModule = IModule & {
    type: ModuleTypes.IM21ReferencedCategoriesModule;
    items?: IPageReference[];
};

export type IM30ProductCarousel = IModule & {
    headline?: string;
    link?: ILink;
    onlyShowInStock?: boolean;
};

export type IM30CampaignProductCarouselModule = IM30ProductCarousel & {
    type: ModuleTypes.IM30CampaignProductCarouselModule;
    campaignID?: string;
    amountOfProducts?: number;
    sorting?: 'attribute' | 'id' | 'type' | 'relevance' | 'popularity';
    ascendingOrder: boolean;
};

export type IM30PopularProductCarouselModule = IM30ProductCarousel & {
    type?: ModuleTypes.IM30PopularProductCarouselModule;
};

export type IM30RelatedProductCarouselModule = IM30ProductCarousel & {
    type?: ModuleTypes.IM30RelatedProductCarouselModule;
    headline?: string;
    productId: string;
    isModal?: boolean;
};
export type IM30RecentProductCarouselModule = IM30ProductCarousel & {
    type?: ModuleTypes.IM30RecentProductCarouselModule;
    headline?: string;
};

export type IM30BrandProductCarouselModule = IM30ProductCarousel & {
    type?: ModuleTypes.IM30BrandProductCarouselModule;
    brand: string;
};

export type IM30SpecificProductNumbersCarouselModule = IM30ProductCarousel & {
    type?: ModuleTypes.IM30SpecificProductNumbersCarouselModule;
    productNumbers: string;
};

export type IM30ComplimentaryProductCarouselModule = IM30ProductCarousel & {
    type?: ModuleTypes.IM30ComplementaryProductCarouselModule;
    productId: string;
    setHasComplementaryProducts: (status: boolean) => void;
    inputRef?: RefObject<HTMLDivElement>;
};

export type IM30BasketRecommendationProductCarouselModule = IM30ProductCarousel & {
    type?: ModuleTypes.IM30BasketRecommendationProductCarouselModule;
    productIds: string[];
};

export type IM31DynamicCategoryListBlock = {
    id: string;
    type: ModuleTypes.IM31DynamicCategoryListBlock;
    categories?: Category[];
};

export type Category = {
    link: ILink;
    image: IImageMedia;
};

export type IM40ProductPromotionModule = IModule & { type: ModuleTypes.IM40ProductPromotionModule };

export type IM45BrandOverviewModule = IModule & { type: ModuleTypes.IM45BrandOverviewModule };

export type IM50ShopTheLookModule = IModule & { type: ModuleTypes.IM50ShopTheLookModule };

export type IM60UspModule = IModule & { type: ModuleTypes.IM60UspModule; items: IUspItem[] };

export type IM70MediaModule = (IModule & IM70MediaItem) & {
    type: ModuleTypes.IM70MediaModule;
    caption?: string;
    link?: ILink;
};

export enum M75MediaModuleMaxHeight {
    Small = '350px',
    Medium = '450px',
    Large = '550px',
}

export type IM75MediaAndTextModule = IModule & {
    type: ModuleTypes.IM75MediaAndTextModule;
    mediaSize: 'default' | 'small';
    image?: IImageMedia;
    video?: IVideoMedia;
    headline?: string;
    description?: RichText;
    verticalAlignment?: VerticalAlignment;
    callToAction?: ILink;
    callToActionTypeFill?: boolean;
    mediaHorizontalAlignment: HorizontalAlignment;
    horizontalAlignment?: HorizontalAlignment;
    hasGap?: boolean;
    maxHeight: M75MediaModuleMaxHeight;
    imageMobile?: IImageMedia;
};

export type IM76ContentPromotionModule = IModule & {
    type: ModuleTypes.IM76ContentPromotionModule;
    image?: IImageMedia;
    mobileImage?: IImageMedia;
    video?: IVideoMedia;
    headline?: string;
    subHeadline?: string;
    description?: RichText;
    verticalAlignment?: VerticalAlignment;
    callToAction?: ILink;
    callToActionTypeFill?: boolean;
    mediaHorizontalAlignment: HorizontalAlignment;
    horizontalAlignment?: HorizontalAlignment;
};

export type IM77SpecificProductNumbersGridModule = IModule & {
    type?: ModuleTypes.IM77SpecificProductNumbersGridModule;
    headline?: string;
    link?: ILink;
    productNumbers: string;
};

export type IM80MediaAndTextBundleModule = IModule & {
    type: ModuleTypes.IM80MediaAndTextBundleModule;
    headline?: string;
    callToAction?: ILink;
    mediaAndTextItems: (IMediaAndTextItem | IPageReference)[];
};

export type IM100RichTextModule = IModule & {
    type: ModuleTypes.IM100RichTextModule;
    headline?: string;
    text?: RichText;
    secondaryColumn?: RichText;
    secondaryColumnScripts?: RichTextScriptTypes;
};

export enum RichTextScriptTypes {
    'None' = 'None',
    'CookiePolicy' = 'CookiePolicy',
}

export type IM120AccordionListModule = IModule & {
    type: ModuleTypes.IM120AccordionListModule;
    headline?: string;
    accordionItems: IAccordionItem[];
};

export type IM200OrderHistoryModule = IModule & {
    headline?: string;
    type: ModuleTypes.IM200OrderHistory;
};

export type IM201OrderDetailsModule = IModule & {
    headline?: string;
    type: ModuleTypes.IM201OrderDetails;
};

export type IM202AccountInformationModule = IModule & {
    headline?: string;
    type: ModuleTypes.IM202AccountInformation;
};

export type IM203AccountStatementModule = IModule & {
    headline?: string;
    type: ModuleTypes.IM203AccountStatement;
};

export type IM204UserListModule = IModule & {
    headline?: string;
    type: ModuleTypes.IM204UserList;
};
export type IM205UserProfileModule = IModule & {
    headline?: string;
    type: ModuleTypes.IM205UserProfile;
};

export enum PageTypes {
    IP10SearchPage = 'p10SearchPage',
    IP11PopularProductsPage = 'p11PopularProductsPage',
    IP14BrandOverviewPage = 'p14BrandOverviewPage',
    IP20FrontPage = 'p20FrontPage',
    IP21FrontPageLouisPoulsen = 'p21FrontPageLouisPoulsen',
    IP22FrontPageFogKonto = 'p22FrontPageFogKonto',
    IP30CategoryPage = 'p30CategoryPage',
    IP31CategoryListPage = 'categoryListPage',
    IP40ProductListPage = 'productFilterPage',
    IP41CategoryNode = 'categoryNode',
    IP50ProductDetailPage = 'p50ProductDetailPage',
    IP90BasketPage = 'p90BasketPage',
    IP60ModulePage = 'p60ModulePage',
    IP70FindStoresPage = 'p70FindStoresPage',
    IP80FavoritePage = 'p80FavoritePage',
    IP100CheckoutInformationPage = 'p100CheckoutInformationPage',
    IP101CheckoutDeliveryPage = 'p101CheckoutDeliveryPage',
    IP103CheckoutReceiptPage = 'p103CheckoutReceiptPage',
    IP110FaqPage = 'p110FaqPage',
    IP120ProfilePage = 'p120ProfilePage',
    IP130ContactPage = 'p130ContactPage',
    IP140NotFoundPage = 'p140NotFoundPage',
    IP150ErrorPage = 'p150ErrorPage',
    IP160ArticlePage = 'p160ArticlePage',
    IP170NotAllowedPage = 'p170NotAllowedPage',
    IP180NotAuthenticatedPage = 'p180NotAuthenticatedPage',
    IP200MitFogPage = 'p200MitFogPage',
    IMyFogPage = 'mitFogPage',
    IPRedirect = 'redirect',
    IPNewsPaper = 'newsPaper',
}

export type IPages =
    | IP10SearchPage
    | IP11PopularProductsPage
    | IP14BrandOverviewPage
    | IP20FrontPage
    | IP21FrontPageLouisPoulsen
    | IP30CategoryPage
    | IP31CategoryListPage
    | IP40ProductListPage
    | IP41CategoryNode
    | IP50ProductDetailPage
    | IP60ModulePage
    | IP70FindStoresPage
    | IP80FavoritePage
    | IP90BasketPage
    | IP100CheckoutInformationPage
    | IP101CheckoutDeliveryPage
    | IP110FaqPage
    | IP120ProfilePage
    | IP130ContactPage
    | IP140NotFoundPage
    | IP150ErrorPage
    | IP160ArticlePage
    | IP170NotAllowedPage
    | IP180NotAuthenticatedPage
    | IP200MitFogPage
    | IPNewsPaper;

export interface IMetaTag {
    tag: string;
}

export type IMetaPage = IPage & { metaTitle?: string; metaTags?: IMetaTag[] };

export type IModulePage = IPage;

export type IPage = (IGlobalizable & ILocalizable) & {
    meta: { title?: string; description?: string; hideFromRobots?: boolean; canonical?: ILink };
    statusCode?: number;
    pageElements?: IPageElement[];
    breadcrumb?: IBreadcrumb;
    hideBreadcrumb?: boolean;
    id: string;
    type: string;
    pageProperties: object;
};

export type IP10SearchPage = IModulePage & { type: PageTypes.IP10SearchPage };

export type IP20FrontPage = IModulePage & { type: PageTypes.IP20FrontPage };

export type IP21FrontPageLouisPoulsen = IModulePage & { type: PageTypes.IP21FrontPageLouisPoulsen };

export type IP30CategoryPage = IModulePage & { type: PageTypes.IP30CategoryPage };

export type IP31CategoryListPage = IModulePage & {
    title: string | null | undefined;
    type: PageTypes.IP31CategoryListPage;
};

export type IP40ProductListPage = IModulePage & {
    title: string | null | undefined;
    type: PageTypes.IP40ProductListPage;
    pageProperties: FilterAdmin | null | undefined;
};

export type IP11PopularProductsPage = IModulePage & {
    title: string | null | undefined;
    type: PageTypes.IP11PopularProductsPage;
    pageProperties: FilterAdmin | null | undefined;
};

export type IP14BrandOverviewPage = IModulePage & {
    title: string | null | undefined;
    type: PageTypes.IP14BrandOverviewPage;
};

export type IP41CategoryNode = IModulePage & {
    title: string | null | undefined;
    type: PageTypes.IP41CategoryNode;
    pageProperties: FilterAdmin | null | undefined;
};

export type IP50ProductDetailPage = IModulePage & {
    type: PageTypes.IP50ProductDetailPage;
    pageProperties: {
        productItemNumber?: string;
    };
};
export type IP60ModulePage = IModulePage & { type: PageTypes.IP60ModulePage };

export type IP90BasketPage = IModulePage & { type: PageTypes.IP90BasketPage };

export type IP70FindStoresPage = IModulePage & { type: PageTypes.IP70FindStoresPage };

export type IP80FavoritePage = IModulePage & { type: PageTypes.IP80FavoritePage };

export type IP100CheckoutInformationPage = IModulePage & {
    type: PageTypes.IP100CheckoutInformationPage;
};

export type IP101CheckoutDeliveryPage = IModulePage & { type: PageTypes.IP101CheckoutDeliveryPage };

export type IP103CheckoutReceiptPage = IModulePage & { type: PageTypes.IP103CheckoutReceiptPage };

export type IP110FaqPage = IModulePage & { type: PageTypes.IP110FaqPage };

export type IP120ProfilePage = IModulePage & { type: PageTypes.IP120ProfilePage };

export type IP130ContactPage = IModulePage & { type: PageTypes.IP130ContactPage };

export type IP140NotFoundPage = IModulePage & { type: PageTypes.IP140NotFoundPage };

export type IP150ErrorPage = IModulePage & { type: PageTypes.IP150ErrorPage };

export type IP160ArticlePage = IModulePage & {
    type: PageTypes.IP160ArticlePage;
    title?: string;
    articleElements?: IPageElement[];
    createDate?: Date;
    updateDate?: Date;
};

export type IP170NotAllowedPage = IModulePage & {
    type: PageTypes.IP170NotAllowedPage;
    pageProperties: {
        errorPageProperties: {
            gotoFrontpageCtaLabel?: string;
            bodyText: string;
            faq?: FAQContent[];
            headingLabel: string;
            helpHeadingLabel: string;
            mailAddress: string;
            telephoneNumber: string;
        };
    };
};

export type IP180NotAuthenticatedPage = IModulePage & {
    type: PageTypes.IP180NotAuthenticatedPage;
    pageProperties: {
        errorPageProperties: {
            ctaCreateUser?: {
                name?: string;
                target?: string;
                url?: string;
            };
            ctaLoginLabel?: string;
            bodyText: string;
            faq?: FAQContent[];
            headingLabel: string;
            helpHeadingLabel: string;
            mailAddress: string;
            telephoneNumber: string;
        };
    };
};

export type IP200MitFogPage = IModulePage & { type: PageTypes.IP200MitFogPage };

export type FilterAdmin = {
    filterAdmin: {
        filters: {
            [key: string]: string[];
        };
        facets: string[];
        sort: string;
        hiddenProductIds: string[];
        promotedProductIds: string[];
        sortOptions: string[]; // TODO: Maybe add enum here
    };
};

export type HeaderTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p';

export type HeaderStyle =
    | 'display1'
    | 'display2'
    | 'display3'
    | 'display4'
    | 'display5'
    | 'paragraph';

export type HorizontalAlignment = 'left' | 'right';

export type VerticalAlignment = 'top' | 'center' | 'bottom';

export type ProductSplashType = {
    type: 'informative' | 'anniversary' | 'sale' | 'blackFriday' | 'bestBuy' | 'banner';
    text: string;
};

export type FAQContent = {
    question?: string;
    answer: string;
    isOpen: boolean;
};

export type IProductCategory = object;

export interface IAccordionItem {
    headline: string;
    description: RichText;
    open: boolean;
}

export type RichText = string;

export interface IMedia {
    type?: string;
    id: string; // TODO: Umbraco type is number, but next type is string.

    /** Source of media. */
    crops?: [];
    focalPoint: null | {
        left: number;
        top: number;
    };
    src: string;
    width: number;
    height: number;
    caption?: string;
    name?: string;
}

export interface ITitle {
    title?: string;
}

export type IImageMedia = IMedia & { alt?: string };

export interface IVideoMedia {
    src: string;

    /** Should the video loop */
    loop: boolean;

    /** Should the video start on load */
    autoPlay: boolean;
}

export interface IUspItem {
    image: IImageMedia;
    headline: string;
    text?: string;
    link?: ILink;
}

export interface ValidatedBasketViewModel {
    success?: boolean;
    validationMessages?: string[];
    culture?: string;
    market?: string;

    /** @format double */
    quantitySum?: number;
    totals?: ITotals;
    lineItems?: ILineItem[];
    type?: string;
    id?: string;
}

export interface ITotals {
    subTotal: number;
    shippingTotal: number;
    taxTotal: number;
    handlingTotal: number;
    total: number;
}

export interface ILineItem {
    variation: IVariation;

    /** @format double */
    quantity: number;
    unitPrice: IMoney;
    discountedUnitPrice?: IMoney;
    linePrice: IMoney;
    discountedLinePrice?: IMoney;
    id?: string;
}

export interface IStockStatus {
    quantityAvailable: number;
}

export type IVariation = (IGlobalizable & ILocalizable) & {
    sku: string;
    name: string;
    url?: string;
    image?: IImageMedia;
    type: string;
};

export type IVariationDetails = IVariation & {
    images?: IImageMedia[];
    price: IMoney;
    discountedPrice?: IMoney;
    stockStatus: IStockStatus;
};

export interface ISplash {
    text?: string;
}

export interface IMoney {
    /** @format double */
    amount: number;
}

export interface BestBetModel {
    id?: string;

    /** @pattern ^[^~`!@%^*()\[\]\{\}:;'|/<>?_-](?:\w|[0-9&+#$'_,.\-\s])*$ */
    phrases: string;
    target_description?: string;
    target_type?: string;
    target_key?: string;
    tags?: string[];

    /** @format date-time */
    date_added?: string;
    best_bet_has_own_style?: boolean;
    best_bet_target_title?: string;
    best_bet_target_description?: string;
    best_bet_target_url?: string;
    best_bet_target_document_title?: string;
    best_bet_target_document_description?: string;
    best_bet_target_document_status?: number;
}

export interface CouponModel {
    code?: string;
    applied?: boolean;
    validationMessages?: string;
    hasValidationIssues?: boolean;
}

export interface LineItemPostModel {
    code: string;
    displayName?: string;

    /** @format double */
    quantity: number;

    /**
     * @format double
     * @min 0
     */
    placedPrice: number;
}

export interface LineItemPatchModel {
    /** @format double */
    quantity?: number;

    /**
     * @format double
     * @min 0
     */
    placedPrice?: number;

    /**
     * @format double
     * @min 0
     */
    discountValue?: number;
    discountType?: 'Percentage' | 'Value';
    discountDescription?: string;
}

export interface PropertyItemModel {
    id?: number;
    name: string;
    friendlyName?: string;
    dataType?:
        | 'BigInt'
        | 'Binary'
        | 'Bit'
        | 'Char'
        | 'DateTime'
        | 'Decimal'
        | 'Float'
        | 'Image'
        | 'Int'
        | 'Money'
        | 'NChar'
        | 'NText'
        | 'NVarChar'
        | 'Real'
        | 'UniqueIdentifier'
        | 'SmallDateTime'
        | 'SmallInt'
        | 'SmallMoney'
        | 'Text'
        | 'Timestamp'
        | 'TinyInt'
        | 'VarBinary'
        | 'VarChar'
        | 'Variant'
        | 'Numeric'
        | 'Sysname'
        | 'number'
        | 'Boolean'
        | 'Date'
        | 'Email'
        | 'URL'
        | 'ShortString'
        | 'LongString'
        | 'LongHtmlString'
        | 'DictionarySingleValue'
        | 'DictionaryMultiValue'
        | 'EnumSingleValue'
        | 'EnumMultiValue'
        | 'StringDictionary'
        | 'File'
        | 'ImageFile'
        | 'MetaObject';
    dictionaryItems?: MetaDictionaryItemModel[];
    allowNulls?: boolean;
    value?: object;
    isEditable?: boolean;
    attributes?: Record<string, string>;
}

export interface MetaDictionaryItemModel {
    id?: number;
    value?: string;
}

export interface OrderNoteModel {
    orderNoteId?: number;
    title?: string;
    detail?: string;
    type?: string;
    lineItemId?: number;

    /**
     * @format uuid
     * @example 00000000-0000-0000-0000-000000000000
     */
    customerId?: string;
    customerName?: string;

    /** @format date-time */
    created?: string;
}

export interface IFrame {
    header?: IHeader;
    footer?: Footer;
    search?: ISearch;
    market?: IMarket;
    marketSelector?: IMarketSelector;
    mitFogSettings?: IMitFogSettings;
    settings?: ISiteSettings;
    staticLinks?: IStaticLinks;
    id?: string;
    culture?: string;
    pageNotifications?: IPageNotification[];
    type?: string;
}

export interface IHeader {
    id?: string;
    usp?: USP;
}

export interface USP extends ModuleTheme {
    textColor?: string;
    textColorHEX?: string;
    items: USPItem[];
}

export interface USPItem {
    icon?: IImageMedia;
    text?: string;
    link?: ILink;
}

export interface Footer {
    id?: string;
    contactText?: RichText;
    logoImage?: IImageMedia;
    newsletterText?: RichText;
    newsletterLink?: ILink;
    menu?: INavigationNode[];
    paymentIcons?: FooterIcon[];
    additionalIcons?: FooterIcon[];
}

export interface FooterIcon {
    image: IImageMedia;
    text?: string;
    link: ILink;
}

export interface INavigation {
    mainMenu?: INavigationNode[];
    showLoginButton?: boolean;
    id?: string;
}

export interface ISearch {
    id?: string;
}

export interface IMarket {
    currency?: ICurrency;
    pricesIncludeTax?: boolean;
    id: string;
}

export interface IMarketSelector {
    markets?: IMarketSelectorMarket[];
}
export interface IMitFogSettings {
    root?: IRoot;
    setPasswordLink?: ISetPasswordLink;
}
export interface IRoot {
    text?: string;
    url?: string;
}

export interface ISetPasswordLink {
    text?: string;
    url?: string;
}

export interface ILocalizable {
    culture: string;
}

export interface IGlobalizable {
    market: string;
}

export interface IPageNotification {
    backgroundColor?: string;
    id: string;
    isCloseable?: boolean;
    link?: ILink;
    text?: string;
}

export interface ISiteSettings {
    googleTagManagerId?: string;
}

export interface IStaticLinks {
    basketPage?: ILink;
    brandPage?: ILink;
    checkoutInformationPage?: ILink;
    receiptPage?: ILink;
    productPage?: ILink;
    searchPage?: ILink;
    newsletterConditionsPage?: ILink;
    termsAndConditionsPage?: ILink;
    gdprPage?: ILink;
    storesPage?: ILink;
    notFoundErrorPage?: ILink;
    serverErrorPage?: ILink;
    frontPage?: ILink;
    checkoutDeliveryPage?: ILink;
    salesTermsAndConditions?: ILink;
    freightAndDeliveryPage: ILink;
}

export interface IRedirect {
    type: PageTypes.IPRedirect;
    destination: string;
    permanent: boolean;
}

export interface IPNewsPaper {
    type: PageTypes.IPNewsPaper;
    statusCode?: number;
    breadcrumb?: IBreadcrumb;
    hideBreadcrumb?: boolean;
    meta: { title?: string; description?: string; hideFromRobots?: boolean; canonical?: ILink };
}

export interface INavigationNode {
    link: ILink;
    children?: INavigationNode[];
    promotedChildren?: IPromotedNavigationNode[];
    id?: string;
}

export interface IPromotedNavigationNode {
    image: IImageMedia;
    link: ILink;
    label?: string;
    text?: string;
}

export interface ICurrency {
    symbol?: string;
    id?: string;
}

export interface IMarketSelectorMarket {
    label?: string;
    languages?: IMarketSelectorLanguage[];
    id?: string;
}

export interface ILink {
    text?: string;
    title?: string;
    url: string;
    target?: string;
    id?: string;
}

export interface IMarketSelectorLanguage {
    text?: string;
    title?: string;
    url?: string;
    target?: string;
    id?: string;
    culture?: string;
}

export interface IMarketBanner {
    text?: string;
    buttonText?: string;
    dismissButtonText?: string;
    selectOtherMarketText?: string;
    selectMarketLabel?: string;
    marketLink?: ILink;
}

export interface IPageElement {
    detailsReference: { text?: string; title?: string; url?: string; id?: string };
    id?: string;
    type?: ModuleTypes;
    image?: IMedia[];
}

export interface IPageReference {
    alias?: 'pageReference';
    pageTitle?: string;
    pageDescription?: string;
    pageImage?: IImageMedia;
    callToAction?: ILink;
}

export interface IBreadcrumb {
    items?: ILink[];
}

export interface MarketId {
    value?: string;
}

export interface NumberFormatInfo {
    /** @format int32 */
    currencyDecimalDigits?: number;
    currencyDecimalSeparator?: string;
    isReadOnly?: boolean;
    currencyGroupSizes?: number[];
    numberGroupSizes?: number[];
    percentGroupSizes?: number[];
    currencyGroupSeparator?: string;
    currencySymbol?: string;
    naNSymbol?: string;

    /** @format int32 */
    currencyNegativePattern?: number;

    /** @format int32 */
    numberNegativePattern?: number;

    /** @format int32 */
    percentPositivePattern?: number;

    /** @format int32 */
    percentNegativePattern?: number;
    negativeInfinitySymbol?: string;
    negativeSign?: string;

    /** @format int32 */
    numberDecimalDigits?: number;
    numberDecimalSeparator?: string;
    numberGroupSeparator?: string;

    /** @format int32 */
    currencyPositivePattern?: number;
    positiveInfinitySymbol?: string;
    positiveSign?: string;

    /** @format int32 */
    percentDecimalDigits?: number;
    percentDecimalSeparator?: string;
    percentGroupSeparator?: string;
    percentSymbol?: string;
    perMilleSymbol?: string;
    nativeDigits?: string[];
    digitSubstitution?: 'Context' | 'None' | 'NativeNational';
}

export interface Currency {
    currencyCode?: string;
    format?: NumberFormatInfo;

    /** @format double */
    lowestDenomination?: number;
    isEmpty?: boolean;
}

export interface IFormSubmitResult {
    isSuccess?: boolean;
    redirectUrl?: string;
    message?: string;
    validationFail?: boolean;
    invalidFields?: IFormElementValidationInfo[];
}

export interface IFormElementValidationInfo {
    id?: string;
    name?: string;
    label?: string;
    validationMessage?: string;
    validator?: string;
}

export interface BasketListParams {
    culture: string;
    market: string;
}

export interface BasketLineCreateParams {
    culture: string;
    market: string;
    sku: string;

    /** @format double */
    quantity: number;
}

export interface BasketLineUpdateParams {
    culture: string;
    market: string;

    /** @format double */
    quantity: number;
    sku: string;
}

export interface BasketLineDeleteParams {
    culture: string;
    market: string;
    sku: string;
}

export interface ContentFrameListParams {
    url: string;
}

export interface ContentMarketBannerListParams {
    pageId: string;
    culture: string;
    market: string;
    detectedMarket?: string;
}

export interface ContentModuleListParams {
    pageId: string;
    culture: string;
    market: string;
    moduleId: string;
}

export interface ContentPageListParams {
    url: string;
}

export interface ContentTranslationsListParams {
    culture: string;
}

export interface MarketingSubscriptionListParams {
    email: string;
}

export interface MarketingSubscriptionCreateParams {
    email: string;
}

export interface MarketingSubscriptionDeleteParams {
    email: string;
}

export interface SearchAutoCompleteListParams {
    culture: string;
    market: string;
    query?: string;
    id?: string;
    pageSize?: number;
}

export interface SearchResultBySearchIdListParams {
    culture: string;
    market: string;
    query?: string;
    searchId?: string;
    pageSize?: number;
    page?: number;
}

export interface SearchResultListParams {
    culture: string;
    market: string;
    query?: string;
    category?: string;
    pageSize?: number;
    page?: number;
}
