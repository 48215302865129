import { useMemo } from 'react';
import useEmitter from './useEmitter';
import useListener from './useListener';
import type { Params } from '../types';

export default function useCustomEvent<T>({ eventName, onSignal }: Params<T>) {
    const handleSignal = useMemo(() => onSignal || (() => null), [onSignal]);

    useListener<T>({
        eventName,
        onSignal: handleSignal,
    });

    return useEmitter<T>(eventName);
}
