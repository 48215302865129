/* spellchecker: disable */
export enum AutocompleteType {
    Adresse = 'adresse',
    Vejnavn = 'vejnavn',
    Adgangsadresse = 'adgangsadresse',
}

export type AutocompleteList =
    | ({
          tekst: string;
          caretpos: number;
          forslagstekst: string;
          stormodtagerpostnr?: boolean;
      } & (Adresse | Vejnavn | Adgangsadresse))[]
    | [];

type Adresse = {
    type: AutocompleteType.Adresse;
    data: {
        id: string;
        status: number;
        darstatus: number;
        vejkode: string;
        vejnavn: string;
        adresseringsvejnavn: string;
        husnr: string;
        etage: string | undefined;
        dør: string | undefined;
        supplerendebynavn?: string;
        postnr: string;
        postnrnavn: string;
        stormodtagerpostnr?: boolean;
        stormodtagerpostnrnavn?: string;
        kommunekode: string;
        adgangsadresseid: string;
        x: number;
        y: number;
        href: string;
    };
};

type Vejnavn = {
    type: AutocompleteType.Vejnavn;
    data: {
        navn: string;
        href: string;
    };
};

type Adgangsadresse = {
    type: AutocompleteType.Adgangsadresse;
    data: {
        id: string;
        status: number;
        darstatus: number;
        vejkode: string;
        vejnavn: string;
        adresseringsvejnavn: string;
        husnr: string;
        supplerendebynavn?: string;
        postnr: string;
        postnrnavn: string;
        stormodtagerpostnr?: boolean;
        stormodtagerpostnrnavn?: string;
        kommunekode: string;
        x: number;
        y: number;
        href: string;
    };
};
