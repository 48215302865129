import NextImage, { ImageProps as NextImageProps } from 'next/image';
import { umbracoLoader } from './loaders/umbracoLoader';

export type ImageProps = NextImageProps & {
    focalPoint?: null | {
        left: number;
        top: number;
    };
};

export const UmbracoImage = ({ src, style, alt = '', focalPoint, ...rest }: ImageProps) => {
    const isStatic = !/^http?s:\/\//.test(src.toString());

    const objectPosition = focalPoint
        ? `${focalPoint.left * 100}% ${focalPoint.top * 100}%`
        : 'unset';

    return (
        <NextImage
            loader={isStatic ? undefined : umbracoLoader}
            src={src}
            style={{ objectPosition, ...style }}
            alt={alt}
            {...rest}
        />
    );
};
