import { ShopTypes, UmbracoTypes } from '~/lib/data-contract';
import { usePage, useProduct } from '~/services';

export default function useProductImages() {
    const { data: page } = usePage<UmbracoTypes.IP50ProductDetailPage>();

    const itemNo = decodeURIComponent(page?.pageProperties?.productItemNumber || '');
    return useProduct<ShopTypes.ProductQueryResource[]>(itemNo, (data) =>
        (data.product?.item?.images || []).concat(data.product?.images || []).filter(Boolean)
    );
}
