import { HTTPError } from 'ky';
import { useQuery } from '@tanstack/react-query';
import { Loop54Types } from '~/lib/data-contract';
import { Loop54, Prefetch } from '~/services';
import { hours, seconds } from '~/shared/utils/helpers';
import { RelatedEntitiesRequest } from '../types';

export default function useRelatedEntities<SelectType = Response>(
    params: RelatedEntitiesRequest | undefined,
    select?: (data: Response) => SelectType,
) {
    return useQuery<Response, HTTPError | Error, SelectType>(
        ['relatedEntities', params],
        ({ signal }) => Loop54.operations.getRelatedEntities(signal, params),
        {
            enabled: !!params,
            select,
            retry: false,
            keepPreviousData: true,
            cacheTime: 0, // Disable cache so that we get personalized result every time page is requested
            staleTime: hours(1), // Keep personalized result for 1 hour
            refetchOnWindowFocus: false, // Never refetch on window focus since that will cause the products to change position
        },
    );
}

export const prefetchRelatedEntities = ({ queryClient }: Prefetch, productId: string) => {
    const params = {
        entity: {
            id: productId,
            type: 'Product',
        },
        resultsOptions: {
            take: 10,
        },
    };

    return queryClient.prefetchQuery(
        ['relatedEntities', params],
        ({ signal }) => Loop54.operations.getRelatedEntities(signal, params),
        {
            cacheTime: seconds(10),
            staleTime: hours(1),
        },
    );
};

type Response = Loop54Types.GetRecommendedEntitiesResponse;
