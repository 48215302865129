import { HTTPError } from 'ky';
import { useQuery } from '@tanstack/react-query';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';

export default function useOrderedBasket<SelectType = Basket>(
    reference: string | undefined,
    select?: (data: Basket) => SelectType,
) {
    return useQuery<Basket, HTTPError | Error, SelectType>(
        ['orderedBasket', { reference }],
        () => Shop.operations.getBasket(reference),
        {
            retry: (failureCount, error) => {
                // If basket not found, we assume requested basket reference is not valid
                if (error instanceof HTTPError && error.response?.status === 404) {
                    return false;
                }

                // Any other failed request will be retried.
                return failureCount < 3;
            },
            enabled: !!reference,
            select,
            staleTime: 60 * 60 * 100, // 1 hour
        },
    );
}

type Basket = ShopTypes.BasketResponse;
