import { HTTPError } from 'ky';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ShopTypes } from '~/lib/data-contract';
import { Shop, useBasketReference } from '~/services';
import { useBasketTimer } from '~/shared/utils/storage';

export default function useSetDeliveryMethod(deliveryId: number) {
    const queryClient = useQueryClient();
    const { data: reference } = useBasketReference();
    const [, setTimer] = useBasketTimer();

    return useMutation<Basket, HTTPError | Error, Arguments>(
        ({ freightOption, freightProvider, parcelServicePoint }) =>
            Shop.operations.setDeliveryMethod({
                deliveryId,
                freightOption,
                freightProvider,
                parcelServicePoint,
                reference,
            }),
        {
            onSuccess: (data) => {
                queryClient.setQueryData(['basket', { reference: data.basketReference }], data);
                setTimer(data.updated.toString());
            },
        },
    );
}

type Basket = ShopTypes.BasketResponse;
type Arguments = {
    freightProvider?: string;
    freightOption?: ShopTypes.FreightOption;
    parcelServicePoint?: ShopTypes.ParcelServicePoint;
};
