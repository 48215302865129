import { Storage } from '~/shared/utils/storage';
import { useLocalStorageString } from '../utils';

export default function useFreightType() {
    return useLocalStorageString<FreightType>(Storage.freightType, FreightTypes.Online);
}

export type FreightType = FreightTypes;
export enum FreightTypes {
    Online = 'online',
    Pickup = 'pickup',
}
