import { ShopTypes } from '~/lib/data-contract';
import { pushToDataLayer } from '../utils/pushToDataLayer';
import { GAEcommerceEvent } from '../utils/types';
import { getGAItem } from '../utils/getGAItem';
import { Cookie } from '~/shared/utils/cookie';

export function viewItem(
    product?: ShopTypes.ProductQueryProduct,
    priceData?: ShopTypes.SalesUnitPrice,
    activeCampaigns?: ShopTypes.ActiveCampaignDetails[],
    itemListName?: string,
) {
    if (product) {
        pushToDataLayer({ ecommerce: null });
        pushToDataLayer({
            event: GAEcommerceEvent.ViewItem,
            ecommerce: {
                items: [getGAItem(1, product, priceData, activeCampaigns, itemListName)],
            },
        }).then(() => sessionStorage.removeItem(Cookie.currentItemList));
    }
}
