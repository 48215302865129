import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';

export const InputWrapper = styled.div<{
    isHighlighted?: boolean;
    noBorder?: boolean;
    noPadding?: boolean;
    disabled?: boolean;
}>(
    ({ theme }) => ({
        alignItems: 'center',
        backgroundColor: theme.colors.offWhite,
        border: `1px solid ${theme.colors.grey60}`,
        borderRadius: 3,
        display: 'flex',
        flexDirection: 'row',
        marginTop: theme.space[2],
        position: 'relative',
        transition: 'border-color 180ms ease',
    }),

    ifProp('isHighlighted', ({ theme }) => ({
        borderColor: theme.colors.thunderBlack,
    })),
    ifProp('disabled', ({ theme }) => ({
        borderColor: theme.colors.grey60,
    })),
);

export const Placeholder = styled.label<{ isHighlighted?: boolean }>(
    ({ theme }) => ({
        backgroundColor: theme.colors.offWhite,
        color: theme.colors.thunderBlack,
        fontSize: theme.fontSizes.mouse,
        left: theme.space[1],
        lineHeight: theme.lineHeights.rat,
        padding: `0 ${theme.space[1]}`,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        transformOrigin: 'top left',
        transition: 'transform 180ms ease, color 180ms ease',
        pointerEvents: 'none',
        whiteSpace: 'nowrap',
    }),

    ifProp('isHighlighted', ({ theme }) => ({
        color: theme.colors.grey60,
        transform: 'translateY(-200%) scale(.88)',
    })),
);

export const Input = styled.input<{ hideIcon?: boolean; disabled?: boolean | undefined }>(
    ({ theme, hideIcon }) => ({
        border: 'none',
        color: theme.colors.thunderBlack,
        fontSize: theme.fontSizes.mouse,
        width: '100%',
        height: theme.space[6],
        lineHeight: theme.lineHeights.chicken,
        padding: `0 ${hideIcon ? theme.space[2] : '35px'} 0 ${theme.space[2]}`,
        background: 'transparent',

        '&:focus': {
            outline: 'none',
        },
        '&:disabled': {
            cursor: 'default',
            color: theme.colors.grey60,
        },

        // Number inputs
        '&[type="number"]::-webkit-outer-spin-button, &[type="number"]::-webkit-inner-spin-button':
            {
                WebkitAppearance: 'none',
                margin: 0,
            },
        '&[type="number"]': {
            MozAppearance: 'textfield',
        },
    }),
);
