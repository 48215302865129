import { useBasket } from '~/services';

export default function useCustomerComment(deliveryId: number) {
    return useBasket(
        (data) =>
            data.contexts
                ?.flatMap((context) => context?.freights)
                ?.find((freight) => freight?.deliveryId === deliveryId)?.customerComment
    );
}
