import loop54 from '~/lib/api/loop54';
import { Loop54Types } from '~/lib/data-contract';
import { Loop54 } from '~/services';
import { EntitiesRequest } from '../types';

export default function getEntities(
    signal: AbortSignal | undefined,
    params: EntitiesRequest | undefined
) {
    if (!params) {
        return Promise.reject(new Error(Loop54.errors.missingParams.message));
    }

    return loop54
        .post(Loop54.utils.getUrl('/getEntities'), {
            json: params,
            signal,
        })
        .json<Loop54Types.GetEntitiesResponse>();
}
