import { HTTPError } from 'ky';
import { useQuery } from '@tanstack/react-query';
import { FilterDefaultsResponse, Prefetch, Umbraco } from '~/services';
import { hours, minutes } from '~/shared/utils/helpers';
import { sharedQueryClient } from '~/shared/utils/request';

export default function useFilterDefaults<SelectType = FilterDefaultsResponse>(
    select?: (data: FilterDefaultsResponse) => SelectType,
) {
    return useQuery<FilterDefaultsResponse, HTTPError | Error, SelectType>(
        ['filterDefaults'],
        ({ signal }) => Umbraco.operations.getFilterDefaults(signal),
        {
            retry: false,
            staleTime: hours(1),
            cacheTime: minutes(1),
            refetchOnWindowFocus: true,
            keepPreviousData: true,
            select,
        },
    );
}

export async function prefetchFilterDefaults({ queryClient }: Prefetch) {
    await sharedQueryClient.fetchQuery(['filterDefaults'], ({ signal }) =>
        Umbraco.operations.getFilterDefaults(signal),
    );

    return queryClient.setQueryData<FilterDefaultsResponse>(
        ['filterDefaults'],
        () => sharedQueryClient.getQueryData(['filterDefaults']),
        {
            updatedAt: sharedQueryClient.getQueryState(['filterDefaults'])?.dataUpdatedAt,
        },
    );
}
