import umbraco from '~/lib/api/umbraco';
import { UmbracoTypes } from '~/lib/data-contract';
import { Umbraco } from '~/services';

export default function getNavigation(signal: AbortSignal | undefined, asPath: string) {
    const url = Umbraco.utils.getUrl(`/content/frame/navigation`);

    url.searchParams.append('url', asPath);

    return umbraco.get(url, { signal }).json<UmbracoTypes.INavigationNode>();
}
