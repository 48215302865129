import { ComponentProps, ComponentPropsWithoutRef, forwardRef } from 'react';
import ArrowLeft from '~/icons/iconography/arrow/left.svg';
import ArrowRight from '~/icons/iconography/arrow/right.svg';
import { StyledLink } from '~/shared/styles/Link';
import { StyledIcon, StyledLinkWrapper } from './styled';

export type LinkProps = ComponentPropsWithoutRef<'a'> & {
    /**
     * Should link be shown with the arrow icon
     */
    animation?: 'default' | 'none' | 'reverse' | 'static';
    as?: ComponentProps<typeof StyledLinkWrapper>['as'];
    fullWidth?: boolean;
    multipleElements?: boolean;
    withIcon?: boolean;
    displayFlex?: boolean;
    iconLeft?: boolean;
    customIcon?: React.ReactNode;
};

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link(
    {
        animation = 'default',
        children,
        displayFlex = false,
        fullWidth = false,
        multipleElements = false,
        withIcon = false,
        iconLeft = false,
        customIcon,
        ...rest
    },
    ref,
) {
    const linkAnimation = withIcon ? 'none' : animation;

    return (
        <StyledLinkWrapper fullWidth={fullWidth} iconLeft={iconLeft} ref={ref} {...rest}>
            <StyledLink
                animation={linkAnimation}
                displayFlex={displayFlex}
                fullWidth={fullWidth}
                multipleElements={multipleElements}
            >
                {withIcon && iconLeft ? (
                    <StyledIcon fullWidth={fullWidth} iconLeft={iconLeft}>
                        {customIcon ? customIcon : <ArrowLeft />}
                    </StyledIcon>
                ) : null}

                {children}

                {withIcon && !iconLeft ? (
                    <StyledIcon fullWidth={fullWidth} iconLeft={iconLeft}>
                        {customIcon ? customIcon : <ArrowRight />}
                    </StyledIcon>
                ) : null}
            </StyledLink>
        </StyledLinkWrapper>
    );
});

Link.displayName = 'Link';
