import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { Button } from '~/shared/components/Button';
import { breakpoints } from '~/theme';

type FilterButtonProps = {
    removeAllElementFilter?: boolean;
};

export const FilterButton = styled(Button)<FilterButtonProps>(
    ({ theme }) => ({
        height: theme.lineHeights.wolf,
        borderColor: theme.colors.grey50,

        'div, span, svg': {
            marginRight: theme.space[1],
        },

        [breakpoints.sm]: {
            ':hover': {
                borderColor: theme.colors.brandBlue,

                'div, span': {
                    colors: theme.colors.brandBlue,
                },
            },
        },
    }),
    ifProp('removeAllElementFilter', ({ theme }) => ({
        [breakpoints.sm]: {
            border: 'none',
            backgroundColor: theme.colors.brandBlue,

            ':hover': {
                backgroundColor: theme.colors.brandBlue,
            },
        },
    }))
);
