import NextLink from 'next/link';
import { useRouter } from 'next/router';
import ChevronDown from '~/icons/iconography/chevron/up.svg';
import { UmbracoTypes } from '~/lib/data-contract';
import { StyledLinkAnimationText } from '~/shared/styles/Link';
import { SideNavigationItemLevel } from './models';
import { List, ListItem, Icon, LinkLevelThree, LinkLevelFour, LinkLevelFive } from './styled';

type Props = UmbracoTypes.INavigationNode;

export const NavigationItem = ({ link, children }: Props) => {
    const { query } = useRouter();
    const slugQuery = query.slug as string[];
    const activeUrl = `/${slugQuery?.join('/')}`;
    const isActivePath = link.url === activeUrl;
    const matchesPath = activeUrl.includes(link.url);

    const itemLevel = link.url?.split('/')?.slice(1).length;
    const hasChildren = children?.length;

    const LinkLevelComponent = LinkLevel(String(itemLevel) as SideNavigationItemLevel);

    return (
        <ListItem isActive={isActivePath && itemLevel > 3}>
            <NextLink href={link.url} prefetch={false} passHref legacyBehavior>
                <LinkLevelComponent
                    animation="reverse"
                    title={link.title}
                    isActive={isActivePath}
                    fullWidth={(String(itemLevel) as SideNavigationItemLevel) === '3'}
                    displayFlex={(String(itemLevel) as SideNavigationItemLevel) === '4'}
                    multipleElements
                >
                    <StyledLinkAnimationText>{link.title}</StyledLinkAnimationText>

                    {hasChildren ? (
                        <Icon isActive={isActivePath}>
                            <ChevronDown />
                        </Icon>
                    ) : null}
                </LinkLevelComponent>
            </NextLink>

            {matchesPath && hasChildren ? (
                <List>
                    {children?.map((item, index) => {
                        return (
                            <NavigationItem
                                key={`SideNavigationItem-${item.id}-${index}`}
                                {...item}
                            />
                        );
                    })}
                </List>
            ) : null}
        </ListItem>
    );
};

function LinkLevel(itemLevel: SideNavigationItemLevel) {
    if (itemLevel === '3') {
        return LinkLevelThree;
    }
    if (itemLevel === '4') {
        return LinkLevelFour;
    }
    return LinkLevelFive;
}
