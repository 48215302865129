import { useCallback } from 'react';
import { isBrowser } from '~/shared/utils/platform';
import { Events } from '../events';

type CallEventCallback<T> = (data?: T) => void;

export default function useEmitter<T>(eventName: Events) {
    const callEvent = useCallback<CallEventCallback<T>>(
        (data) => {
            const event = new CustomEvent(eventName, {
                detail: data,
            });

            globalThis.dispatchEvent(event);
        },
        [eventName]
    );

    return isBrowser ? callEvent : () => undefined;
}
