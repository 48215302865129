import { getProductFilter } from '~/features/ProductList/utils';
import { Loop54, Loop54ProductAttributes, usePopularEntities } from '~/services';

type Arguments = {
    amount?: number;
    onlyShowInStock?: boolean;
};

type filter = {
    type: string;
    attributeName?: Loop54ProductAttributes;
    value: string | boolean;
};

export default function usePopularResults({ amount = 10, onlyShowInStock = true }: Arguments) {
    const filters: filter[] = [getProductFilter()];

    if (onlyShowInStock) {
        filters.push({
            type: 'attribute',
            attributeName: Loop54ProductAttributes.InStock,
            value: onlyShowInStock,
        });
    }

    return usePopularEntities(
        {
            resultsOptions: {
                filter: {
                    and: filters,
                },
                sortBy: [{ type: 'popularity' }],
                take: amount,
            },
        },
        (data) => ({
            ...data,
            results: Loop54.utils.reduceLoop54Product(data.results),
        }),
    );
}
