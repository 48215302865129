import { useRouter } from 'next/router';
import { EXCLUDED_FACET_LIST, EXCLUDED_FILTER_LIST } from '~/features/ProductList';

const OPTIONS = { shallow: true, scroll: false };

const SPLIT_CHAR = ';';

const EXCLUDED_LIST = [...EXCLUDED_FACET_LIST, ...EXCLUDED_FILTER_LIST];

// TODO: Use specific prefix for facet filters

const useUrlQueryState = (): [
    Record<string, string[]>,
    (key: string, values: string[]) => void,
    () => void
] => {
    const router = useRouter();
    const [pathname, searchQuery] = router.asPath.split('?');
    const urlSearchParameters = new URLSearchParams(searchQuery);

    // Only use url parameters on client
    const queryState = router.isReady
        ? [...urlSearchParameters.entries()].reduce<{ [key: string]: string[] }>(
              (entries, [key, value]) => {
                  return {
                      ...entries,
                      [key]: entries[key]
                          ? [...entries[key], ...value.split(SPLIT_CHAR)]
                          : [...value.split(SPLIT_CHAR)],
                  };
              },
              {}
          )
        : {};

    const setQueryState = (key: string, values: string[]) => {
        if (values.length < 1) {
            urlSearchParameters.delete(key);
        } else {
            urlSearchParameters.set(key, values.join(SPLIT_CHAR));
        }

        router.replace({ pathname, query: urlSearchParameters.toString() }, undefined, OPTIONS);
    };

    const clearQueryState = () => {
        // Don't clear excluded filters from query
        Array.from(urlSearchParameters.keys())
            .filter((key) => !EXCLUDED_LIST.includes(key))
            .forEach((key) => {
                urlSearchParameters.delete(key);
            });

        router.replace({ pathname, query: urlSearchParameters.toString() }, undefined, OPTIONS);
    };

    return [queryState, setQueryState, clearQueryState];
};

export default useUrlQueryState;
