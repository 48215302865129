import { useLocalStorage } from '@rehooks/local-storage';

const useLocalStorageNumber = (
    key: string,
    initial: number | undefined
): [number | undefined, (newValue: number | undefined) => void, () => void] => {
    const [value, setValue, clearValue] = useLocalStorage<number | string | undefined>(
        key,
        initial
    );

    return [value ? parseInt(value.toString(), 10) : undefined, setValue, clearValue];
};

export default useLocalStorageNumber;
