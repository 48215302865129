import { useQuery } from '@tanstack/react-query';
import { ShopTypes } from '~/lib/data-contract';
import { Shop, useBasket, useBasketReference } from '~/services';

export default function useDeliveryOptions(deliveryId: number) {
    const { data: reference } = useBasketReference();
    const { data: totalPrice } = useBasket((basket) => basket.total.priceInclVat);

    return useQuery<ShopTypes.DeliveryOptionsResponse, Error>(
        ['deliveryOptions', { reference, deliveryId, totalPrice }],
        () => Shop.operations.getDeliveryOptions(reference, deliveryId),
        {
            enabled: !!reference,
        },
    );
}
