import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { breakpoints } from '~/theme';

type StyledButtonProps = {
    isOpen: boolean;
    noLabel: boolean;
};

export const StyledButton = styled.button<StyledButtonProps>(
    ({ theme }) => ({
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.colors.white,
        border: `1px solid ${theme.colors.grey50}`,
        borderRadius: 2,
        cursor: 'pointer',
        display: 'flex',
        fontSize: theme.fontSizes.mouse,
        height: theme.space[6],
        lineHeight: theme.lineHeights.rat,
        width: '100%',
        color: theme.colors.black,
        svg: {
            width: theme.space[4],
            height: theme.space[4],
        },

        label: {
            cursor: 'pointer',
        },
    }),

    ifProp('noLabel', () => ({
        display: 'none',
    })),

    ifProp('isOpen', ({ theme }) => ({
        backgroundColor: theme.colors.primary,
        color: theme.colors.white,

        svg: {
            transform: 'rotate(180deg)',
        },
    })),
);

export const List = styled.ul<{ noLabel: boolean; isHidden: boolean }>(
    ({ theme }) => ({
        zIndex: theme.zIndices[2],
        overflowY: 'scroll',
        backgroundColor: theme.colors.white,
        boxShadow: theme.shadows.content,
        position: 'absolute',
        width: 'calc(100% + 40px)',
        // 50px is the actual height of the option, it can maximum be 7½ options high.
        '--sevenOptions': 'calc(7 * 50px)',
        maxHeight: `calc(var(--sevenOptions) + 25px)`,
    }),

    ifProp('isHidden', () => ({ display: 'none' })),

    ifProp('noLabel', () => ({ boxShadow: 'none' })),
);

export const SearchFilter = styled.li<{ isHidden: boolean }>(
    ({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 55,
        padding: `0 ${theme.space[2]}`,
        borderBottom: `1px solid ${theme.colors.grey30}`,
    }),
    ifProp('isHidden', () => ({ display: 'none' })),
);

export const InputContainer = styled.div(({ theme }) => ({
    display: 'flex',
    width: '100%',
    border: `1px solid ${theme.colors.grey50}`,
    borderRadius: 2,
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.space[1],

    svg: {
        width: 25,
        height: 25,
        color: theme.colors.grey50,
    },
}));

export const Input = styled.input(({ theme }) => ({
    display: 'flex',
    flex: 1,
    outline: 'none',
    fontSize: theme.fontSizes.beetle,
    lineHeight: theme.lineHeights.frog,
    border: 'none',

    [`&::placeholder`]: {
        transition: '0.1s color',
        color: theme.colors.grey50,
    },

    [breakpoints.lg]: {
        fontSize: theme.fontSizes.mouse,
        lineHeight: theme.lineHeights.chicken,
    },
}));

export const ClearButton = styled.button(({ theme }) => ({
    position: 'absolute',
    display: 'flex',
    right: theme.space[3],
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    backgroundColor: theme.colors.white,

    svg: {
        width: 16,
        height: 16,
        color: theme.colors.black,
    },
}));

export const Item = styled.li<{ highlighted: boolean }>(
    ({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        height: theme.space[7],
        padding: `0 ${theme.space[2]}`,
        borderBottom: `1px solid ${theme.colors.grey30}`,
        ':hover': {
            color: theme.colors.primary,
        },
    }),

    ifProp('highlighted', ({ theme }) => ({
        color: theme.colors.primary,
        backgroundColor: theme.colors.grey05,
    })),
);

export const ItemLeftContainer = styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
}));

export const ItemCheckbox = styled.input(({ theme }) => ({
    appearance: 'none',
    cursor: 'pointer',
    backgroundColor: theme.colors.offWhite,
    border: `1px solid ${theme.colors.thunderBlack}`,
    display: 'grid',
    height: theme.fontSizes.chicken,
    minHeight: theme.fontSizes.chicken,
    minWidth: theme.fontSizes.chicken,
    padding: 0,
    placeContent: 'center',
    width: theme.fontSizes.chicken,
    borderRadius: 0,

    '&:checked:before': {
        content: '""',
        backgroundColor: theme.colors.thunderBlack,
        height: '0.60em',
        width: '0.60em',
    },
}));

export const ItemLabel = styled.span(({ theme }) => ({
    marginLeft: theme.space[2],
    fontSize: theme.fontSizes.mouse,
    lineHeight: theme.lineHeights.rat,
}));

export const ItemCount = styled.span(({ theme }) => ({
    fontSize: theme.fontSizes.mouse,
    lineHeight: theme.lineHeights.rat,
    color: theme.colors.grey60,
}));
