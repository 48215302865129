import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { settings } from '../../settings';

export const AnimatedKnob = styled(motion.button)(({ theme }) => ({
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    margin: `${settings.expand}px ${-settings.buttonSize / 2}px`,
    minWidth: `${settings.buttonSize}px`,
    height: `${settings.buttonSize}px`,
    padding: 0,
    border: 'none',
    outline: 'none',
    backgroundColor: theme.colors.black,
    borderRadius: '100%',
    cursor: 'grab',
}));
