import { ShopTypes } from '~/lib/data-contract';
import { getGABasketItems } from '../utils/getGABasketItems';
import { pushToDataLayer } from '../utils/pushToDataLayer';
import { GAEcommerceEvent } from '../utils/types';
import { getTotalBasketDiscount } from '../utils/getTotalBasketDiscount';
import { getShippingTiers } from '../utils/getShippingTiers';

export function purchase(basket: ShopTypes.BasketResponse | undefined, paymentType?: string) {
    if (!basket || !paymentType) {
        return;
    }

    pushToDataLayer({ ecommerce: null });
    pushToDataLayer({
        event: GAEcommerceEvent.Purchase,
        ecommerce: {
            currency: 'DKK',
            value: basket.subTotal.priceInclVat,
            payment_type: paymentType,
            shipping_tier: basket.contexts ? getShippingTiers(basket.contexts) : '',
            tax: basket.subTotal.priceInclVat - basket.subTotal.priceExVat,
            shipping: basket.freightPrice.priceInclVat,
            transaction_id: String(basket.orderNumber),
            discount: getTotalBasketDiscount(basket.contexts),
            email: basket.customerAddress?.email,
            phone: String(basket.customerAddress?.phone),
            street:
                basket.customerAddress?.addressLine1 +
                (basket.customerAddress?.addressLine2
                    ? ', ' + basket.customerAddress?.addressLine2
                    : ''),
            city: basket.customerAddress?.city,
            postcode: basket.customerAddress?.postalCode,
            // coupon: // TODO when BE returns data
            items: getGABasketItems(basket.contexts),
        },
    });
}
