import { theme } from './theme';

type QueryOptions = {
    minWidth?: string;
    maxWidth?: string;
};

export const breaks = {
    /**
     * Large mobile devices
     */
    xxs: 480,
    /**
     * Mobile devices. Use orientation media queries for landscape styling
     */
    xs: 767,
    /**
     * Small tablets
     */
    sm: 768,
    /**
     * Large tablets, small laptops
     */
    md: 1024,
    /**
     * Desktops, laptops
     */
    lg: 1367,
    /**
     * Wide screens
     */
    xl: 1620,

    /**
     * Point where content stops growing
     */
    contentMax: theme.sizes.contentMaxWidth,

    /**
     * Beyond max content width
     */
    pageMax: theme.sizes.pageMaxWidth,
};

export const queries = {
    xxs: `(min-width: ${breaks.xxs}px)`,
    xs: `(max-width: ${breaks.xs}px)`,
    sm: `(min-width: ${breaks.sm}px)`,
    md: `(min-width: ${breaks.md}px)`,
    lg: `(min-width: ${breaks.lg}px)`,
    xl: `(min-width: ${breaks.xl}px)`,
    contentMax: `(min-width: ${breaks.contentMax}px)`,
    pageMax: `(min-width: ${breaks.pageMax}px)`,
    reducedMotion: `(prefers-reduced-motion)`,
};

export const breakpoints = {
    xxs: `@media ${queries.xxs}`,
    xs: `@media ${queries.xs}`,
    sm: `@media ${queries.sm}`,
    md: `@media ${queries.md}`,
    lg: `@media ${queries.lg}`,
    xl: `@media ${queries.xl}`,
    contentMax: `@media ${queries.contentMax})`,
    pageMax: `@media ${queries.pageMax}`,

    /**
     * Prefers reduced motion
     */
    reducedMotion: `@media ${queries.reducedMotion}`,

    custom: (query: string | QueryOptions) => {
        let conditions: string[] = [];

        if (typeof query === 'string') {
            conditions = [`(${query})`];
        } else {
            conditions = [
                query.maxWidth ? `(max-width: ${query.maxWidth})` : '',
                query.minWidth ? `(min-width: ${query.minWidth})` : '',
            ];
        }

        const mediaQuery = conditions.filter(Boolean).join(' and ');
        return `@media ${mediaQuery}`;
    },
};
