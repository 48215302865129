import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const Container = styled.div(({ theme }) => ({
    position: 'absolute',
    width: '100%',
    background: theme.colors.offWhite,
    padding: `${theme.space[2]} ${theme.space[4]} ${theme.space[4]}`,
    boxShadow: theme.shadows.content,
    zIndex: theme.zIndices[1],

    [breakpoints.md]: {
        width: 245,
    },
}));

export const Fields = styled.div(({ theme }) => ({
    display: 'flex',
    gap: theme.space[8],
    justifyContent: 'space-between',
    position: 'relative',

    [breakpoints.md]: {
        gap: theme.space[7],
    },

    ':after': {
        content: '""',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'block',
        marginTop: theme.space[1],
        height: 1,
        width: 21,
        order: 0,
        background: theme.colors.thunderBlack,
    },

    '> div': {
        flex: '1 1',
    },
}));

export const Slider = styled.div(({ theme }) => ({
    padding: `${theme.space[3]} 0`,
}));

export const Buttons = styled.div(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.space[1],
}));
