import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';

type StyledProps = {
    fullWidth: boolean;
    iconLeft: boolean;
};

export const StyledLinkWrapper = styled.a<StyledProps>(
    ({ theme, iconLeft }) => ({
        fontFamily: theme.fonts.body,
        textDecoration: 'none',
        color: theme.colors.nightBlack,

        ':hover': {
            [`${StyledIcon} svg`]: {
                transform: `scale(1.8) rotate(${iconLeft ? '45deg' : '-45deg'})`,
            },
        },
    }),
    ifProp('fullWidth', {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    })
);

export const StyledIcon = styled.span<StyledProps>(
    ({ theme }) => ({
        marginLeft: theme.space[3],
        display: 'inline-flex',

        svg: {
            transform: 'scale(1.8)',
            transition: 'transform 200ms cubic-bezier(.25,.46,.45,.94)',
            height: 10,
            width: 10,
        },
    }),
    ifProp('fullWidth', {
        marginLeft: 0,
    }),
    ifProp('iconLeft', ({ theme }) => ({
        marginLeft: 0,
        marginRight: theme.space[3],
    }))
);
