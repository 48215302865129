import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { Button } from '~/shared/components/Button';
import { breakpoints } from '~/theme';

export const Container = styled.ul(({ theme }) => ({
    display: 'none',
    gap: theme.space[1],
    marginBottom: theme.space[4],

    [breakpoints.sm]: {
        '--columns': 3,
        display: 'grid',
        gridTemplateColumns: 'repeat(var(--columns), minmax(0, 1fr))',
    },

    [breakpoints.md]: {
        '--columns': 4,
    },

    [breakpoints.lg]: {
        '--columns': 6,
    },
}));

export const FilterItem = styled.li<{ showAllFilters: boolean }>(
    {
        position: 'relative',
        [breakpoints.sm]: {
            ':nth-of-type(1n+3)': {
                display: 'none',
            },
        },

        [breakpoints.md]: {
            ':nth-of-type(1n+3)': {
                display: 'list-item',
            },
            ':nth-of-type(1n+4)': {
                display: 'none',
            },
        },

        [breakpoints.lg]: {
            ':nth-of-type(1n+4)': {
                display: 'list-item',
            },
            ':nth-of-type(1n+5)': {
                display: 'none',
            },
        },
    },
    ifProp('showAllFilters', () => ({
        display: 'list-item !important',
    })),
);

export const ShowAllFiltersButton = styled.li<{ totalAmountOfFilters: number | undefined }>(
    ({ totalAmountOfFilters }) => ({
        [breakpoints.sm]: {
            display: [totalAmountOfFilters! < 3 ? 'none' : 'flex'],
        },
        [breakpoints.md]: {
            display: [totalAmountOfFilters! < 4 ? 'none' : 'flex'],
        },
        [breakpoints.lg]: {
            display: [totalAmountOfFilters! < 5 ? 'none' : 'flex'],
        },
    }),
);

export const RangeToggleButton = styled(Button)<{ isOpen?: boolean }>(
    ({ theme }) => ({
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.grey50,
        color: theme.colors.thunderBlack,
        height: theme.space[6],
        width: '100%',

        '&:hover': {
            borderColor: theme.colors.grey50,
            color: theme.colors.thunderBlack,
        },
    }),

    ifProp('isOpen', ({ theme }) => ({
        backgroundColor: theme.colors.primary,
        color: theme.colors.white,

        '&:hover': {
            backgroundColor: theme.colors.primary,
            color: theme.colors.white,
        },

        svg: {
            transform: 'rotate(180deg)',
        },
    })),
);

export const SkeletonWrapper = styled.div(({ theme }) => ({
    display: 'none',
    gap: theme.space[1],

    [breakpoints.lg]: {
        display: 'flex',
    },
}));
export const SkeletonWrapperTablet = styled.div(({ theme }) => ({
    display: 'flex',
    gap: theme.space[1],
}));
