import { Prefetch } from '~/services';
import { Loop54ProductAttributes } from '../types';
import {
    buildPreFetcherForEntities,
    useEntitiesOrEntitiesByAttribute,
    type EntitiesOrEntitiesByAttributeRequest,
    type EntitiesOrEntitiesByAttributeResponse,
} from './useEntitiesOrEntitiesByAttributes';

const queryKey = 'skuEntities';

export default function useSKUEntities<SelectType = EntitiesOrEntitiesByAttributeResponse>(
    params: EntitiesOrEntitiesByAttributeRequest | undefined,
    select?: (data: EntitiesOrEntitiesByAttributeResponse) => SelectType,
) {
    return useEntitiesOrEntitiesByAttribute(queryKey, params, select);
}
type PrefetchBrandEntities = {
    productNumbers: string;
    isMobile: boolean;
} & Prefetch;

export function prefetchSKUEntities({
    queryClient,
    productNumbers,
    isMobile,
}: PrefetchBrandEntities) {
    const params: EntitiesOrEntitiesByAttributeRequest = {
        resultsOptions: {
            filter: {
                or: productNumbers
                    ?.trim()
                    .split('\n')
                    .forEach((product) => {
                        return {
                            type: 'attribute',
                            attributeName: Loop54ProductAttributes.SKU,
                            value: product,
                        };
                    }),
            },
            sortBy: [{ type: 'relevance' }],
            take: isMobile ? 4 : 25,
        },
    };

    return buildPreFetcherForEntities({ queryKey, params, queryClient });
}
