import { QueryFunctionContext, QueryKey, useQueries } from '@tanstack/react-query';
import { Loop54Types } from '~/lib/data-contract';
import { Loop54, Shop } from '~/services';
import { searchOptions } from './useSearch';

export default function useSearchAutocomplete(query: string | undefined) {
    const searchQuery: Loop54Types.SearchRequest = {
        query: query ?? '',
        customData: {
            directSearch: true,
        },
        resultsOptions: {
            filter: { type: 'type', value: 'Product' },
            sortBy: [{ type: 'relevance' }],
            take: 4,
        },
    };

    const searchAutocompleteQuery: Loop54Types.AutoCompleteRequest = {
        query: query ?? '',
        customData: {
            directSearch: true,
        },
        queriesOptions: {
            take: 3,
        },
    };

    return useQueries({
        queries: [
            {
                queryKey: ['searchAutocomplete', searchQuery],
                queryFn: ({ signal }: QueryFunctionContext<QueryKey, any>) =>
                    Loop54.operations.getSearch(signal, searchQuery),
                enabled: !!query,
                retry: false,
                ...searchOptions,
            },
            {
                queryKey: ['brand', query],
                queryFn: ({ signal }: QueryFunctionContext<QueryKey, any>) =>
                    Shop.operations.getBrand(signal, undefined, query),
                enabled: !!query,
                retry: false,
            },
            {
                queryKey: ['searchAutocompleteSuggestions', searchAutocompleteQuery],
                queryFn: ({ signal }: QueryFunctionContext<QueryKey, any>) =>
                    Loop54.operations.getSearchAutocomplete(signal, searchAutocompleteQuery),
                enabled: !!query,
                retry: false,
                ...searchOptions,
            },
        ],
    });
}
