import Link from 'next/link';
import BagIcon from '~/icons/iconography/bag.svg';
import { usePrice, useTranslations } from '~/services';
import { Loop54Product } from '~/services/loop54/types';
import { ImageOverlay, ProductImage } from '~/shared/components/Image';
import { StyledLinkAnimationText } from '~/shared/styles/Link';
import { useSession } from 'next-auth/react';
import PlaceholderImage from '~/shared/components/NewComponents/PlaceholderImage';
import { Skeleton } from '~/shared/components/SkeletonLoader/styled';
import { closePowerStepModal } from '~/state/usePowerStepState';
import { setItemList, showQuickAddToBasketModal } from '~/state/useQuickAddToBasketState';
import { Splashes } from './components/Splashes';
import {
    BottomCardDetail,
    Brand,
    Card,
    Container,
    Details,
    EnergyLabel,
    Name,
    SkeletonBottom,
    SkeletonButton,
    SkeletonTitle,
    StyledButton,
    StyledPrice,
    StyledStockStatus,
    Thumbnail,
    Title,
    VariantCount,
} from './styled';
import useCanUserAccessFunctionality from '~/shared/utils/mit-fog/canUserAccessFunctionality';

type Props = {
    customerId: string | undefined;
    product: Loop54Product;
    translate: ReturnType<typeof useTranslations>['translate']; // Pass hooks in to avoid to many listeners to react query
    itemListName?: string;
    isWithinModal?: boolean;
    token: string | null;
};

export const ProductCard = ({
    product,
    translate,
    itemListName,
    isWithinModal,
    customerId,
    token,
}: Props) => {
    const { ItemName, ItemName2, SKU } = product;
    const name = `${ItemName ? `${ItemName} ` : ''}${ItemName2 ?? ''}`;

    const myPrice = usePrice(SKU, !!SKU && !!customerId && !!token, customerId, token);

    const { status } = useSession();
    const isUser = useCanUserAccessFunctionality();

    return (
        <Container>
            <Link href={product.ItemUrl} prefetch={false} passHref legacyBehavior>
                <Card
                    multipleElements
                    animation="reverse"
                    onClick={() => {
                        if (isWithinModal) {
                            closePowerStepModal();
                        }
                        setItemList(itemListName ?? '');
                    }}
                >
                    <Thumbnail>
                        {product.ImageUrl ? (
                            <ProductImage
                                src={product.ImageUrl}
                                width={160}
                                height={160}
                                alt={name}
                                style={{ objectFit: 'contain' }}
                            />
                        ) : (
                            <PlaceholderImage />
                        )}

                        <ImageOverlay />

                        {parseInt(product.VariantCount, 10) > 1 ? (
                            <VariantCount>
                                {product.VariantCount} {translate('generic.variants')}
                            </VariantCount>
                        ) : null}

                        {product?.Campaigns_Object ? (
                            <Splashes campaigns={product?.Campaigns_Object} />
                        ) : null}
                    </Thumbnail>

                    <Details>
                        <Title>
                            <Brand>{product.Brand}</Brand>
                            <Name>
                                <StyledLinkAnimationText>{name}</StyledLinkAnimationText>
                            </Name>
                        </Title>

                        <BottomCardDetail>
                            {status === 'unauthenticated' || !!isUser?.shouldDisplayPrices ? (
                                <StyledPrice
                                    product={product}
                                    translate={translate}
                                    myPrice={myPrice.data}
                                    showPriceExVat={!!isUser?.shouldDisplayPricesExVat}
                                    myPriceIsLoading={
                                        !!customerId && !!token ? myPrice.isLoading : false
                                    }
                                    showOriginalPrice={!customerId && !token}
                                />
                            ) : null}
                            <StyledStockStatus
                                onlineStatus={product.StockStatus}
                                warehouseStatus={product?.Stocks_Object}
                                freight={product?.Freight_Object}
                                translate={translate}
                            />
                        </BottomCardDetail>
                    </Details>
                </Card>
            </Link>
            {!product.EnergyClass ||
            !product.EnergyLabelLink ||
            !product.EnergyProductDataSheetLink ? null : (
                <EnergyLabel
                    variant="productCard"
                    energyClass={product.EnergyClass}
                    energyLabelLink={product.EnergyLabelLink}
                    energyProductDataSheetLink={product.EnergyProductDataSheetLink}
                />
            )}

            {status === 'unauthenticated' ? (
                <StyledButton
                    aria-label={translate('generic.buy')}
                    onClick={() => {
                        showQuickAddToBasketModal(SKU, isWithinModal);
                        setItemList(itemListName ?? '');
                    }}
                    customIcon={<BagIcon />}
                >
                    {translate('generic.buy')}
                </StyledButton>
            ) : null}
        </Container>
    );
};

ProductCard.Skeleton = ProductCardSkeleton;

function ProductCardSkeleton() {
    return (
        <Container>
            <Card>
                <Thumbnail>
                    <Skeleton width={130} height={120} />
                    <VariantCount>
                        <Skeleton isFullWidth height={130} />
                    </VariantCount>
                </Thumbnail>
                <Details>
                    <SkeletonTitle>
                        <Skeleton width={80} height={10} />
                        <Skeleton isFullWidth height={14} />
                        <Name>
                            <Skeleton isFullWidth height={15} />
                        </Name>
                    </SkeletonTitle>
                    <SkeletonBottom>
                        <Skeleton width={80} height={10} />
                        <Skeleton width={80} height={10} />
                        <Skeleton width={80} height={30} />
                    </SkeletonBottom>
                </Details>
            </Card>

            <SkeletonButton>
                <Skeleton width={35} height={35} />
            </SkeletonButton>
        </Container>
    );
}
