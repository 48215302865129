import { useQuery } from '@tanstack/react-query';
import { HTTPError } from 'ky';
import { ShopTypes } from '~/lib/data-contract';
import { Prefetch, Shop } from '~/services';
import { hours, seconds } from '~/shared/utils/helpers';

export default function useBrand(brandCategory: string, query: string) {
    return useQuery<ShopTypes.BrandQueryResult, HTTPError | Error>(
        ['brand', { brandCategory, query }],
        ({ signal }) => Shop.operations.getBrand(signal, brandCategory, query),
        {
            staleTime: hours(1),
            cacheTime: seconds(30),
            retry: 1,
        },
    );
}

type PrefetchProps = {
    brandCategory: string;
    query: string;
} & Prefetch;

export async function prefetchBrands({ queryClient, brandCategory, query }: PrefetchProps) {
    return queryClient.fetchQuery(['brand', { brandCategory, query }], ({ signal }) =>
        Shop.operations.getBrand(signal, brandCategory, query),
    );
}
