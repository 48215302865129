import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { ColorOptions } from './ProgressBar';

export const Container = styled(motion.div)({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
});

export const Background = styled(motion.div)<{ color: ColorOptions }>(({ color }) => ({
    width: '100%',
    height: 1,
    background: color,
}));
