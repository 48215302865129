import { pushToDataLayer } from '../utils/pushToDataLayer';
import { GACustomEvent } from '../utils/types';

export function siteSearch(searchQuery: string, numberOfSearchResults: number) {
    pushToDataLayer({
        event: GACustomEvent.SiteSearch,
        search_query: (searchQuery ?? '').toLowerCase(),
        number_of_search_results: numberOfSearchResults,
    });
}
