import { HTTPError } from 'ky';
import { useQuery } from '@tanstack/react-query';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';
import { minutes, seconds } from '~/shared/utils/helpers';

export default function useStockDetails({ itemNo, postalCode, enabled = true }: Props) {
    return useQuery<ShopTypes.ProductStockDetailsResponse, HTTPError | Error>(
        ['stockDetails', { itemNo, postalCode }],
        () => Shop.operations.getStockDetails(itemNo, postalCode),
        {
            enabled: enabled && Boolean(itemNo),
            staleTime: minutes(1),
            cacheTime: seconds(30),
            refetchOnWindowFocus: true,
            retry: 1,
        },
    );
}

interface Props {
    enabled?: boolean;
    itemNo: string | undefined;
    postalCode: string | undefined;
}
