import styled from '@emotion/styled';
import { darken, lighten } from 'color2k';
import { ifProp, switchProp } from 'styled-tools';
import Icon from '~/icons/iconography/arrow/right.svg';
import { ButtonProps } from '~/shared/components/Button';
import { StyledSpinner } from '~/shared/components/LoadingSpinner';

export const StyledResetButton = styled.button(({ theme }) => ({
    alignItems: 'center',
    appearance: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: 0,
    boxSizing: 'border-box',
    color: 'inherit',
    cursor: 'pointer',
    display: 'inline-flex',
    flexShrink: 0,
    letterSpacing: 0,
    lineHeight: 1,
    margin: 0,
    padding: 0,
    textDecoration: 'none',
    textRendering: 'inherit',
    userSelect: 'none',
    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
    WebkitUserSelect: 'none',
    textAlign: 'left',
    height: theme.space[6],
}));

export const StyledButton = styled(StyledResetButton)<ButtonProps>(
    ({ theme, isLoading }) => ({
        border: '1px solid currentColor',
        fontFamily: theme.fonts.body,
        fontSize: theme.fontSizes.mouse,
        height: theme.space[6],
        borderRadius: '2px',
        padding: `1px ${theme.space[2]} 0 `, // 1px is needed for the icon to be visually centered
        transition: '200ms ease',
        transitionProperty: 'background-color, border-color, color',
        overflow: 'hidden',
        position: 'relative',

        [`&:hover ${StyledIcon}`]: {
            transform: 'rotate(-45deg)',
        },

        [`${StyledSpinner}`]: {
            opacity: isLoading ? 1 : 0,
            transform: `translate(-50%, -50%) scale(${isLoading ? 1 : 1.4}) `,
        },
        [`${Wrapper}`]: {
            transform: `scale(${isLoading ? 1.2 : 1})`,
            opacity: isLoading ? 0 : 1,
        },
    }),

    switchProp('variant', {
        fill: ({ theme, color = theme.colors.brandBlue }) => ({
            backgroundColor: color,
            borderColor: color,
            color: color === theme.colors.offWhite ? theme.colors.brandBlue : theme.colors.offWhite,

            '&:active, &:hover': {
                borderColor:
                    color === theme.colors.offWhite ? darken(color, 0.041) : lighten(color, 0.041),
                backgroundColor:
                    color === theme.colors.offWhite ? darken(color, 0.041) : lighten(color, 0.041),
            },
        }),
        stroke: ({ theme, color = theme.colors.brandBlue }) => ({
            borderColor: color,
            color: color,
            backgroundColor: 'transparent',
            [`${StyledSpinner}`]: {
                fill: color,
            },

            '&:active, &:hover': {
                color: color === theme.colors.offWhite ? darken(color, 0.1) : lighten(color, 0.041),
                borderColor: 'currentColor',
            },
        }),
        link: ({ theme, color = theme.colors.brandBlue }) => ({
            backgroundColor: 'transparent',
            border: 'none',
            color,
            padding: 0,
            margin: 0,
        }),
    }),

    ifProp({ disabled: true }, ({ theme, variant }) => ({
        '&, &:active, &:hover': {
            backgroundColor: variant === 'fill' ? theme.colors.grey60 : theme.colors.offWhite,
            borderColor: theme.colors.grey60,
            color: variant === 'fill' ? theme.colors.offWhite : theme.colors.grey60,
            cursor: 'default',
        },
        [`&:hover ${StyledIcon}`]: {
            transform: 'none',
        },
    })),
    ifProp({ fullWidth: true }, () => ({
        width: '100%',
    })),
);

export const StyledLinkButton = styled(StyledButton.withComponent('a'))();

export const IconWrapper = styled.span(({ theme }) => ({
    flex: 0,

    svg: {
        transition: '200ms ease',
        transitionProperty: 'transform, color, fill, opacity',
        justifyContent: 'center',
        marginTop: '1px',
        width: 18,
        height: 18,

        path: {
            fill: theme.colors.white,
        },
    },
}));

export const StyledIcon = styled(Icon)();

export const ButtonText = styled.span(({ theme }) => ({
    alignItems: 'center',
    display: 'inline-flex',
    flexFlow: 'row',
    transition: 'opacity 200ms ease, transform 200ms ease ',
    transformOrigin: 'center',
    lineHeight: theme.lineHeights.squirrel,
    fontSize: theme.fontSizes.mouse,
}));

export const Wrapper = styled.div(({ theme }) => ({
    transition: 'opacity 200ms ease, transform 200ms ease ',
    display: 'inline-flex',
    alignItems: 'center',
    gap: '35px',
    lineHeight: theme.lineHeights.squirrel,
    justifyContent: 'space-between',
    width: '100%',
}));
