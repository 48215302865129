import shop from '~/lib/api/shop';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';

export default function getBrand(
    signal: AbortSignal | undefined,
    category?: string,
    searchPhrase?: string,
) {
    const url = Shop.utils.getUrl(`/products/brands`);

    if (category) {
        url.searchParams.set('category', category || '');
    }

    if (searchPhrase) {
        url.searchParams.set('searchPhrase', searchPhrase || '');
    }

    return shop
        .get(url, {
            signal,
        })
        .json<ShopTypes.BrandQueryResult>();
}
