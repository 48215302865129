import { HTTPError } from 'ky';
import { useQuery } from '@tanstack/react-query';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';
import { hours, seconds } from '~/shared/utils/helpers';
import { Validation } from '~/shared/utils/validation';

export default function useFreight({ itemNo, postalCode }: Props) {
    const postalCodeString = postalCode?.toString() ?? '';
    const postalCodeIsValid = !!postalCodeString && Validation.PostalCode.test(postalCodeString);

    return useQuery<ShopTypes.DeliveryPriceDetails, HTTPError | Error>(
        ['freight', { itemNo, postalCode }],
        () => Shop.operations.getFreight(itemNo, postalCode),
        {
            enabled: !!itemNo && postalCodeIsValid,
            staleTime: hours(1),
            cacheTime: seconds(30),
            retry: 1,
        },
    );
}

interface Props {
    itemNo: string | undefined;
    postalCode: string | undefined;
}
