import { addSecondsToDate } from '../../helpers';
import { Cookie } from '../cookie';
import { useCookie } from '../utils';

export function useContextGroup() {
    return useCookie({
        stateful: false,
        key: Cookie.contextGroup,
        options: {
            expires: addSecondsToDate(new Date(), 90),
            path: '/',
        },
    });
}
