import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const Grid = styled.div<{ isFullWidth: boolean }>(({ theme, isFullWidth }) => ({
    '--columns': 2,
    '--gap': theme.space[2],
    marginBottom: theme.space[6],
    display: 'grid',
    gridTemplateColumns: 'repeat(var(--columns), minmax(0, 1fr))',
    gap: 'var(--gap)',

    [breakpoints.md]: {
        '--columns': isFullWidth ? 4 : 3,
        marginBottom: theme.space[9],
    },
    [breakpoints.xl]: {
        '--columns': isFullWidth ? 5 : 4,
    },
}));
