import { create } from 'zustand';
import { hideQuickAddToBasketModal } from './useQuickAddToBasketState';
import { wait } from '~/shared/utils/promises';

type ModalState = {
    isOpenPowerStep: boolean;
    powerStepId: string | null;
    warehouseId: number | null;
};

export const usePowerStepState = create<ModalState>(() => ({
    powerStepId: null,
    isOpenPowerStep: false,
    warehouseId: null,
}));

export function setPowerStepId(id: string) {
    usePowerStepState.setState({
        powerStepId: id,
    });
}
export async function closePowerStepModal() {
    usePowerStepState.setState({
        isOpenPowerStep: false,
        powerStepId: null,
        warehouseId: null,
    });
    await wait(800);
}

export function openPowerStep(itemNo: string, warehouseId?: number) {
    usePowerStepState.setState({
        isOpenPowerStep: true,
        powerStepId: itemNo,
        warehouseId,
    });
}

export async function openPowerStepModal(itemNo: string, warehouseId?: number) {
    await hideQuickAddToBasketModal();

    openPowerStep(itemNo, warehouseId);
}
