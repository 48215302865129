import { Session } from 'next-auth';
import { useContextGroup } from '~/shared/utils/cookie';

// These rewrite rules should also be added to the next.config.ts file
export function useRewritePaths(asPath: string, session: Session | null) {
    const [contextGroup] = useContextGroup();

    if (session?.accessToken && asPath === '/') {
        return '/mit-fog/forside';
    }

    if (asPath === '/' && contextGroup === 'LouisPoulsen') {
        return '/HomeLouisPoulsen';
    }

    return asPath;
}
