import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { SkeletonLoaderProps } from './SkeletonLoader';

const shimmer = keyframes`
    100% {
      transform: translateX(100%);
    }`;

export const Skeleton = styled.span<SkeletonLoaderProps>(
    ({ height, theme }) => ({
        height: `${height}px`,
        maxWidth: '100%',
        backgroundColor: theme.colors.grey30,
        overflow: 'hidden',
        position: 'relative',
        display: 'block',

        ['&::after']: {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            transform: 'translateX(-100%)',
            background:
                'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 40%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0.2) 60%, rgba(255,255,255,0) 100%)',
            animation: `${shimmer} 1s infinite`,
            content: '""',
        },
    }),

    ifProp('isLight', ({ theme }) => ({
        backgroundColor: theme.colors.offWhite,

        ['&::after']: {
            background: `linear-gradient(90deg, ${theme.colors.offWhite} 0%, rgba(0,0,0,0.02) 40%, rgba(0,0,0,0.05) 50%, rgba(0,0,0,0.02) 60%, ${theme.colors.offWhite} 100%)`,
        },
    })),

    ifProp('isFullWidth', {
        width: '100%',
    }),

    ifProp('width', ({ width }: { width: number }) => ({
        width: `${width}px`,
    })),
);
