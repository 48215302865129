import dawa from '~/lib/api/dawa';
import { DawaTypes } from '~/lib/data-contract';
import { Dawa, AutocompleteList } from '~/services';

export default function getAutocomplete(query: DawaTypes.AutocompleteListParams) {
    if (!query || !query?.q) {
        return Promise.reject(new Error(Dawa.errors.invalidQuery.message));
    }

    const url = Dawa.utils.getUrl('/autocomplete');

    // Any params will be added to the url
    for (const [key, value] of Object.entries(query)) {
        url.searchParams.append(key, value);
    }

    return dawa.get(url).json<AutocompleteList>();
}
