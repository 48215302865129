const type = {
    referenceNotFound: {
        name: 'ReferenceNotFound',
        message: 'Reference not found',
    },
    basketNotFound: {
        name: 'BasketNotFound',
        message: (reference: string | undefined) => 'Basket not found with reference ' + reference,
    },
    invalidProductItemNumber: {
        name: 'InvalidProductItemNumber',
        message: 'Invalid product item number',
    },
    invalidPriceItemNumbers: {
        name: 'InvalidPriceItemNumbers',
        message: 'Invalid price item numbers',
    },
    invalidItemNoOrPostalCode: {
        name: 'InvalidItemNoOrPostalCode',
        message: 'Invalid item number or postal code',
    },
    emailNotProvided: {
        name: 'EmailNotProvided',
        message: 'Missing email',
    },
    accountInfoNotFound: {
        name: 'AccountInfoNotFound',
        message: 'Account info not found',
    },
    orderHistoric: {
        name: 'orderHistoric',
        message: 'Missing order historic',
    },
    orderDetails: {
        name: 'orderDetails',
        message: 'Missing order details',
    },
    exportPriceList: {
        name: 'exportPriceList',
        message: 'Missing customerId or email',
    },
    accountStatement: {
        name: 'accountStatement',
        message: 'Missing account statement',
    },
};

export default type;
