import { NavigationFilterWrapper } from './styled';
import { NavigationFilterItem } from './NavigationFilterItem';
import { NavigationFilterProps } from '../Desktop/NavigationFilter';

const NavigationFilter = ({
    navigationTree,
    navigationName,
    facetDisplayName,
    onSelectedFilterHandler,
}: NavigationFilterProps) => {
    return navigationTree ? (
        <NavigationFilterWrapper>
            {navigationTree.map((item, index) => (
                <NavigationFilterItem
                    key={`SideNavigationItem-${item.id}-${index}`}
                    facetName={navigationName}
                    facetDisplayName={facetDisplayName}
                    onFilterClick={onSelectedFilterHandler}
                    {...item}
                />
            ))}
        </NavigationFilterWrapper>
    ) : null;
};

export default NavigationFilter;
