import { ShopTypes } from '~/lib/data-contract';
import { GAEcommerceItem } from './types';

export function getGAItem(
    quantity: number,
    product?: ShopTypes.ProductQueryProduct,
    priceData?: ShopTypes.SalesUnitPrice,
    activeCampaigns?: ShopTypes.ActiveCampaignDetails[],
    itemListName?: string,
) {
    const categories = product?.categoryBreadcrumb?.split('|');

    if (product) {
        return {
            item_id: product?.item?.itemNumber,
            item_name: `${product?.item?.name ? `${product?.item?.name} ` : ''}${
                product?.item?.name2 ?? ''
            }`,
            index: 0,
            item_brand: product?.brand,
            item_category: categories?.[0] ? categories[0] : undefined,
            item_category2: categories?.[1] ? categories[1] : undefined,
            item_category3: categories?.[2] ? categories[2] : undefined,
            item_category4: categories?.[3] ? categories[3] : undefined,
            item_list_name: itemListName ? itemListName : undefined,
            item_variant:
                product?.item?.variantType || product?.item?.variantName
                    ? `${product?.item?.variantType ? `${product?.item?.variantType}: ` : ''}${
                          product?.item?.variantName ?? ''
                      }`
                    : undefined,
            price: priceData?.price?.priceInclVat,
            quantity: quantity,
            campaign_id:
                activeCampaigns && activeCampaigns?.length > 0
                    ? activeCampaigns?.[0].entityId
                    : undefined,
            currency: 'DKK',
        } as GAEcommerceItem;
    }
}
