import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { ShopTypes } from '~/lib/data-contract';

export const StockStatus = styled.span<{
    status: ShopTypes.StockStatus;
    isPhysicalStore?: boolean;
}>(
    ({ theme }) => ({
        display: 'inline-block',
        borderRadius: '50%',
        width: '6px',
        height: '6px',
        marginRight: theme.space[1],
        backgroundColor: theme.colors.grey50,
    }),
    switchProp('status', {
        [ShopTypes.StockStatus.Unavailable]: ({ theme }) => ({
            backgroundColor: theme.colors.alert,
        }),
        [ShopTypes.StockStatus.OutOfStock]: ({ theme }) => ({
            backgroundColor: theme.colors.alert,
        }),
        [ShopTypes.StockStatus.LowStock]: ({ theme, isPhysicalStore }) => ({
            backgroundColor: isPhysicalStore ? theme.colors.warning : theme.colors.success,
        }),
        [ShopTypes.StockStatus.InStock]: ({ theme }) => ({
            backgroundColor: theme.colors.success,
        }),
    }),
);
