import Cookies, { CookieAttributes } from 'js-cookie';
import { useEffect, useState } from 'react';

export function useCookie({ key, options, initialValue, stateful = true }: Props) {
    const [stateValue, setStateValue] = useState(Cookies.get(key) || initialValue);

    function setValues(value: string) {
        setStateValue(value);
        Cookies.set(key, value, options);
    }

    function removeValues() {
        setStateValue(initialValue || undefined);
        Cookies.remove(key, options);
    }

    useEffect(() => {
        if (!navigator.cookieEnabled || !('cookieStore' in globalThis)) {
            // Browsers that don't support the new cookieStore API will not be able to watch for cookie changes
            return;
        }

        function cookieWatcher(event: any) {
            const isChanged = (event.changed as Cookie[])?.some(
                (cookie) => cookie.name === key && cookie.value !== stateValue,
            );

            if (isChanged) {
                const changedCookie = (event.changed as Cookie[]).find(
                    (cookie) => cookie.name === key,
                );

                setStateValue(changedCookie?.value);
            }

            const isDeleted = (event.deleted as Cookie[])?.some(
                (cookie) => cookie.name === key && cookie.value !== stateValue,
            );

            if (isDeleted) {
                setStateValue(initialValue || undefined);
            }
        }

        if (stateful) {
            cookieStore.addEventListener('change', cookieWatcher);

            return () => cookieStore.removeEventListener('change', cookieWatcher);
        }
    }, [stateValue, key, initialValue, stateful]);

    return [stateValue, setValues, removeValues] as const;
}

declare const cookieStore: any;

interface Props {
    key: string;
    initialValue?: string;
    options?: CookieAttributes;
    stateful?: boolean;
}

interface Cookie {
    name: string;
    value: string;
    expires?: number;
    domain?: undefined;
    path?: undefined;
    secure?: boolean;
    sameSite: 'strict' | 'lax' | 'none';
}
