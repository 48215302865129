import shop from '~/lib/api/shop';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';

export default async function setBasketVoucher(basketReference: string | undefined, couponCode: string, email?: string) {
    if (!basketReference) {
        return Promise.reject(new Error(Shop.errors.referenceNotFound.message));
    }

    const addEmailQuery = email ? `?email=${encodeURIComponent(email)}` : ''
    const endpoint = `/baskets/${basketReference}/voucher/${couponCode}${addEmailQuery}`
    const voucherReturn = await shop
        .post(Shop.utils.getUrl(endpoint), {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .json<ShopTypes.BasketResponse>();
    
    return voucherReturn;

}
