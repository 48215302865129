import shop from '~/lib/api/shop';
import { Shop } from '~/services';

export default function deleteBasket(reference: string | undefined) {
    if (!reference) {
        return Promise.reject(new Error(Shop.errors.referenceNotFound.message));
    }

    return shop.delete(Shop.utils.getUrl(`/baskets/${reference}`)).json<string>();
}
