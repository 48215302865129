import { Loop54 } from '~/services';
import useBasketRecommendations from '~/services/loop54/hooks/useBasketRecommendations';

export default function useBasketRecommendationsResults(productIds: string[]) {
    return useBasketRecommendations(
        {
            entities: productIds.map((id) => ({
                type: 'Product',
                id,
            })),
            resultsOptions: {
                sortBy: [{ type: 'popularity' }],
                take: 10,
            },
        },
        (data) => ({
            ...data,
            results: Loop54.utils.reduceLoop54Product(data.results),
        }),
    );
}
