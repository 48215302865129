import { HTTPError } from 'ky';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';
import { useSession } from 'next-auth/react';

export default function useSetCustomUserProfile() {
    const { data } = useSession();
    const token = data?.accessToken ?? null;
    const queryClient = useQueryClient();

    return useMutation<
        ShopTypes.UserInformationDto,
        HTTPError | Error,
        Omit<Parameters<typeof Shop.operations.setCustomUserProfile>[0], 'token'>
    >(
        ({ name, phoneNumber }) =>
            Shop.operations.setCustomUserProfile({ name, phoneNumber, token }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['userProfile', { token }]);
            },
        },
    );
}
