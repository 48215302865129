import styled from '@emotion/styled';
import SpinnerIcon from '~/icons/loader.svg';
import { SpinnerProp } from './LoadingSpinner';

export const StyledSpinner = styled(SpinnerIcon)<SpinnerProp>(({ theme, size }) => ({
    fill: theme.colors.offWhite,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    transition: 'opacity 200ms ease, transform 200ms ease',
    height: size,
    width: size,
}));
