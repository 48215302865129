import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { Link } from '~/shared/components/Link';
import { breakpoints } from '~/theme';
import { EnergyLabelVariant } from './types';

export const Container = styled.span(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
    width: theme.space[9],
    zIndex: theme.zIndices[1],
}));

export const IconLink = styled.a(({ theme }) => ({
    marginBottom: theme.space[1],
}));

export const TextLink = styled(Link)(({ theme }) => ({
    color: theme.colors.nightBlack,
    fontSize: theme.fontSizes.mouse,
}));

export const Icon = styled.svg<{ variant: EnergyLabelVariant }>(
    {
        display: 'block',
    },
    switchProp('variant', {
        pdp: {
            height: 38,
        },
        productCard: {
            height: 20,

            [breakpoints.md]: {
                height: 24,
            },
        },
    }),
);
