import { Global, css } from '@emotion/react';
import emotionReset from 'emotion-reset';
import { breakpoints, theme } from '~/theme';

export const fonts = [
    '/assets/fonts/Dinpro-Bold.woff',
    '/assets/fonts/Dinpro-Bold2.woff2',
    '/assets/fonts/Dinpro-Medium.woff',
    '/assets/fonts/Dinpro-Medium2.woff2',
    '/assets/fonts/Dinpro-Regular.woff',
    '/assets/fonts/Dinpro-Regular2.woff2',
];

export const GlobalStyles = (
    <Global
        styles={css`
            ${emotionReset}

            html {
                box-sizing: border-box;
                overflow-y: scroll;
                height: -webkit-fill-available;
                scroll-behavior: smooth;
                scroll-padding-top: ${theme.navigationOffsetTop.mobile}px;

                ${breakpoints.md} {
                    scroll-padding-top: ${theme.navigationOffsetTop.desktop}px;
                }
            }

            *,
            *:before,
            *:after {
                box-sizing: inherit;
            }

            @font-face {
                font-family: 'Dinpro';
                src:
                    url(${fonts[0]}) format('woff'),
                    url(${fonts[1]}) format('woff2');
                font-weight: bold;
                font-style: normal;
                font-display: swap;
            }

            @font-face {
                font-family: 'Dinpro';
                src:
                    url(${fonts[2]}) format('woff'),
                    url(${fonts[3]}) format('woff2');
                font-weight: 500;
                font-style: normal;
                font-display: swap;
            }

            @font-face {
                font-family: 'Dinpro';
                src:
                    url(${fonts[4]}) format('woff'),
                    url(${fonts[5]}) format('woff2');
                font-weight: normal;
                font-style: normal;
                font-display: swap;
            }

            body {
                height: 100vh;
                height: -webkit-fill-available;
                font-family: ${theme.fonts.body};
                background-color: ${theme.colors.grey10};
                margin: 0;
                color: ${theme.colors.nightBlack};
            }

            #__next {
                display: flex;
                min-height: 100%;
                flex-direction: column;
            }

            /* For React Query DevTools */
            .fetch-debugger {
                display: none !important;
                margin: 0 !important;
                transform: scale(0.75) !important;
                transform-origin: left bottom !important;
            }
            ${breakpoints.sm} {
                .fetch-debugger {
                    display: block !important;
                }
            }

            /* In order to make sure that the SmartSupp Chat Bot is underneath or Modals. */
            #smartsupp-widget-container {
                div {
                    z-index: 400 !important;
                }
            }
        `}
    />
);
