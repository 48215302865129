import Script from 'next/script';

type ProdProps = {
    productItemNumber?: number | string;
    productItemTitle?: string;
    productItemEan?: string;
};

export function VideolyScript({ productItemNumber, productItemTitle, productItemEan }: ProdProps) {
    return (
        <>
            <div>
                <div>
                    <div id="videoly-videobox-placeholder"></div>
                    <div id="videoly-product-id" style={{ display: 'none' }}>
                        {productItemNumber}
                    </div>
                    <div id="videoly-product-title" style={{ display: 'none' }}>
                        {productItemTitle}
                    </div>
                    <div id="videoly-product-sku" style={{ display: 'none' }}>
                        {productItemNumber}
                    </div>
                    <div id="videoly-product-ean" style={{ display: 'none' }}>
                        {productItemEan}
                    </div>
                </div>
            </div>

            <Script
                id="videoly"
                type="text/javascript"
                strategy="afterInteractive"
                src="//api.videoly.co/1/quchbox/0/726/quch.js"
            />
        </>
    );
}
