import Script from 'next/script';
import { useConfig } from '~/shared/hooks';

const isDevelopment = process.env.NODE_ENV === 'development';

export function ActiveCampaignScript() {
    const { ACTIVE_CAMPAIGN_ACCOUNT } = useConfig();

    if (isDevelopment) {
        return null;
    }

    return (
        <Script
            id="active-campaign"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
                __html: `
                    (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function()
                    {(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)}
                    ;e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
                    vgo('setAccount', '${ACTIVE_CAMPAIGN_ACCOUNT}');
                    vgo('setTrackByDefault', true);
                    vgo('process');
                `,
            }}
        />
    );
}
