import { UmbracoTypes } from '~/lib/data-contract';
import { Prefetch } from '~/services';
import { getFilterQuery } from '~/shared/utils/helpers';
import { FilterDefaultsResponse } from '../types';
import {
    buildPreFetcherForEntities,
    useEntitiesOrEntitiesByAttribute,
    type EntitiesOrEntitiesByAttributeRequest,
    type EntitiesOrEntitiesByAttributeResponse,
} from './useEntitiesOrEntitiesByAttributes';

const queryKey = 'filterEntities';

export default function useFilterEntities<SelectType = EntitiesOrEntitiesByAttributeResponse>(
    params: EntitiesOrEntitiesByAttributeRequest | undefined,
    select?: (data: EntitiesOrEntitiesByAttributeResponse) => SelectType,
) {
    return useEntitiesOrEntitiesByAttribute(queryKey, params, select);
}

type PrefetchEntitiesOrEntitiesByAttribute = Prefetch & {
    breadcrumb?: UmbracoTypes.IBreadcrumb;
    displayCategoryPath?: boolean;
    filterAdmin?: UmbracoTypes.FilterAdmin['filterAdmin'];
    filterDefaults?: FilterDefaultsResponse;
    pageTitle: string | null | undefined;
    productsPerPage?: number;
};

export function prefetchFilterEntities({
    breadcrumb,
    displayCategoryPath,
    filterAdmin,
    filterDefaults,
    pageTitle,
    productsPerPage,
    queryClient,
}: PrefetchEntitiesOrEntitiesByAttribute) {
    const params: EntitiesOrEntitiesByAttributeRequest = getFilterQuery({
        breadcrumb,
        displayCategoryPath,
        filterAdmin,
        filterDefaults,
        filtersFromUrl: {},
        pageNumber: 1,
        pageTitle,
        productsPerPage,
    });

    return buildPreFetcherForEntities({ queryKey, params, queryClient });
}
