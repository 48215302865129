import { useQuery } from '@tanstack/react-query';
import { Shop, useBasket, useBasketReference } from '~/services';

export default function useDeliveryDates(deliveryId: number) {
    const { data: reference } = useBasketReference();
    const { data: updated } = useBasket((basket) => basket.updated.toString());

    return useQuery<string[], Error, Date[]>(
        ['deliveryDates', { reference, deliveryId, updated }],
        () => Shop.operations.getDeliveryDates(reference, deliveryId),
        {
            enabled: !!reference,
            select: (data) => data.map((dateString) => new Date(dateString)),
        },
    );
}
