import shop from '~/lib/api/shop';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';

type Args = {
    signal: AbortSignal | undefined;
    customerId: string | null;
    token: string | null;
};

export default function getUserProfile({ signal, customerId, token }: Args) {
    const url = Shop.utils.getUrl('/customers/users/me');

    if (customerId) {
        url.searchParams.append('customerId', customerId);
    }
    return shop
        .get(url, {
            signal,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .json<ShopTypes.UserCustomerResponse>();
}
