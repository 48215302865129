import { HTTPError } from 'ky';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { ShopTypes, TranslationsTypes } from '~/lib/data-contract';
import { Prefetch, Umbraco } from '~/services';
import { useCurrency } from '~/shared/hooks';
import { hours, minutes } from '~/shared/utils/helpers';
import {
    formatDateAndTime,
    formatDateLongDayAndLongMonth,
} from '~/shared/utils/helpers/dateHelper';
import { sharedQueryClient } from '~/shared/utils/request';

export default function useTranslations() {
    const { locale } = useRouter();
    const formatCurrency = useCurrency();

    const { data: translations, isLoading } = useQuery<Translations, HTTPError | Error>(
        ['translations', locale],
        ({ signal }) => Umbraco.operations.getTranslations(signal, locale),
        {
            retry: false,
            staleTime: hours(1),
            cacheTime: minutes(1),
            refetchOnWindowFocus: true,
            keepPreviousData: true,
            select: (data) => Umbraco.utils.namespacedKeys(data),
        },
    );

    const translate = (
        key: TranslationsTypes.TranslationKey | ShopTypes.Translation['name'],
        values?: ShopTypes.TranslationValue[],
        ignoreMissing?: boolean,
    ) => {
        let translation = key ? translations?.[key] : undefined;

        if (typeof translation === 'undefined' || translation === null) {
            if (!ignoreMissing) {
                console.warn(`Missing translation: ${key}`);
            }
            return '';
        }

        if (!translation) {
            return '';
        }

        if (values) {
            for (const i in values) {
                switch (values[i].type) {
                    case ShopTypes.TranslationValueType.Currency:
                        translation = translation.replace(
                            `[${values[i].key!}]`,
                            formatCurrency(values[i].value),
                        );
                        break;

                    case ShopTypes.TranslationValueType.Date:
                        translation = values[i].value
                            ? translation.replace(
                                  `[${values[i].key!}]`,
                                  formatDateLongDayAndLongMonth(values[i].value),
                              )
                            : translation;
                        break;

                    case ShopTypes.TranslationValueType.DateTime:
                        translation = values[i].value
                            ? translation.replace(
                                  `[${values[i].key!}]`,
                                  formatDateAndTime(values[i].value),
                              )
                            : translation;
                        break;

                    default:
                        translation = translation.replace(`[${values[i].key!}]`, values[i].value);
                        break;
                }
            }
        }

        return translation as string | NonNullable<ShopTypes.Translation['name']>;
    };

    return { translate, isLoading };
}

export async function prefetchTranslations({ locale, queryClient }: PrefetchProps) {
    await sharedQueryClient.fetchQuery(['translations', locale], ({ signal }) =>
        Umbraco.operations.getTranslations(signal, locale),
    );

    return queryClient.setQueryData(
        ['translations', locale],
        () => sharedQueryClient.getQueryData(['translations', locale]),
        {
            updatedAt: sharedQueryClient.getQueryState(['translations', locale])?.dataUpdatedAt,
        },
    );
}

export type Translate = ReturnType<typeof useTranslations>['translate'];

type Translations = {
    [key in TranslationsTypes.TranslationKey | string]: string;
};

interface PrefetchProps extends Prefetch {
    locale?: string;
}
