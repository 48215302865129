export enum GAEcommerceEvent {
    AddPaymentInfo = 'add_payment_info',
    AddShippingInfo = 'add_shipping_info',
    AddToCart = 'add_to_cart',
    BeginCheckout = 'begin_checkout',
    Purchase = 'purchase',
    RemoveFromCart = 'remove_from_cart',
    ViewCart = 'view_cart',
    ViewItem = 'view_item',
}

export interface GAEvent {
    items?: GAEcommerceItem[];
    currency?: string;
    value?: number;
    shipping_tier?: string;
    payment_type?: string;
    tax?: number;
    shipping_cost?: number;
    discount?: number;
    transaction_id?: string;
    // coupon?: string;
}

export interface GAEcommerceItem {
    index: number;
    item_id?: string;
    item_name?: string;
    item_brand?: string;
    item_category?: string;
    item_category_2?: string;
    item_category_3?: string;
    item_variant?: string;
    price?: number;
    quantity?: number;
    campaign_id?: number;
    currency?: string;
}

export enum GACustomEvent {
    SiteSearch = 'site_search',
    Sorting = 'sorting',
    Filters = 'filters',
    Menu = 'menu',
    NewsletterSignup = 'newsletter_signup',
}

export enum GAErrorEvent {
    Price = 'error_price',
}
