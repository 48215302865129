import { useQuery } from '@tanstack/react-query';
import { HTTPError } from 'ky';
import { useSession } from 'next-auth/react';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';

export default function useUserCustomers() {
    const { data } = useSession();
    const token = data?.accessToken ?? null;

    return useQuery<ShopTypes.SimpleCustomerListResponse, HTTPError | Error>(
        ['useUserCustomers', { token }],
        ({ signal }) => Shop.operations.getUserCustomers({ signal, token }),
        {
            enabled: Boolean(data?.accessToken),
        },
    );
}
