import { getProductFilter } from '~/features/ProductList/utils';
import { Prefetch } from '~/services';
import { Loop54ProductAttributes } from '../types';
import {
    buildPreFetcherForEntities,
    useEntitiesOrEntitiesByAttribute,
    type EntitiesOrEntitiesByAttributeRequest,
    type EntitiesOrEntitiesByAttributeResponse,
} from './useEntitiesOrEntitiesByAttributes';

const queryKey = 'popularEntities';

export default function usePopularEntities<SelectType = EntitiesOrEntitiesByAttributeResponse>(
    params: EntitiesOrEntitiesByAttributeRequest | undefined,
    select?: (data: EntitiesOrEntitiesByAttributeResponse) => SelectType
) {
    return useEntitiesOrEntitiesByAttribute(queryKey, params, select);
}
type PrefetchBrandEntities = {
    brand: string;
} & Prefetch;

export function prefetchBrandEntities({ queryClient, brand }: PrefetchBrandEntities) {
    const params: EntitiesOrEntitiesByAttributeRequest = {
        resultsOptions: {
            filter: {
                and: [
                    getProductFilter(),
                    {
                        type: 'attribute',
                        attributeName: Loop54ProductAttributes.InStock,
                        value: true,
                    },
                    {
                        type: 'attribute',
                        attributeName: Loop54ProductAttributes.Brand,
                        value: brand,
                    },
                ],
            },
            sortBy: [{ type: 'popularity' }],
            take: 10,
        },
    };

    return buildPreFetcherForEntities({ queryKey, params, queryClient });
}
