import { StyledText, StyledTextProps } from './styled';

export type TextVariants =
    | 'display1'
    | 'display2'
    | 'display3'
    | 'display4'
    | 'display5'
    | 'display6'
    | 'display6Discount'
    | 'paragraph'
    | 'caption'
    | 'uiBtn'
    | 'uiInputField'
    | 'uiLinks'
    | 'uiSplashes';

export type TextProps = StyledTextProps & {
    as?: React.ElementType<HTMLElement>;
};

/**
 * Override rendered markup with `as` attribute. For instance:
 *
 * ```tsx
 * <Text as="span">An span in markup.</Text>
 * ```
 */

export const Text = StyledText;
