import { pushToDataLayer } from '../utils/pushToDataLayer';
import { GAEcommerceEvent, GAEcommerceItem } from '../utils/types';

export function addToCart(value: number, items: GAEcommerceItem[]) {
    if (items.length) {
        pushToDataLayer({ ecommerce: null });
        pushToDataLayer({
            event: GAEcommerceEvent.AddToCart,
            ecommerce: {
                currency: 'DKK',
                value,
                items,
            },
        });
    }
}
