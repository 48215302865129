import { RefObject, useCallback } from 'react';
import { useMedia } from 'react-use';
import { queries, theme } from '~/theme';

export default function useScrollTo(ref: RefObject<HTMLDivElement | undefined>) {
    const isDesktop = useMedia(queries.md, false);
    const offset = isDesktop ? theme.navigationOffsetTop.desktop : theme.navigationOffsetTop.mobile;
    return useCallback(() => {
        if (!ref.current) return;
        window.scrollTo({ top: ref.current.offsetTop - offset, behavior: 'smooth' });
    }, [ref, offset]);
}
