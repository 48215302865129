import { HTTPError } from 'ky';
import { useState } from 'react';

interface useResponseHandlerProps<TError, TSuccess> {
    statusCodes: number[];
    onError?: (error: TError | (HTTPError | Error)) => void;
    onSuccess?: (data: TSuccess) => void;
}

export const useResponseHandler = <TError, TSuccess = object>({
    statusCodes,
    onError,
    onSuccess,
}: useResponseHandlerProps<TError, TSuccess>) => {
    const [error, setError] = useState<TError>();

    const clearError = () => {
        setError(undefined);
    };

    const responseHandler = {
        onError: async (error: HTTPError | Error) => {
            if (error instanceof HTTPError && statusCodes.includes(error.response?.status)) {
                setError(await error.response.json());
            }

            onError?.(error);
        },

        onSuccess: (data: TSuccess) => {
            setError(undefined);
            onSuccess?.(data);
        },
    };

    return [error, clearError, responseHandler] as const;
};
