import { ShopTypes } from '~/lib/data-contract';

export function getShippingTiers(basket: ShopTypes.BasketContextResponse[]) {
    return basket
        .flatMap(
            (context) =>
                context.freights?.flatMap(
                    (freight) =>
                        freight.freightTypeName +
                        (freight.freightProvider ? ' ' + freight.freightProvider : ''),
                ),
        )
        .join(' ');
}
