import shop from '~/lib/api/shop';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';
import { minutes } from '~/shared/utils/helpers';

type Args = {
    signal: AbortSignal | undefined;
    customerId: string | null;
    token: string | null;
};

export default function getAccountStatementsignal({ signal, customerId, token }: Args) {
    const url = Shop.utils.getUrl(`/customers/${customerId}/AccountStatement`);

    return shop
        .get(url, {
            signal,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            timeout: minutes(1),
        })
        .json<ShopTypes.IAccountStatement[]>();
}
