import styled from '@emotion/styled';
import { Buttons } from '~/features/ProductList/components/PriceRange/styles';
import { Button } from '~/shared/components/Button';
import { breakpoints, ThemeType } from '~/theme';

const FilterListButtons = (theme: ThemeType, leftMargin: boolean) => ({
    button: {
        height: theme.lineHeights.wolf,
        margin: `${theme.space[1]} ${theme.space[1]} ${theme.space[1]} 0`,
        marginLeft: leftMargin ? theme.space[2] : 0,
        padding: '8px',

        svg: {
            marginRight: theme.space[1],
        },
    },

    'button, div, span, li': {
        display: 'inline-flex',
        width: 'unset',
    },
});

export const FacetSelectionList = styled.ul<{ marginTop?: number }>(({ theme }) => ({
    flex: '1',
    overflowY: 'scroll',
    width: '100%',

    '> li > button': {
        width: '100%',
    },

    'li:last-of-type': {
        button: {
            borderBottom: `1px solid ${theme.colors.grey40}`,
        },
    },
}));

export const FilterList = styled.ul(({ theme }) => ({
    border: 'none',
    backgroundColor: theme.colors.nightBlack,
    width: '100%',
    paddingBottom: theme.space[2],

    ...FilterListButtons(theme, true),
}));

export const HeaderButton = styled(Button)(({ theme, color }) => ({
    border: 'none',
    height: '60px',
    width: '100%',
    borderRadius: 0,
    boxShadow: 'none',

    '&:active, &:hover': {
        borderColor: color,
        backgroundColor: color,
    },

    svg: {
        height: '16px',
        marginRight: theme.space[1],
        width: '16px',
    },
}));

export const MobileFiltersToggleButton = styled(Button)(({ theme }) => ({
    marginBottom: theme.space[2],

    [breakpoints.sm]: {
        display: 'none',
    },
}));

export const MobileFiltersWrapper = styled.div({
    alignItems: 'start',
    display: 'flex',
    flex: 1,
    flexFlow: 'column',
    height: '100vh',
    justifyContent: 'start',
    overflow: 'hidden',
});

export const MobileFilterButton = styled(Button)(({ theme }) => ({
    borderBottom: 0,
    borderLeft: 0,
    borderRight: 0,
    borderColor: theme.colors.grey40,
    borderRadius: 0,

    '&:hover': {
        borderColor: theme.colors.grey40,
        backgroundColor: theme.colors.grey10,
    },

    span: {
        width: 'unset',
    },
}));

export const MobileFiltersFooter = styled.div(({ theme }) => ({
    alignItems: 'center',
    backgroundColor: theme.colors.primary,
    display: 'flex',
    flexFlow: 'row nowrap',
    height: '85px',
    justifyContent: 'space-evenly',
    width: '100%',

    button: {
        width: '40%',
    },
}));

export const MultipleSelectorWrapper = styled.div({
    flex: 1,
    overflowY: 'scroll',
    width: '100%',

    '> div': {
        position: 'unset',
    },

    '> div > ul': {
        position: 'unset',
        width: '100%',

        '> li': {
            width: '100%',
        },
    },
});

export const PriceRangeWrapper = styled.div({
    flex: 1,
    width: '100%',

    [`${Buttons}`]: { display: 'none' },
});
