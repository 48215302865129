import { ReactChild } from 'react';
import { useTranslations } from '~/services';
import { Container, Icon, IconLink, TextLink } from './styled';
import { EnergyLabelVariant } from './types';

interface Props {
    className?: string;
    energyClass: string;
    energyLabelLink: string;
    energyProductDataSheetLink: string;
    variant?: EnergyLabelVariant;
}

const energyLabel = {
    A: '#10964C',
    B: '#5DAF40',
    C: '#C3D33C',
    D: '#F8EA4B',
    E: '#F9B638',
    F: '#E56C29',
    G: '#DE0927',
};

const EnergyLabel = ({
    className,
    energyClass,
    energyLabelLink,
    energyProductDataSheetLink,
    variant = 'pdp',
}: Props) => {
    const { translate } = useTranslations();

    return (
        <Container className={className}>
            <IconLink
                href={energyLabelLink}
                target="_blank"
                rel="noreferrer noopener"
                title={`Energimærkning ${energyClass}`}
            >
                <EnergyIcon color={energyClass} variant={variant}>
                    {energyClass}
                </EnergyIcon>
            </IconLink>

            <TextLink
                target="_blank"
                rel="noreferrer noopener"
                href={energyProductDataSheetLink}
                title={translate('product.productDataSheetLink')}
            >
                {translate('product.productDataSheetLink')}
            </TextLink>
        </Container>
    );
};

interface IconProps {
    variant: EnergyLabelVariant;
    children: ReactChild;
    color: string;
}

function EnergyIcon({ variant, color, children }: IconProps) {
    const backgroundColor = energyLabel[color as keyof typeof energyLabel];

    return (
        <Icon
            variant={variant}
            viewBox="0 0 38 20"
            height="20"
            width="100%"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fill={backgroundColor} d="M8 0v20h20.122L38 10.001 28.122 0z" />
            <g fill="none" fillRule="evenodd">
                <path stroke="#000" fill="#fff" strokeWidth=".5" d="M.25.25h7.5v19.5H.25z" />
                <path d="M2 8h4v4H2z" />
                <path
                    stroke="#000"
                    strokeWidth=".5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.044 8.625v2.75M2.875 10.25 4 11.375l1.125-1.125"
                />
                <path
                    d="m4.645 5.605-.3.005H3.66l-.29-.005-.265.835c-.057.013-.132.02-.225.02a.979.979 0 0 1-.265-.035L2.6 6.4l1.13-3.38c.07-.013.172-.02.305-.02s.233.007.3.02l1.12 3.38a.478.478 0 0 1-.27.07c-.11 0-.188-.02-.235-.06a.477.477 0 0 1-.12-.22l-.185-.585Zm-1.14-.43.255-.005h.5l.25.005-.23-.725a20.706 20.706 0 0 1-.265-.905H3.99c-.04.183-.105.425-.195.725l-.29.905ZM4.105 15.135l.77-.005c.253 0 .38.117.38.35v1.3a3.42 3.42 0 0 1-1.235.215c-.25 0-.47-.047-.657-.14a1.134 1.134 0 0 1-.448-.385c-.21-.323-.315-.732-.315-1.225 0-.307.042-.578.125-.815a1.416 1.416 0 0 1 1.355-.98c.453 0 .842.122 1.165.365a.584.584 0 0 1-.255.4 1.473 1.473 0 0 0-.91-.31.81.81 0 0 0-.67.325c-.17.217-.255.557-.255 1.02 0 .87.318 1.305.955 1.305.237 0 .448-.028.635-.085v-.56a3.6 3.6 0 0 1 .01-.32h-.44c-.167 0-.25-.078-.25-.235 0-.077.013-.15.04-.22Z"
                    fillOpacity=".8"
                    fill="#000"
                    fillRule="nonzero"
                />
            </g>
            <text
                x="12"
                y="50%"
                height="20"
                fill="#fff"
                alignmentBaseline="central"
                dominantBaseline="central"
                fontSize="18"
            >
                {children}
            </text>
        </Icon>
    );
}

export default EnergyLabel;
