import { useMemo } from 'react';
import { Loop54Product } from '~/services';
import { Splash, SplashesWrapper } from './styled';

interface Props {
    campaigns: Loop54Product['Campaigns_Object'];
}

export const Splashes = ({ campaigns }: Props) => {
    const campaignSplashes = useMemo(() => {
        const splashMap = new Map<string, Loop54Product['Campaigns_Object'][0]>();
        campaigns.forEach((campaign) => {
            if (splashMap.has(String(campaign.CampaignId)) || !campaign.CampaignLabelDescription) {
                return;
            }
            splashMap.set(String(campaign.CampaignId), campaign);
        });
        return Array.from(splashMap.values());
    }, [campaigns]);

    return (
        <SplashesWrapper>
            {campaignSplashes.map((campaign) => (
                <Splash
                    key={campaign.CampaignId}
                    style={{
                        backgroundColor: `#${campaign.CampaignLabelColorCode}`,
                        color: `#${campaign.CampaignFontColorCode}`,
                    }}
                >
                    {campaign.CampaignLabelDescription}
                </Splash>
            ))}
        </SplashesWrapper>
    );
};
