import React, { ComponentProps, forwardRef } from 'react';
import { ButtonText, IconWrapper, StyledButton, StyledIcon, Wrapper } from '~/shared/styles/Button';
import { StyledLinkButton } from '~/shared/styles/Button/styles';
import { theme } from '~/theme';
import { LoadingSpinner } from '../LoadingSpinner';

export type ButtonColorOptions =
    | typeof theme.colors.brandBlue
    | typeof theme.colors.nightBlack
    | typeof theme.colors.offWhite
    | typeof theme.colors.thunderBlack
    | typeof theme.colors.grey50
    | typeof theme.colors.grey60;

export type ButtonProps = {
    children: React.ReactNode;
    color?: ButtonColorOptions;
    customIcon?: React.ReactNode;
    disabled?: boolean;
    isLoading?: boolean;
    noIcon?: boolean;
    variant?: 'fill' | 'stroke' | 'link';
    fullWidth?: boolean;
    onClick?: React.MouseEventHandler;
    wrapperClass?: string;
};

export const Button = forwardRef<any, ComponentProps<typeof StyledButton>>(function Render(
    {
        children,
        color = theme.colors.brandBlue,
        customIcon,
        isLoading,
        wrapperClass,
        noIcon,
        onClick,
        variant = 'fill',
        ...restProps
    },
    ref,
) {
    function onClickHandler(event: React.MouseEvent<HTMLButtonElement>) {
        if (isLoading) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }
        onClick?.(event);
    }

    return (
        <StyledButton
            color={color}
            ref={ref}
            isLoading={isLoading}
            onClick={onClickHandler}
            variant={variant}
            {...restProps}
        >
            <Wrapper className={wrapperClass}>
                <ButtonText>{children}</ButtonText>
                {noIcon ? null : (
                    <IconWrapper>{customIcon ? customIcon : <StyledIcon />}</IconWrapper>
                )}
            </Wrapper>
            {isLoading ? <LoadingSpinner /> : null}
        </StyledButton>
    );
});

export const ButtonAsLink = forwardRef<any, ComponentProps<typeof StyledLinkButton>>(
    function Render(props, ref) {
        return <StyledLinkButton ref={ref} {...props} />;
    },
);
