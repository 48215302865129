import { useQuery } from '@tanstack/react-query';
import { HTTPError } from 'ky';
import { useSession } from 'next-auth/react';
import { ShopTypes } from '~/lib/data-contract';
import { Shop, useUserProfile } from '~/services';

export default function useUsersList() {
    const { data } = useSession();
    const { data: profile } = useUserProfile();
    const customerId = profile?.user?.currentCustomerId ?? null;
    const token = data?.accessToken ?? null;

    return useQuery<ShopTypes.CustomerUsersResponse, HTTPError>(
        ['userList', { customerId, token }],
        ({ signal }) =>
            Shop.operations.getUsersList({
                customerId,
                token,
                signal,
            }),
        {
            enabled: Boolean(data?.accessToken),
        },
    );
}
