import { useMutation, useQueryClient } from '@tanstack/react-query';
import { HTTPError } from 'ky';
import { useSession } from 'next-auth/react';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';

export default function useDeleteUser() {
    const { data } = useSession();
    const token = data?.accessToken ?? null;
    const queryClient = useQueryClient();

    function mutationFn({
        customerId,
        userId,
    }: Parameters<typeof Shop.operations.deleteUser>[0]): Promise<string> {
        return Shop.operations.deleteUser({
            customerId,
            userId,
            token,
        });
    }

    return useMutation<
        string,
        HTTPError | Error,
        Parameters<typeof Shop.operations.deleteUser>[0],
        Context
    >(mutationFn, {
        onMutate: async ({
            customerId,
            userId,
        }: Parameters<typeof Shop.operations.deleteUser>[0]) => {
            if (!customerId || !userId) {
                return;
            }
            await queryClient.cancelQueries(['userList', { customerId }]);

            const previousUserList = queryClient.getQueryData<ShopTypes.CustomerUsersResponse>([
                'userList',
                { customerId },
            ]);

            if (previousUserList) {
                queryClient.setQueryData<ShopTypes.CustomerUsersResponse>(
                    ['userList', { customerId }],
                    (old) => {
                        if (old) {
                            return {
                                ...old,
                                users: old.users.filter((user) => user.userId !== userId),
                            };
                        }
                        return old;
                    },
                );
            }

            return { previousUserList };
        },

        onError: (_error, _, context) => {
            const userBeforeMutation = context?.previousUserList;
            if (userBeforeMutation) {
                queryClient.setQueryData(
                    ['userList', { customerId: userBeforeMutation.customerId }],
                    userBeforeMutation,
                );
            }
        },

        onSettled: (_error, _, variables) => {
            queryClient.invalidateQueries(['userList', { customerId: variables.customerId }]);
        },
    });
}

type Context = {
    previousUserList: ShopTypes.CustomerUsersResponse | undefined;
};
