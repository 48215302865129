import { HTTPError } from 'ky';
import { useQuery } from '@tanstack/react-query';
import { Loop54Types } from '~/lib/data-contract';
import { Loop54, Prefetch } from '~/services';
import { hours, seconds } from '~/shared/utils/helpers';

export default function useBasketRecommendations<SelectType = Response>(
    params: Loop54Types.GetBasketRecommendationsRequest,
    select?: (data: Response) => SelectType,
) {
    return useQuery<Response, HTTPError | Error, SelectType>(
        ['basketRecommendations', params],
        ({ signal }) => Loop54.operations.getBasketRecommendations(signal, params),
        {
            enabled: !!params,
            select,
            retry: 1,
            keepPreviousData: true,
            cacheTime: 0, // Disable cache so that we get personalized result every time page is requested
            staleTime: hours(1), // Keep personalized result for 1 hour
            refetchOnWindowFocus: false, // Never refetch on window focus since that will cause the products to change position
        },
    );
}

type PrefetchBasketRecommendations = {
    productIds: string[];
} & Prefetch;

export function prefetchBasketRecommendations({
    queryClient,
    productIds,
}: PrefetchBasketRecommendations) {
    const params: Loop54Types.GetBasketRecommendationsRequest = {
        entities: productIds.map((id) => ({
            type: 'Product',
            id,
        })),
        resultsOptions: {
            sortBy: [{ type: 'popularity' }],
            take: 10,
        },
    };

    return queryClient.prefetchQuery(
        ['basketRecommendations', params],
        ({ signal }) => Loop54.operations.getBasketRecommendations(signal, params),
        {
            cacheTime: seconds(10),
            staleTime: hours(1),
        },
    );
}

type Response = Loop54Types.GetBasketRecommendationsResponse;
