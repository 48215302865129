import styled from '@emotion/styled';
import { ifNotProp, ifProp } from 'styled-tools';

type StyledLinkProps = {
    animation: 'default' | 'reverse' | 'none' | 'static';
    multipleElements: boolean;
    fullWidth: boolean;
    displayFlex: boolean;
};

export const StyledLink = styled.span<StyledLinkProps>(
    {
        position: 'relative',
        cursor: 'pointer',
        width: '100%',
        transition: 'color 0.2s',
        alignItems: 'center',
    },

    ifProp('multipleElements', ({ theme, animation }) => ({
        [`${StyledLinkAnimationText}`]: {
            width: 'fit-content',
            backgroundImage: 'linear-gradient(transparent calc(100% - 1px), currentColor 1px)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: animation === 'default' ? '100% 100%' : '0% 100%',
            transition: 'color 0.2s, background-size .2s ' + theme.easings.smoothEase,

            'a:hover &, button:hover &, [aria-selected="true"] &': {
                backgroundSize: animation === 'default' ? '0% 100%' : '100% 100%',
            },
        },
    })),

    ifProp('displayFlex', {
        display: 'flex',
    }),

    ifProp('fullWidth', {
        display: 'flex',
        justifyContent: 'space-between',
    }),

    ifNotProp({ animation: 'none' }, ({ theme, animation, multipleElements }) => ({
        backgroundImage: multipleElements
            ? ''
            : 'linear-gradient(transparent calc(100% - 1px), currentColor 1px)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: animation === 'default' ? '100% 100%' : '0% 100%',
        transition: 'color 0.2s, background-size .2s ' + theme.easings.smoothEase,

        'a:hover &, button:hover &': {
            backgroundSize: animation === 'default' ? '0% 100%' : '100% 100%',
        },
    })),

    ifProp({ animation: 'static' }, () => ({
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
    })),
);

// This is just an span used to animate on a nested text inside a link. Can only be used with multipleElements prop
export const StyledLinkAnimationText = styled.span`
    .subtitle {
        font-weight: ${({ theme }) => theme.fontWeights.normal};
        font-size: ${({ theme }) => theme.fontSizes.guinea};
        line-height: ${({ theme }) => theme.lineHeights.coyote};
        letter-spacing: -0.11px;
        color: ${({ theme }) => theme.colors.grey50};
    }

    .brandSuggestion {
        font-weight: ${({ theme }) => theme.fontWeights.normal};
        font-size: ${({ theme }) => theme.fontSizes.guinea};
        line-height: ${({ theme }) => theme.lineHeights.coyote};
        letter-spacing: -0.11px;
        color: ${({ theme }) => theme.colors.searchSuggestion};
    }

    .searchResult {
        font-weight: ${({ theme }) => theme.fontWeights.normal};
        font-size: ${({ theme }) => theme.fontSizes.guinea};
        line-height: ${({ theme }) => theme.lineHeights.coyote};
        letter-spacing: -0.11px;
        color: ${({ theme }) => theme.colors.searchSuggestion};
    }

    .searchSuggestion {
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        font-size: ${({ theme }) => theme.fontSizes.guinea};
        line-height: ${({ theme }) => theme.lineHeights.coyote};
        letter-spacing: -0.11px;
        color: ${({ theme }) => theme.colors.searchSuggestion};
    }
`;
