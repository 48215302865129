import loop54 from '~/lib/api/loop54';
import { Loop54Types } from '~/lib/data-contract';
import { Loop54 } from '~/services';

export default function getEntitiesByAttribute(
    params: Loop54Types.GetEntitiesByAttributeRequest | undefined
) {
    if (!params) {
        return Promise.reject(new Error(Loop54.errors.missingParams.message));
    }

    return loop54
        .post(Loop54.utils.getUrl('/getEntitiesByAttribute'), {
            json: params,
        })
        .json<Loop54Types.GetEntitiesByAttributeResponse>();
}
