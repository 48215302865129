import { getProductFilter } from '~/features/ProductList/utils';
import { Prefetch } from '~/services';
import { Loop54ProductAttributes } from '../types';
import {
    buildPreFetcherForEntities,
    useEntitiesOrEntitiesByAttribute,
    type EntitiesOrEntitiesByAttributeRequest,
    type EntitiesOrEntitiesByAttributeResponse,
} from './useEntitiesOrEntitiesByAttributes';

const queryKey = 'popularEntities';

export default function usePopularEntities<SelectType = EntitiesOrEntitiesByAttributeResponse>(
    params: EntitiesOrEntitiesByAttributeRequest | undefined,
    select?: (data: EntitiesOrEntitiesByAttributeResponse) => SelectType,
) {
    return useEntitiesOrEntitiesByAttribute(queryKey, params, select);
}

type PrefetchPopularEntities = Prefetch & {
    amount?: number;
};

export function prefetchPopularEntities({ queryClient, amount }: PrefetchPopularEntities) {
    const params: EntitiesOrEntitiesByAttributeRequest = {
        resultsOptions: {
            filter: {
                and: [
                    getProductFilter(),
                    {
                        type: 'attribute',
                        attributeName: Loop54ProductAttributes.InStock,
                        value: true,
                    },
                ],
            },
            sortBy: [{ type: 'popularity' }],
            take: amount ?? 10,
        },
    };

    return buildPreFetcherForEntities({ queryKey, params, queryClient });
}
