import { ShopTypes } from '~/lib/data-contract';
import { pushToDataLayer } from '../utils/pushToDataLayer';
import { GAErrorEvent } from '../utils/types';

export function getPricesCall(product?: ShopTypes.ProductQueryProduct) {
    if (product) {
        pushToDataLayer({
            event: GAErrorEvent.Price,
        });
    }
}
