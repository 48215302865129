import Script from 'next/script';

export function CookieConsentScript() {
    return (
        <Script
            id="CookieConsent"
            type="text/javascript"
            strategy="lazyOnload"
            data-culture="DA"
            src="//policy.app.cookieinformation.com/uc.js"
        />
    );
}
