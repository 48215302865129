import Cookies from 'js-cookie';
import { Cookie } from '~/shared/utils/cookie';

type CookieConsentSettings = {
    marketing?: boolean;
    functional?: boolean;
    statistics?: boolean;
};

export const getCookieConsent = (): CookieConsentSettings => {
    const consentCookie = Cookies.get(Cookie.cookieConsent);
    const consentData = consentCookie ? JSON.parse(consentCookie) : consentCookie;

    const cookieConsentSettings: CookieConsentSettings = {
        marketing: consentData?.consents_approved?.includes('cookie_cat_marketing'),
        functional: consentData?.consents_approved?.includes('cookie_cat_functional'),
        statistics: consentData?.consents_approved?.includes('cookie_cat_statistic'),
    };

    return cookieConsentSettings;
};
