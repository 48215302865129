import { useQuery } from '@tanstack/react-query';
import { HTTPError } from 'ky';
import { ShopTypes } from '~/lib/data-contract';
import { Prefetch, Shop } from '~/services';
import { minutes, seconds } from '~/shared/utils/helpers';

export default function useProduct<SelectType = Product>(
    itemNo: string,
    select?: (data: Product) => SelectType,
    onSuccess?: (data: SelectType) => void,
) {
    return useQuery<Product, HTTPError | Error, SelectType>(
        ['product', itemNo],
        () => Shop.operations.getProduct(itemNo),
        {
            enabled: Boolean(itemNo),
            staleTime: minutes(30),
            cacheTime: seconds(30),
            retry: 1,
            keepPreviousData: true, // Keep data to prevent flickering when changing variants
            refetchOnWindowFocus: true,
            select,
            onSuccess,
        },
    );
}

export function prefetchProduct({ itemNo, queryClient }: Props) {
    return queryClient.fetchQuery(['product', itemNo], () => Shop.operations.getProduct(itemNo), {
        staleTime: 0,
    });
}

interface Props extends Prefetch {
    itemNo: string | undefined;
}

type Product = ShopTypes.ProductQueryResult & ShopTypes.ApiExceptionResponse;
