export const Cookie = {
    userId: 'fog~userId',
    ogLocation: 'fog~ogLocation',
    contextGroup: 'fog~contextGroup',
    currentItemList: 'fog~itemList',

    // External
    cookieConsent: 'CookieInformationConsent',

    // GA4
    ga4ClientId: '_ga',
    ga4SessionIdProduction: '_ga_V7FFMMWSQL',
    ga4SessionIdQA: '_ga_4BQBEE569B',
    ga4SessionIdTest: '_ga_4BQBEE569B',
};
