import * as colors from './colors';

export type ThemeType = typeof theme;

export const theme = {
    colors,
    fonts: {
        headline: 'Dinpro, helvetica, arial, sans-serif',
        body: 'Dinpro, helvetica, arial, sans-serif',
        mono: 'monospace',
    },
    fontWeights: {
        normal: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
    },
    fontSizes: {
        ant: '8px',
        beetle: '10px',
        frog: '11px',
        mouse: '12px',
        rat: '13px',
        guinea: '14px',
        squirrel: '15px',
        chicken: '16px',
        cat: '20px',
        wolf: '25px',
        bear: '30px',
        hippo: '35px',
        elephant: '40px',
    },
    lineHeights: {
        mosquito: '9px',
        beetle: '10px',
        frog: '11px',
        mouse: '12px',
        rat: '13px',
        guinea: '14px',
        squirrel: '15px',
        chicken: '16px',
        owl: '18px',
        turkey: '19px',
        cat: '20px',
        fox: '21px',
        dog: '22px',
        coyote: '23px',
        wolf: '25px',
        bear: '30px',
        hippo: '35px',
        elephant: '40px',
        mammoth: '45px',
    },
    space: {
        '1': '5px',
        '2': '10px',
        '3': '15px',
        '4': '20px',
        '5': '30px',
        '6': '40px',
        '7': '50px',
        '8': '60px',
        '9': '70px',
        '10': '80px',
        '11': '90px',
        '12': '100px',
        gutter: 'min(6vw, 115px)',
    },
    sizes: {
        // Limit content
        contentMaxWidth: 1440,

        // Limit page width. Background colors etc.
        pageMaxWidth: 1680,

        content: 'min(88vw, 1690px)',
    },
    navigationOffsetTop: {
        desktop: 120,
        mobile: 20,
    },
    zIndices: {
        '1': 100,
        '2': 200,
        '3': 300,
        '4': 400,
        max: 999,

        searchField: 175,
        searchButtons: 200,
        searchResult: 150,
        mainNavigation: 400,
        mainNavigationContent: 300,
        background: 300,
        miniBasket: 290,
        modalContent: 100,
    },
    easings: {
        smoothEase: 'cubic-bezier(0.35, 0, 0.15, 1)',
    },
    shadows: {
        content: '0 5px 14px 0 rgba(0,0,0,0.2)',
    },
} as const;
