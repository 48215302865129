import { useTranslations } from '~/services';
import { Button } from '~/shared/components/Button';
import { useUrlQueryState } from '~/shared/hooks';
import { theme } from '~/theme';
import { getTranslatedContextName } from '../helpers/filterHelper';
import { Container } from './styled';

interface ContextFiltersProps {
    contextList: string[];
}

const FILTER_NAME = 'ContextGroup';

const ContextFilters = ({ contextList }: ContextFiltersProps) => {
    const { translate } = useTranslations();
    const [selectedFilters, setSelectedFilters, clearQueryState] = useUrlQueryState();

    if (!contextList || contextList?.length < 2) {
        return null;
    }

    const filteredContextList = contextList?.filter((name) => name !== 'none');

    const activeContext = selectedFilters?.[FILTER_NAME]?.[0];

    const setContextGroup = (group?: string) => {
        setSelectedFilters(FILTER_NAME, group ? [group] : []);
        clearQueryState();
    };

    return (
        <Container>
            <li>
                {activeContext && activeContext !== 'none' ? (
                    <Button
                        color={theme.colors.nightBlack}
                        noIcon
                        variant="stroke"
                        onClick={() => {
                            setContextGroup('none');
                        }}
                    >
                        {translate('navigation.filter.allProducts')}
                    </Button>
                ) : (
                    <Button noIcon>{translate('navigation.filter.allProducts')}</Button>
                )}
            </li>

            {filteredContextList.map((context) => (
                <li key={context}>
                    {activeContext && activeContext === context ? (
                        <Button noIcon>{getTranslatedContextName(context, translate)}</Button>
                    ) : (
                        <Button
                            color={theme.colors.nightBlack}
                            noIcon
                            variant="stroke"
                            onClick={() => {
                                setContextGroup(context);
                            }}
                        >
                            {getTranslatedContextName(context, translate)}
                        </Button>
                    )}
                </li>
            ))}
        </Container>
    );
};

export default ContextFilters;
