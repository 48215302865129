import { Loop54, Loop54ProductAttributes, useSKUEntities } from '~/services';

type Arguments = {
    productNumbers: string;
    isMobile: boolean;
};

export default function useSKUResults({ productNumbers, isMobile }: Arguments) {
    return useSKUEntities(
        {
            resultsOptions: {
                filter: {
                    or: productNumbers
                        ?.trim()
                        .split('\n')
                        .map((product) => {
                            return {
                                type: 'attribute',
                                attributeName: Loop54ProductAttributes.SKU,
                                value: product,
                            };
                        }),
                },
                sortBy: [{ type: 'relevance' }],
                take: isMobile ? 4 : 25,
            },
        },
        (data) => ({
            ...data,
            results: Loop54.utils.reduceLoop54Product(data.results),
        }),
    );
}
