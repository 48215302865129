import shop from '~/lib/api/shop';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';

export default function getConfirmed(transactionId: string, basketReference: string) {
    const url = Shop.utils.getUrl('/checkout/confirmed');
    url.searchParams.set('transactionId', transactionId);
    url.searchParams.set('basketReference', basketReference || '');

    return shop
        .get(url, {
            timeout: 60 * 1000, // 60 seconds
            retry: 3,
        })
        .json<ShopTypes.ConfirmPaymentResult>();
}
