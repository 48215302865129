export const splitOnLastSpace = (input?: string): string[] => {
    if (!input) {
        return ['', ''];
    }

    const indexOfLastSpace = input.lastIndexOf(' ');

    if (indexOfLastSpace < 0) {
        return [input, ''];
    }

    return [input.slice(0, indexOfLastSpace), input.slice(indexOfLastSpace + 1)];
};

export const capitalizeFirstLetter = ([firstLetter, ...restOfWord]: string) =>
    `${firstLetter.toUpperCase()}${restOfWord.join('')}`;

// https://stackoverflow.com/a/7592235/1312570
export const capitalizeWholeWords = (str: string, lower = false) =>
    (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
        match.toUpperCase()
    );
