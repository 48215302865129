import { useCallback, useMemo } from 'react';
import CloseIcon from '~/icons/iconography/close.svg';
import {
    useTranslations,
    useFilterDefaults,
} from '~/services';
import { useUrlQueryState } from '~/shared/hooks';
import { theme } from '~/theme';
import { EXCLUDED_FACET_LIST } from '../..';
import { FilterButton } from '../FilterButton/styled';
import {
    getFilterButtonText,
    getTranslatedFacetName,
    getTranslatedFacetValue,
} from '../helpers/filterHelper';
import { Container } from './styled';

const AppliedFilters = () => {
    const { translate } = useTranslations();
    const [selectedFilters, setSearchFilters, clearSearchFilters] = useUrlQueryState();
    const { data: filterDefaults } = useFilterDefaults();

    const { facets: allFacets } = filterDefaults ?? {};

    const removeSearchFilter = useCallback(
        (facetName: string, filterName: string) => {
            const newFilterList = selectedFilters?.[facetName]?.filter(
                (filter) => filter !== filterName
            );

            if (newFilterList) {
                setSearchFilters(facetName, newFilterList);
            }
        },
        [selectedFilters, setSearchFilters]
    );

    const filterElementList = useMemo(
        () =>
            !selectedFilters
                ? null
                : Object.entries(selectedFilters)
                      // Skip excluded facets, e.g. ContextGroup
                      ?.filter(([facetName]) => !EXCLUDED_FACET_LIST.includes(facetName))
                      // Only show valid facets that exists
                      ?.filter(([facetName]) => Object.keys(allFacets ?? {}).includes(facetName))
                      ?.flatMap(([facetName, filterList]) =>
                          filterList.map((filter) => (
                              <li key={filter}>
                                  <FilterButton
                                      color={theme.colors.nightBlack}
                                      noIcon
                                      onClick={() => {
                                          removeSearchFilter(facetName, filter);
                                      }}
                                      type="button"
                                      variant="stroke"
                                  >
                                      <CloseIcon />

                                      {getFilterButtonText(
                                          getTranslatedFacetName(facetName, translate) || '',
                                          getTranslatedFacetValue(facetName, filter, translate) ||
                                              filter
                                      )}
                                  </FilterButton>
                              </li>
                          ))
                      ),
        [removeSearchFilter, selectedFilters, allFacets, translate]
    );

    if (!selectedFilters || Object.values(selectedFilters).length < 1) {
        return null;
    }

    return (filterElementList?.length || 0) < 1 ? null : (
        <Container>
            <li>
                <FilterButton
                    removeAllElementFilter
                    color={theme.colors.nightBlack}
                    noIcon
                    onClick={clearSearchFilters}
                    type="button"
                >
                    <CloseIcon />
                    {translate('product-list.reset')}
                </FilterButton>
            </li>

            {filterElementList}
        </Container>
    );
};

export default AppliedFilters;
