import shop from '~/lib/api/shop';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';

export default function setDeliveryMethod({
    deliveryId,
    freightProvider,
    freightOption,
    parcelServicePoint,
    reference,
}: SetDeliveryMethodArguments) {
    if (!reference) {
        return Promise.reject(new Error(Shop.errors.referenceNotFound.message));
    }

    const url = Shop.utils.getUrl(`/baskets/${reference}/update/deliverymethod/${deliveryId}`);

    return shop
        .post(url, {
            json: { freightOption, freightProvider, parcelServicePoint },
        })
        .json<ShopTypes.BasketResponse>();
}

interface SetDeliveryMethodArguments {
    deliveryId: number;
    freightProvider?: string;
    freightOption?: ShopTypes.FreightOption;
    parcelServicePoint?: ShopTypes.ParcelServicePoint;
    reference: string | undefined;
}
