import { useMutation } from '@tanstack/react-query';
import { Loop54Types } from '~/lib/data-contract';
import { Loop54 } from '~/services';

type Arguments = Loop54Types.Event;

export default function useCreateEvents() {
    return useMutation<Loop54Types.Response, Loop54Types.ErrorDetails, Arguments>(
        (props: Arguments) => Loop54.operations.createEvents(props),
    );
}
