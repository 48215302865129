import Script from 'next/script';
import { useEffect } from 'react';

export function UserLinkScript({ hide }: { hide: boolean }) {
    const props = {
        domain: 'johannesfog.dk',
    };

    useEffect(() => {
        const smartSupp = document.getElementById('bai-cb-container');
        if (smartSupp) {
            smartSupp.style.display = hide ? 'none' : 'block';
        }
    }, [hide]);

    return (
        <>
            <Script
                src="https://files.userlink.ai/public/embed.min.js"
                charSet="utf-8"
                id="bai-scr-emb"
                data-cid="66e7ed820e9fc55f98a24a1a"
                async
                {...props}
            ></Script>
        </>
    );
}
