import { ImageLoaderProps } from 'next/image';

interface Props extends ImageLoaderProps {
    imageHost: string;
}

// https://developers.cloudflare.com/images/image-resizing/integration-with-frameworks/
export function cloudflare({ src, width, quality, imageHost }: Props) {
    const url = new URL(src);

    // Resize is unnecessary for vector-based SVG images but we still want to point to cloudflare's CDN
    if (url.pathname.endsWith('.svg')) {
        return `${imageHost}/${normalizeUrl(url)}`;
    }

    // https://developers.cloudflare.com/images/image-resizing/url-format/#format
    const params = ['format=auto', `width=${width}`];

    if (quality) {
        params.push(`quality=${quality}`);
    }

    const paramsString = params.join(',');

    return `${imageHost}/cdn-cgi/image/${paramsString}/${normalizeUrl(url)}`;
}

function normalizeUrl(url: URL) {
    const path = `${url.pathname}${url.search}`;
    return path.startsWith('/') ? path.slice(1) : path;
}
