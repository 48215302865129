import { Loop54Types } from '~/lib/data-contract';
import { Loop54Product } from '../types';

export default function reduceLoop54Product(collection: Loop54Types.EntityCollection | undefined) {
    return {
        ...collection,
        items: collection?.items?.map(
            (item) =>
                ({
                    ...item.attributes?.reduce<Loop54Product>(
                        (acc, attribute) => ({
                            ...acc,
                            [attribute.name!]: getValue(attribute),
                        }),
                        {} as Loop54Product,
                    ),
                }) as Loop54Product,
        ),
    };
}

function getValue(attributes: ArrayType<Loop54Types.Entity['attributes']>) {
    if (attributes?.name?.endsWith('_Object')) {
        return attributes?.values?.map((value) => JSON.parse(value as string));
    }

    if (attributes?.type === 'number') {
        return attributes?.values?.[0] as number;
    }

    return attributes?.values?.[0];
}

type ArrayType<T> = T extends (infer Item)[] ? Item : T;
