import shop from '~/lib/api/shop';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';

export default function getFreight(itemNo: string | undefined, postalCode: string | undefined) {
    if (!itemNo || !postalCode) {
        return Promise.reject(new Error(Shop.errors.invalidItemNoOrPostalCode.message));
    }

    const url = Shop.utils.getUrl(`/freight/price/${itemNo}`);

    if (postalCode) {
        url.searchParams.set('postalCode', postalCode);
    }

    return shop.get(url).json<ShopTypes.DeliveryPriceDetails>();
}
