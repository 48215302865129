import { HTTPError } from 'ky';
import { useQuery } from '@tanstack/react-query';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';

export default function useOrderStatus(
    reference: string | undefined,
    email: string | undefined,
    enabled = true,
) {
    return useQuery<ShopTypes.OrderStatusResponse, HTTPError | Error>(
        ['orderStatus', { reference, email }],
        () => Shop.operations.getOrderStatus(reference, email),
        {
            enabled: enabled && !!email,
        },
    );
}
