import { useMutation, useQueryClient } from '@tanstack/react-query';
import { HTTPError } from 'ky';
import { useSession } from 'next-auth/react';
import { ShopTypes } from '~/lib/data-contract';
import { Shop, useUserProfile } from '~/services';

export default function useExportPriceList() {
    const { data } = useSession();
    const { data: profile } = useUserProfile();
    const customerId = profile?.customer?.id ?? null;
    const email = profile?.user?.email ?? null;
    const token = data?.accessToken ?? null;
    const queryClient = useQueryClient();

    return useMutation<ShopTypes.ProblemDetails, HTTPError | Error>(
        ['userExportPriceList', { customerId, email, token }],
        () => Shop.operations.getExportPriceList({ customerId, email, token }),
        {
            onSuccess: (data) => {
                queryClient.setQueryData(
                    [
                        'userExportPriceList',
                        { customerId, email },
                        {
                            enabled: Boolean(token),
                        },
                    ],
                    data,
                );
                queryClient.invalidateQueries(['userExportPriceList', { customerId, email }]);
            },
        },
    );
}
