import shop from '~/lib/api/shop';
import { Shop } from '~/services';

export default function getDeliveryOptions(reference: string | undefined, deliveryId: number) {
    if (!reference) {
        return Promise.reject(new Error(Shop.errors.referenceNotFound.message));
    }

    return shop
        .get(Shop.utils.getUrl(`/baskets/deliverydays/${reference}/${deliveryId}`))
        .json<string[]>();
}
