import NextLink from 'next/link';
import { IP40ProductListPage } from '~/lib/data-contract/umbraco-api-model';
import { useNavigation, usePage } from '~/services';
import { Link } from '~/shared/components/Link';
import { NavigationItem } from './NavigationItem';
import { List, MenuTitle } from './styled';
import { Dispatch, SetStateAction, useEffect } from 'react';

type Props = {
    hasLeftNavigation: boolean;
    setHasLeftNavigation: Dispatch<SetStateAction<boolean>>;
};

const Navigation = ({ hasLeftNavigation, setHasLeftNavigation }: Props) => {
    const {
        data: page,
        isLoading: pageIsLoading,
        isFetched: pageIsFetched,
    } = usePage<IP40ProductListPage>();
    const {
        data: navigation,
        isLoading: navigationIsLoading,
        isFetched: navigationIsFetched,
    } = useNavigation(2);
    const secondLevelCategory = page?.breadcrumb?.items?.[2];

    useEffect(() => {
        if (pageIsFetched && navigationIsFetched) {
            setHasLeftNavigation(!!navigation?.children?.[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        navigation?.children,
        navigationIsFetched,
        navigationIsLoading,
        page?.breadcrumb?.items,
        pageIsLoading,
        pageIsFetched,
    ]);

    return (
        <>
            {secondLevelCategory ? (
                <MenuTitle variant="display5" as="h3">
                    <NextLink
                        prefetch={false}
                        href={secondLevelCategory?.url || '/'}
                        passHref
                        legacyBehavior
                    >
                        <Link animation="reverse" title={secondLevelCategory.title}>
                            {secondLevelCategory.text}
                        </Link>
                    </NextLink>
                </MenuTitle>
            ) : null}

            {hasLeftNavigation ? (
                <List>
                    {navigation?.children?.map((item, index) => (
                        <NavigationItem key={`SideNavigationItem-${item.id}-${index}`} {...item} />
                    ))}
                </List>
            ) : null}
        </>
    );
};

export default Navigation;
