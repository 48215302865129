import shop from '~/lib/api/shop';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';

type Args = {
    users: ShopTypes.EditUserRequest;
    token: string | null;
};

export default function setUsersList({ users, token }: Args) {
    return shop
        .put(Shop.utils.getUrl('/customers/users'), {
            json: users,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .json<ShopTypes.EditUserRequest>();
}
