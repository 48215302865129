import { RangeValue } from '../types';

// Find the index of the closest value within given valueRange
export const getClosestIndex = (value: number, valueRange: RangeValue) => {
    const closestValue = valueRange.reduce((prev, curr) =>
        Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
    );

    const closestIndex = valueRange.indexOf(closestValue);

    return closestIndex;
};
