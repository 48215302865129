import shop from '~/lib/api/shop';
import { ShopTypes } from '~/lib/data-contract';
import { ParcelServiceProvider, Shop } from '~/services';

export default function getParcelServicePoints(
    provider: ParcelServiceProvider,
    query: ShopTypes.ParcelServicePointQuery
) {
    return shop
        .post(Shop.utils.getUrl(`/checkout/parcelservicepoints/${provider}`), {
            json: query,
        })
        .json<ShopTypes.ParcelServicePointQueryResult>();
}
