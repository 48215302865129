import { ShopTypes } from '~/lib/data-contract';
import { pushToDataLayer } from '../utils/pushToDataLayer';
import { GAEcommerceEvent } from '../utils/types';
import { getGABasketItems } from '../utils/getGABasketItems';

export function viewCart(basket?: ShopTypes.BasketResponse) {
    if (basket) {
        pushToDataLayer({ ecommerce: null });
        pushToDataLayer({
            event: GAEcommerceEvent.ViewCart,
            ecommerce: {
                currency: 'DKK',
                value: basket?.total?.priceInclVat,
                items: getGABasketItems(basket.contexts),
            },
        });
    }
}
