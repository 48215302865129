const type = {
    invalidQuery: {
        name: 'InvalidQuery',
        message: 'Invalid query or missing query',
    },
    missingPostalCode: {
        name: 'MissingPostalCode',
        message: 'Missing PostalCode',
    },
};

export default type;
