import umbraco from '~/lib/api/umbraco';
import { UmbracoTypes } from '~/lib/data-contract';
import { Umbraco } from '~/services';

export default function getFrame(signal: AbortSignal | undefined, asPath?: string) {
    const url = Umbraco.utils.getUrl('/content/frame');
    url.searchParams.append('url', asPath ?? '/'); // When supporting multiple languages, this will need to be changed

    return umbraco.get(url, { signal }).json<UmbracoTypes.IFrame>();
}
