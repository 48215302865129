import Cookies from 'js-cookie';
import { generateUserId } from '~/shared/hooks/useGenerateUserId';
import { getCookieConsent } from '~/shared/utils/cookie/consent';
import { isBrowser } from '~/shared/utils/platform';
import { Cookie } from '../cookie';
import { useCookie } from '../utils';

export function useUserId() {
    const [userId, setUserId] = useCookie({
        key: Cookie.userId,
        initialValue: generateUserId(),
        options: {
            expires: 365,
            path: '/',
        },
    });

    // setUserId should only be used if user has consented to cookies
    return [userId, setUserId] as const;
}

export function getUserId() {
    try {
        const { statistics } = getCookieConsent();

        if (!isBrowser || !statistics) {
            return generateUserId();
        }

        return Cookies.get(Cookie.userId) || generateUserId();
    } catch (_) {
        return generateUserId();
    }
}
