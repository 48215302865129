import { ShopTypes } from '~/lib/data-contract';
import { useBasket } from '~/services';

export default function useBasketContainsInvalidItems() {
    return useBasket((data) =>
        data.contexts
            ?.flatMap((context) => context.freights)
            ?.flatMap((freight) => freight?.brands)
            ?.flatMap((brand) => brand?.items)
            ?.find(
                (item) =>
                    item?.itemStatus === ShopTypes.ItemStatusValues.Obsolete ||
                    item?.validationError
            )
    );
}
