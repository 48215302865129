import { create } from 'zustand';

type CookieConsentSettings = {
    marketing?: boolean;
    functional?: boolean;
    statistics?: boolean;
};

type CookieConsentState = CookieConsentSettings & {
    setConsent: (settings: CookieConsentSettings) => void;
};

export const useCookieConsentState = create<CookieConsentState>((set) => ({
    setConsent: (settings) => {
        set(settings);
    },
}));
