import { Translate } from '~/services';

export const getFilterButtonText = (facet: string, filter: string): string =>
    (filter.includes('min') && filter.includes('max')
        ? `${facet}: ${getRangeFilterText(filter)}`
        : `${facet}: ${filter}`
    ).replace(/^: /, '');

const getRangeFilterText = (jsonString: string) => {
    const rangeFilter = JSON.parse(jsonString);

    return `${rangeFilter.min} - ${rangeFilter.max} kr`;
};

export const getTranslatedFacetName = (facet: string | undefined, translate: Translate) =>
    facet ? translate(`product-list-filters.${facet}`) || '' : facet;

export const getTranslatedFacetValue = (
    facetName: string | undefined,
    value: string | undefined,
    translate: Translate
) =>
    value
        ? translate(`product-list-values.${facetName}.${value}`, undefined, true) ||
          translate(`product-list-values.${value}`, undefined, true) ||
          value
        : value;

export const getTranslatedContextName = (context: string | undefined, translate: Translate) =>
    context ? translate(`product-list-contexts.${context}`) || context : context;

export const getTranslatedSortingName = (sorting: string | undefined, translate: Translate) =>
    sorting ? translate(`product-list-sortorders.${sorting}`) || sorting : sorting;
