import { HTTPError } from 'ky';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ShopTypes } from '~/lib/data-contract';
import { Shop, useBasketReference } from '~/services';
import { useBasketTimer } from '~/shared/utils/storage';

export default function useClearBasket() {
    const queryClient = useQueryClient();
    const { data: reference } = useBasketReference();
    const [, setTimer] = useBasketTimer();

    return useMutation<ShopTypes.BasketResponse, HTTPError | Error>(
        () => Shop.operations.clearBasket(reference),
        {
            onSuccess: (data) => {
                queryClient.setQueryData(['basket', { reference: data.basketReference }], data);
                setTimer(data.updated.toString());
            },
        },
    );
}
