import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useUserId, Cookie } from '~/shared/utils/cookie';
import { useCookieConsentState } from '~/state/useCookieConsentState';
import { generateUserId } from './utils';

export function useGenerateUserId() {
    const [userId, setUserId] = useUserId();
    const { functional } = useCookieConsentState();

    useEffect(() => {
        const hasUserIdInCookie = Cookies.get(Cookie.userId);

        if (globalThis.navigator.cookieEnabled && functional && (!hasUserIdInCookie || !userId)) {
            setUserId(userId || generateUserId());
        }
    }, [userId, setUserId, functional]);
}
