import { useBasket } from '~/services';

export default function useDeliveryAddress(deliveryId: number) {
    return useBasket(
        (data) =>
            data.contexts
                ?.flatMap((context) => context?.freights)
                ?.find((freight) => freight?.deliveryId === deliveryId)?.deliveryAddress
    );
}
