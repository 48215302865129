import { ShopTypes } from '~/lib/data-contract';
import { GAEcommerceItem } from './types';

export function getGABasketItem(
    item: ShopTypes.BasketItemResponse,
    brandName: string,
    itemIndex: number,
    itemListName?: string,
) {
    const categories = item?.categoryBreadcrumb?.split('|');

    return {
        item_id: item?.itemNumber,
        item_name: `${item?.name ? `${item?.name} ` : ''}${item?.name2 ?? ''}`,
        index: itemIndex,
        item_brand: brandName,
        item_category: categories?.[0] ? categories[0] : undefined,
        item_category2: categories?.[1] ? categories[1] : undefined,
        item_category3: categories?.[2] ? categories[2] : undefined,
        item_category4: categories?.[3] ? categories[3] : undefined,
        item_list_name: itemListName ? itemListName : undefined,
        item_variant:
            item?.variantType || item?.variantName
                ? `${item?.variantType ? `${item?.variantType}: ` : ''}${item?.variantName ?? ''}`
                : undefined,
        price: item?.price?.priceInclVat,
        quantity: item?.quantity,
        campaign_id:
            item?.activeCampaigns && item?.activeCampaigns?.length > 0
                ? item?.activeCampaigns?.[0].entityId
                : undefined,
        currency: 'DKK',
    } as GAEcommerceItem;
}
