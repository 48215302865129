import { UmbracoTypes } from '~/lib/data-contract';
import { Cookie } from '~/shared/utils/cookie';
import { isBrowser } from '~/shared/utils/platform';
import { breaks, queries } from '~/theme';
import { pushToDataLayer } from '../utils/pushToDataLayer';

export function virtualPageView(
    url: string,
    type: UmbracoTypes.IPages['type'] | undefined,
    pageTitle?: string,
    status?: 'authenticated' | 'loading' | 'unauthenticated',
) {
    const pageUrl = new URL(url, window.location.origin);

    pushToDataLayer({
        event: 'virtualPageView',
        pageType: type || 'Unknown',
        pageUrl: pageUrl.origin,
        pagePath: pageUrl.pathname + pageUrl.search,
        pageTitle: pageTitle,
        ogLocation: sessionStorage.getItem(Cookie.ogLocation),
        environment: getEnvironmentType(pageUrl),
        loginStatus: status === 'authenticated' ? 'loggedIn' : 'loggedOut',
        deviceType: getDeviceType(),
    });
}

function getDeviceType() {
    if (!isBrowser) {
        return 'Unknown';
    }

    const isMobile = window.matchMedia(queries.xs).matches;
    const isTablet = window.matchMedia(
        `(min-width: ${breaks.xs}px) and (max-width: ${breaks.md}px)`,
    ).matches;
    const isDesktop = window.matchMedia(queries.md).matches;

    return (
        (isMobile && 'mobile') || (isTablet && 'tablet') || (isDesktop && 'desktop') || 'Unknown'
    );
}

function getEnvironmentType(pageUrl: URL) {
    if (pageUrl.origin.includes('johannesfog.dk')) {
        return 'prod';
    } else if (pageUrl.origin.includes('johannesfogtest.dk')) {
        return 'test';
    }

    return 'dev';
}
