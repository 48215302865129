import { useMotionValue, useTransform } from 'framer-motion';
import { forwardRef, KeyboardEvent, useEffect } from 'react';
import { AnimatedKnob } from './styled';

interface KnobProps {
    progress: number;
    setProgress: (progress: number) => void;
    min: number;
    max: number;
    step: number;
    labelFormatter: (progress: number) => string;
}

export const Knob = forwardRef<HTMLButtonElement, KnobProps>(
    ({ progress, setProgress, min, max, step }, ref) => {
        const motionProgress = useMotionValue(progress);
        const left = useTransform(motionProgress, [min, max], ['0%', '100%']);

        useEffect(() => {
            motionProgress.set(progress);
        }, [progress, motionProgress]);

        const handleKeyDown = (event: KeyboardEvent<HTMLDivElement | unknown>) => {
            event.preventDefault();
            switch (event.key) {
                case 'PageUp':
                    return setProgress(progress + 5 * step);
                case 'PageDown':
                    return setProgress(progress - 5 * step);
                case 'Home':
                    return setProgress(min);
                case 'End':
                    return setProgress(max);
                case 'ArrowRight':
                case 'ArrowUp':
                    return setProgress(progress + step);
                case 'ArrowLeft':
                case 'ArrowDown':
                    return setProgress(progress - step);
            }
        };

        return (
            <AnimatedKnob
                ref={ref}
                style={{ left }}
                variants={{
                    initial: { zIndex: 0 },
                    hover: { zIndex: 1 },
                    tap: { zIndex: 1 },
                    focus: { zIndex: 1 },
                }}
                initial="initial"
                animate="animate"
                whileHover="hover"
                whileTap="tap"
                whileFocus="focus"
                onKeyDown={handleKeyDown}
            />
        );
    }
);
