// https://github.com/LoopFiftyFour/JS-Connector/blob/c9bb0fc2bedec96cc6d20d4b769b9a777c3093ff/src/core.js#L15
const POSSIBLE_CHAR = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
const USER_ID_LENGTH = 10;

export function generateUserId() {
    let text = '';

    for (let i = 0; i < USER_ID_LENGTH; i++) {
        text += POSSIBLE_CHAR.charAt(Math.floor(Math.random() * POSSIBLE_CHAR.length));
    }

    return text;
}
