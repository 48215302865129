import { ShopTypes } from '~/lib/data-contract';
import { getItemsByFreight } from '../utils/getItemsByFreight';
import { pushToDataLayer } from '../utils/pushToDataLayer';
import { GAEcommerceEvent } from '../utils/types';

export function addShippingInfo(freight?: ShopTypes.BasketFreightResponse) {
    const items = getItemsByFreight(freight);

    if (freight && items) {
        pushToDataLayer({ ecommerce: null });
        pushToDataLayer({
            event: GAEcommerceEvent.AddShippingInfo,
            ecommerce: {
                currency: 'DKK',
                shipping_tier:
                    freight.freightTypeName +
                    (freight.freightProvider ? ' ' + freight.freightProvider : ''),
                value: freight.amount.priceInclVat,
                items,
            },
        });
    }
}
