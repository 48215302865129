// Don't let min knob have a higher or the same value than max knob and vice versa.

import { RangeValue } from '../types';

// Higher order function for initial setup.
export const preventOverlap =
    (valueRange: RangeValue, index: number, min: number, max: number, step: number) =>
    (value: number) => {
        const isFirstKnob = index === 0;
        const firstKnobValue = valueRange[0];
        const lastKnobValue = valueRange[1];

        if (isFirstKnob) {
            const highestAllowedValue = lastKnobValue - step;
            // Crop at top
            const topCroppedValue = Math.min(value, highestAllowedValue);
            // Crop at bottom
            const bottomCroppedValue = Math.max(min, topCroppedValue);
            // Return final value within possible range
            return bottomCroppedValue;
        } else {
            // isLastKnob
            const lowestAllowedValue = firstKnobValue + step;
            // Crop at bottom
            const bottomCroppedValue = Math.max(value, lowestAllowedValue);
            // Crop at top
            const topCroppedValue = Math.min(max, bottomCroppedValue);
            // Return final value within possible range
            return topCroppedValue;
        }
    };
