import shop from '~/lib/api/shop';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';

export default function getOrderStatus(reference: string | undefined, email: string | undefined) {
    if (!reference) {
        return Promise.reject(new Error(Shop.errors.referenceNotFound.message));
    }

    if (!email) {
        return Promise.reject(new Error(Shop.errors.emailNotProvided.message));
    }

    const url = Shop.utils.getUrl(`/order/status/${reference}`);

    url.searchParams.set('email', email);

    return shop.get(url).json<ShopTypes.OrderStatusResponse>();
}
