import { useLocalStorage } from '@rehooks/local-storage';

const useLocalStorageString = <T extends string = string>(
    key: string,
    initial: T | undefined
): [T | undefined, (newValue: T | undefined) => void, () => void] => {
    const [value, setValue, clearValue] = useLocalStorage<T | number | undefined>(key, initial);

    return [value?.toString() as T, setValue, clearValue];
};

export default useLocalStorageString;
