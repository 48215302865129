import { useQuery } from '@tanstack/react-query';
import { DawaTypes } from '~/lib/data-contract';
import { Dawa } from '~/services';

export default function useAutocomplete(query: DawaTypes.AutocompleteListParams) {
    return useQuery(['autocomplete', { query }], () => Dawa.operations.getAutocomplete(query), {
        enabled: !!query?.q,
        cacheTime: 1 * 60 * 1000, // 1 minute
        retry: 1,
        keepPreviousData: true,
    });
}
