import styled from '@emotion/styled';
import { Text } from '../Text';

export const StyledOnlineText = styled(Text)({
    display: 'inline-flex',
    alignSelf: 'baseline',
    alignItems: 'baseline',
    gridRow: '2 / 3',
    gridColumn: '2 / 3',
    justifySelf: 'end',
    whiteSpace: 'nowrap',
});
