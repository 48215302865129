import { useEffect, useState } from 'react';
import { useTranslations } from '~/services';
import { Button } from '~/shared/components/Button';
import { RangeSlider } from '~/shared/components/RangeSlider';
import { useUrlQueryState } from '~/shared/hooks';
import { Input, InputWrapper, Placeholder } from '~/shared/styles/Form';
import { clamp } from '~/shared/utils/helpers';
import { Buttons, Container, Fields, Slider } from './styles';

interface PriceRangeProps {
    max: number;
    min: number;
    name: string;
    onClose?: (selectedFilter?: string) => void;
    step?: number;
    rangeSubscription?: {
        subscribe: (update: () => void, reset: () => void) => void;
        unsubscribe: () => void;
    };
}

const PriceRange = ({
    max: originalMax,
    min: originalMin,
    name,
    onClose,
    step = 1,
    rangeSubscription,
}: PriceRangeProps) => {
    // Use whole numbers only by rounding up/down
    const max = Math.ceil(originalMax);
    const min = Math.floor(originalMin);

    const { translate } = useTranslations();
    const [rangeValues, setRangeValues] = useState({ min, max });
    const [selectedFilters, setSelectedFilters] = useUrlQueryState();

    const priceRangeFilter = JSON.parse(selectedFilters[name]?.[0] || '{}');
    const selectedMax = rangeValues.max || priceRangeFilter.max || max;
    const selectedMin = rangeValues.min || priceRangeFilter.min || min;

    // Subscribe / Unsubscribe to parent updates
    useEffect(() => {
        rangeSubscription?.subscribe(onRangeApplyHandler, onRangeClearHandler);
        return rangeSubscription?.unsubscribe;
    });

    // Set rangeValues on global filter updates
    useEffect(() => {
        setRangeValues({ min: priceRangeFilter.min || min, max: priceRangeFilter.max || max });
    }, [priceRangeFilter.min, priceRangeFilter.max, min, max]);

    const onRangeApplyHandler = () => {
        if (rangeValues.min !== min || rangeValues.max !== max) {
            setSelectedFilters(name, [
                JSON.stringify({
                    min: clamp(Math.min(...Object.values(rangeValues)), min, max),
                    max: clamp(Math.max(...Object.values(rangeValues)), min, max),
                }),
            ]);

            onClose?.(name);
        } else {
            onClose?.();
        }
    };

    const onRangeUpdateHandler = ([newMin, newMax]: [number, number]) => {
        setRangeValues({ min: newMin, max: newMax });
    };

    const onRangeClearHandler = () => {
        setRangeValues({ min, max });
        setSelectedFilters(name, []);
    };

    return (
        <Container>
            <Fields>
                <InputWrapper isHighlighted>
                    <Placeholder isHighlighted>Fra kr.</Placeholder>

                    <Input
                        type="number"
                        inputMode="numeric"
                        value={selectedMin}
                        min={min}
                        max={typeof selectedMax === 'number' ? selectedMax : max}
                        step={step}
                        name={`${name}min`}
                        hideIcon
                        onChange={(event) => {
                            const value = parseFloat(event.target.value);

                            if (isNaN(value)) {
                                onRangeUpdateHandler([min, selectedMax]);
                                return;
                            }

                            onRangeUpdateHandler([value, selectedMax]);
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                onRangeApplyHandler();
                            }
                        }}
                    />
                </InputWrapper>

                <InputWrapper isHighlighted>
                    <Placeholder isHighlighted>Til kr.</Placeholder>

                    <Input
                        type="number"
                        inputMode="numeric"
                        value={selectedMax}
                        min={typeof selectedMin === 'number' ? selectedMin : min}
                        max={max}
                        step={step}
                        name={`${name}max`}
                        hideIcon
                        onChange={(event) => {
                            const value = parseFloat(event.target.value);

                            if (isNaN(value)) {
                                onRangeUpdateHandler([selectedMin, max]);
                                return;
                            }

                            onRangeUpdateHandler([selectedMin, value]);
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                onRangeApplyHandler();
                            }
                        }}
                    />
                </InputWrapper>
            </Fields>

            <Slider>
                <RangeSlider
                    valueRange={[rangeValues.min, rangeValues.max]}
                    setValueRange={onRangeUpdateHandler}
                    min={min}
                    max={max}
                    step={step}
                />
            </Slider>

            <Buttons>
                <Button variant="stroke" noIcon onClick={onRangeClearHandler}>
                    {translate('generic.reset')}
                </Button>

                <Button noIcon onClick={onRangeApplyHandler}>
                    {translate('navigation.filter.results')}
                </Button>
            </Buttons>
        </Container>
    );
};

export default PriceRange;
