import shop from '~/lib/api/shop';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';

export default function setCustomerAddress(
    reference: string | undefined,
    address: ShopTypes.AddressDetails
) {
    if (!reference) {
        return Promise.reject(new Error(Shop.errors.referenceNotFound.message));
    }

    return shop
        .post(Shop.utils.getUrl(`/baskets/${reference}/update/customeraddress`), {
            json: address,
        })
        .json<ShopTypes.BasketResponse>();
}
