import { HTTPError } from 'ky';
import { useMutation } from '@tanstack/react-query';
import { ShopTypes } from '~/lib/data-contract';
import { Shop, useBasketReference } from '~/services';
import { useUserId } from '~/shared/utils/cookie';

export default function useCheckout() {
    const [userId] = useUserId();
    const { data: reference } = useBasketReference();

    return useMutation<ShopTypes.CheckoutQueryResult, HTTPError | Error>(
        ['checkout', { reference }],
        () => Shop.operations.getCheckout({ reference, userId }),
        {
            retry: false,
        },
    );
}
