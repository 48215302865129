import shop from '~/lib/api/shop';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';

export default function setCustomerComment(
    deliveryId: number,
    reference: string | undefined,
    comment?: string
) {
    if (!reference) {
        return Promise.reject(new Error(Shop.errors.referenceNotFound.message));
    }

    return shop
        .post(
            Shop.utils.getUrl(
                `/baskets/${reference}/update/customercomment/${deliveryId}?comment=${comment}`
            )
        )
        .json<ShopTypes.BasketResponse>();
}
