import ky from 'ky';
import { getUserId } from '~/shared/utils/cookie';

const loop54 = ky.create({
    retry: {
        limit: 0,
    },
    hooks: {
        beforeRequest: [
            (request) => {
                request.headers.set('user-id', getUserId());
                request.headers.set('api-version', 'V3');
                // request.headers.set('Loop54-key', ''); // Not used yet since we dont use the administrative api yet
            },
        ],
    },
});

export default loop54;
