import { pushToDataLayer } from '../utils/pushToDataLayer';
import { GACustomEvent } from '../utils/types';

export type GAMenuProps = { first_level: string; second_level?: string; third_level?: string };

export function menu({ first_level, second_level, third_level }: GAMenuProps) {
    pushToDataLayer({
        event: GACustomEvent.Menu,
        first_level: first_level.toLowerCase(),
        second_level: second_level?.toLowerCase(),
        third_level: third_level?.toLowerCase(),
    });
}
