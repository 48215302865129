import { HTTPError } from 'ky';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { UmbracoTypes } from '~/lib/data-contract';
import { Umbraco, Prefetch } from '~/services';
import { minutes } from '~/shared/utils/helpers';
import { sharedQueryClient } from '~/shared/utils/request';

/**
 * Frame hook (footer, settings, and other things that are not page-specific).
 * Will load server side by pre-fetching in data resolver, and fetch new values when locale changes
 * If server side data is not available, data will be fetched client side instead
 */

const defaultPath = '/';

export default function useFrame<SelectType = Frame>(select?: (data: Frame) => SelectType) {
    const { locale } = useRouter();

    return useQuery<Frame, HTTPError | Error, SelectType>(
        ['frame', locale, defaultPath],
        ({ signal }) => Umbraco.operations.getFrame(signal, defaultPath),
        {
            retry: false,
            select,
            keepPreviousData: true,
            staleTime: minutes(10),
            cacheTime: minutes(1),
            refetchOnWindowFocus: true,
        },
    );
}

export async function prefetchFrame({ locale, queryClient }: PrefetchProps) {
    await sharedQueryClient.fetchQuery(['frame', locale, defaultPath], ({ signal }) =>
        Umbraco.operations.getFrame(signal),
    );

    return queryClient.setQueryData(
        ['frame', locale, defaultPath],
        () => sharedQueryClient.getQueryData(['frame', locale, defaultPath]),
        {
            updatedAt: sharedQueryClient.getQueryState(['frame', locale, defaultPath])
                ?.dataUpdatedAt,
        },
    );
}

interface PrefetchProps extends Prefetch {
    locale: string | undefined;
}

type Frame = UmbracoTypes.IFrame;
