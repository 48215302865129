import { HTTPError } from 'ky';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ShopTypes } from '~/lib/data-contract';
import { Shop, useBasketReference } from '~/services';
import { useUserId } from '~/shared/utils/cookie';
import { useBasketTimer } from '~/shared/utils/storage';

export default function useSetBasketItem() {
    const queryClient = useQueryClient();
    const [userId] = useUserId();
    const { data: reference } = useBasketReference();
    const [, setTimer] = useBasketTimer();

    return useMutation<Basket, HTTPError | Error, Arguments, Context>(
        (props) => Shop.operations.setBasketItem({ reference, userId, ...props }),
        {
            onMutate: async () => {
                await queryClient.cancelQueries(['basket', { reference }]);

                return {
                    previousBasket: queryClient.getQueryData<Basket>(['basket', { reference }]),
                };
            },
            onError: (_error, _props, context) => {
                if (context?.previousBasket) {
                    queryClient.setQueryData<Basket>(
                        ['basket', { reference }],
                        context.previousBasket,
                    );
                }
            },
            onSuccess: (data) => {
                queryClient.setQueryData(['basket', { reference: data.basketReference }], data);
                setTimer(data.updated.toString());
            },
        },
    );
}

type Basket = ShopTypes.BasketResponse;

interface Arguments extends ShopTypes.BasketItemOptions {
    itemNo: string | undefined;
}

interface Context {
    previousBasket: Basket | undefined;
}
