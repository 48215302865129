import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const Container = styled.ul(({ theme }) => ({
    display: 'flex',
    gap: theme.space[1],
    marginBottom: theme.space[2],
    msOverflowStyle: 'none',
    overflowX: 'scroll',
    scrollbarWidth: 'none',
    '&:scrollbar': {
        display: 'none',
    },
    whiteSpace: 'nowrap',
    width: '100%',
    order: 2,

    [breakpoints.sm]: {
        order: 1,
        flexWrap: 'wrap',
        marginBottom: theme.space[6],
        overflowX: 'auto',
        whiteSpace: 'unset',
    },
}));
