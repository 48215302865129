import { EntitiesByAttributeRequest, EntitiesRequest, Loop54, useFilterEntities } from '~/services';

export default function useFilterResults(
    params: EntitiesByAttributeRequest | EntitiesRequest | undefined
) {
    return useFilterEntities(params, (data) => ({
        ...data,
        results: Loop54.utils.reduceLoop54Product(data.results),
    }));
}
