import { Events, useEmitter, useListener } from '..';
import type { SignalEvent } from '../types';

export function useFocusSearchFieldEmitter() {
    return useEmitter<boolean>(Events.FocusSearch);
}

export function useFocusSearchFieldListener(onSignal: SignalEvent<boolean>) {
    return useListener<boolean>({
        eventName: Events.FocusSearch,
        onSignal,
    });
}
