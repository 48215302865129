import { useQuery } from '@tanstack/react-query';
import { HTTPError } from 'ky';
import { useSession } from 'next-auth/react';
import { ShopTypes } from '~/lib/data-contract';
import { Shop, useUserProfile } from '~/services';

export default function useAccountInfo() {
    const { data: profile } = useUserProfile();
    const { data } = useSession();
    const customerId = profile?.customer?.id ?? null;
    const token = data?.accessToken ?? null;

    return useQuery<ShopTypes.CustomerInfoHeader[], HTTPError | Error>(
        ['accountInformation', { customerId, token }],
        ({ signal }) =>
            Shop.operations.getAccountInfo({
                signal,
                customerId,
                token,
            }),
        {
            enabled: Boolean(data?.accessToken) && Boolean(customerId),
        },
    );
}
