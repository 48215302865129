export const black = '#000000';
export const white = '#FFFFFF';

export const brandBlue = '#013d7a';
export const darkBlue = '#022546';

export const nightBlack = '#101820';
export const offWhite = '#fcfcfc';

export const grey90 = '#5f646b';
export const grey70 = '#888a8f';
export const grey60 = '#a8acad';
export const grey50 = '#c9cbca';
export const grey40 = '#e3e5e4';
export const grey30 = '#eeeeec';
export const grey10 = '#f5f5f3';
export const grey05 = '#fafaf9';

export const steelBlue = '#86b4cb';
export const petrolGreen = '#9ebfb9';
export const orange = '#F48F49';
export const mossDarkBrown = '#736d56';
export const steelGrey = '#d3dce6';
export const petrolGrey = '#cfdad8';
export const mossLightBrown = '#b7a57a';
export const thunderBlack = '#231F20';

export const primary = brandBlue;
export const secondary = darkBlue;

export const success = '#00cc66';
export const warning = '#fed218';
export const alert = '#ec3f3f';

export const campaign = '#e4ddd3';

export const searchSuggestion = '#06c';
