import { HTTPError } from 'ky';
import { useState } from 'react';
import { QueryCache, QueryClient } from '@tanstack/react-query';
import { ShopTypes } from '~/lib/data-contract';
import { useBasketReference, useBasketTimer } from '~/shared/utils/storage';

// Will share data across pages and users (eg. DONT fetch user specific data)
export const sharedQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 30 * 1000, // 30 seconds
            cacheTime: 60 * 1000, // 1 minutes
        },
    },
});

export const useSetupQueryClient = () => {
    const [reference, setReference, deleteReference] = useBasketReference();
    const [, setTimer] = useBasketTimer();

    const [queryClient] = useState(
        // This state ensures that data is not shared between different users and requests,
        // while still only creating the QueryClient once per component lifecycle.
        () => {
            const queryClient = new QueryClient({
                defaultOptions: {
                    queries: {
                        refetchOnWindowFocus: false, // Disabled since we don't rely on realtime data
                        staleTime: 5 * 60 * 1000, // 5 minutes
                    },
                },
                queryCache: new QueryCache({
                    onSuccess: (data, query) => {
                        if (query?.meta?.type === 'getBasket') {
                            const { basketReference, updated, basketState } =
                                data as ShopTypes.BasketResponse;

                            // Delete completed basket from localStorage if Completed
                            if (
                                basketState === ShopTypes.BasketStates.Completed &&
                                reference === basketReference
                            ) {
                                deleteReference();
                                queryClient.removeQueries(['basket', { reference }]);
                            }
                            // Update localStorage reference if basket is not Completed and actually changed
                            else if (reference !== basketReference) {
                                setReference(basketReference);
                            }

                            // Any changes in timer should trigger other tabs to update
                            setTimer(updated.toString());

                            // TODO: Add types
                            // Remove previous basket queries
                            // queryClient.removeQueries(['basket'], {
                            //     predicate: (query) =>
                            //         query.queryKey[0] === 'basket' &&
                            //         !!query.queryKey[1]?.reference &&
                            //         query.queryKey[1]?.reference !== basketReference,
                            // });
                        }
                    },
                    onError: (error, query) => {
                        // If basket not found, we clear the cached basket and try again. This will trigger a new basket to be created.
                        // Other browser tabs will see the localStorage change and use that reference to fetch the basket.
                        if (
                            query?.meta?.type === 'getBasket' &&
                            error instanceof HTTPError &&
                            error.response?.status === 404
                        ) {
                            deleteReference();
                            queryClient.removeQueries(['basket']);
                        }
                    },
                }),
            });

            return queryClient;
        },
    );

    return queryClient;
};
