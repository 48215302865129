import { Loop54Types, UmbracoTypes } from '~/lib/data-contract';
import {
    Loop54ProductAttributes,
    Facet,
    Facets,
    DefaultFacets,
    SortOption,
    SortOptions,
} from '~/services';

export const EXCLUDED_FILTER_LIST = ['q', 'page', 'sort'];
export const EXCLUDED_FACET_LIST = ['ContextGroup'];

export function getProductFilter() {
    return { type: 'type', value: 'Product' };
}

export const getLoopFacets = (
    allFacets: DefaultFacets | undefined,
    defaultFacets: Facets | undefined,
    pageFilterConfig: UmbracoTypes.FilterAdmin['filterAdmin'] | undefined = undefined,
    filters: Record<string, string[]> | undefined = undefined
): (DistinctFacet | RangeFacet)[] => {
    // Use facets configured on page or fall back default facets
    const pageFacets = pageFilterConfig?.facets?.length
        ? pageFilterConfig?.facets
              ?.map(
                  // Get "full" facet from base facets
                  (facetKey) =>
                      allFacets?.[
                          Loop54ProductAttributes[facetKey as keyof typeof Loop54ProductAttributes]
                      ]
              )
              // Remove any facets that are undefined because they didn't exist in base facets
              .filter((facet) => facet)
        : defaultFacets ?? [];

    const facets = Object.values(pageFacets)
        .filter((facet) => facet)
        .filter(
            // Remove any facets that are being filtered upon - i.e. exists in page filters and has non-empty array/values
            (facet) =>
                !Object.keys(filters ?? {}).includes(facet!.key) || !filters?.[facet!.key]?.length
        )
        .reduce(
            (result, facet) => ({
                ...result,
                [facet!.key]: facet!,
            }),
            {} as Record<string, Facet>
        );

    // Map facets to Loop model (array)
    const loopFacets = Object.values(facets).map((facet) => {
        const loopFacet = {
            attributeName: facet.key,
            type: facet.type,
        };

        if (facet.type === 'distinct') {
            (loopFacet as Loop54Types.DistinctFacetParameter).sortBy = [
                { type: 'item', order: 'asc' },
            ];
        }

        return loopFacet;
    });

    return loopFacets;
};

/**
 * Maps the selected facet values from URL to the facets
 * @param loopFacets Loop facet models array
 * @param filtersFromUrl URL facet values
 */
export const setFacetSelections = (
    loopFacets: (DistinctFacet | RangeFacet)[],
    filtersFromUrl: Record<string, string[]>
): void => {
    for (const key of Object.keys(filtersFromUrl)) {
        const match = loopFacets.find((facet) => facet.attributeName === key);

        // Skip if doesn't exist in known/available facets
        if (!match) {
            continue;
        }

        const selectedValues = filtersFromUrl[key];

        switch (match.type) {
            case 'distinct':
                match.selected = selectedValues;
                break;
            case 'range':
                match.selected = selectedValues?.length ? JSON.parse(selectedValues[0]) : undefined;
                break;
        }
    }
};

/**
 * Finds the matching sort option and maps it to
 * @param allSortOptions All sort options (from defaults)
 * @param sort The selected/active sort option key
 * @returns
 */
export const getSortBy = (allSortOptions: SortOptions | undefined, sort: string | undefined) => {
    const sortOption = Object.values(allSortOptions ?? {})?.find(
        (option) => sort && option.key === sort
    );

    const sortBy = sortOption?.sorts?.map(mapSortBy) ?? [];

    return sortBy as SortOption[];
};

const mapSortBy = (sort: SortOption): SortOption => {
    const newSort = { ...sort };

    if (!sort.order) {
        delete newSort.order;
    }

    if (!sort.attributeName) {
        delete newSort.attributeName;
    }

    return newSort;
};

type DistinctFacet = Loop54Types.DistinctFacetParameter;
type RangeFacet = Loop54Types.RangeFacetParameter;
