import { HTTPError } from 'ky';
import { useQuery } from '@tanstack/react-query';
import { ShopTypes } from '~/lib/data-contract';
import { ParcelServiceProvider, Shop } from '~/services';

export default function useParcelServicePoints({
    provider = ParcelServiceProvider.PostNord,
    query,
    enabled,
}: Props) {
    return useQuery<ShopTypes.ParcelServicePointQueryResult, HTTPError | Error>(
        ['parcelServicePoints', provider, { query }],
        () => Shop.operations.getParcelServicePoints(provider, query),
        {
            enabled,
            retry: 1,
        },
    );
}

interface Props {
    provider: ParcelServiceProvider;
    query: ShopTypes.ParcelServicePointQuery;
    enabled?: boolean;
}
