import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { settings } from './settings';

export const Container = styled(motion.div)({
    width: `calc(100% - ${settings.buttonSize}px)`,
    height: settings.buttonSize + 2 * settings.expand,
    position: 'relative',
    margin: `0 ${settings.buttonSize / 2}px`,
});
