import { create as createBatcher, windowedFiniteBatchScheduler } from '@yornaath/batshit';
import { Shop } from '~/services';

type QueryProps = {
    sku: string;
    token: string | undefined | null;
    customerId: string | null;
};

const pricesBatcher = createBatcher({
    fetcher: async (queries: QueryProps[]) => {
        const customerId = queries[0].customerId;
        const token = queries[0].token;
        const skus = queries.map((query) => query.sku);

        return await Shop.operations.getPrices(undefined, skus, customerId, token);
    },
    resolver: (items, query) => {
        const resolvedItem = items.find((item) => item.itemNumber === query.sku);
        if (!resolvedItem) throw new Error('Could not resolve: ' + query.sku);
        return resolvedItem;
    },
    scheduler: windowedFiniteBatchScheduler({
        windowMs: 10,
        maxBatchSize: 30
    }),
});

export default pricesBatcher;
