export function pushToDataLayer(obj: Record<string, unknown>, maxWait = 5000) {
    return new Promise<void>((resolve) => {
        const dataLayer = (window as any).dataLayer || [];
        if (!Array.isArray((window as any).dataLayer)) {
            (window as any).dataLayer = dataLayer;
        }

        const done = () => {
            // If given item has own eventCallback, fire that too
            resolve();
        };

        // If GTM hasn't loaded at all (ie. adblock), fire callback immediately
        if (!(window as any).google_tag_manager) {
            done();
        }

        // Fail safe: If GTM eventCallback doesn't fire before maxWait time, trigger the callback anyway
        setTimeout(done, maxWait);
        dataLayer.push({
            ...obj,
            eventCallback: done,
        });
    });
}
