import { useEffect, useRef } from 'react';
import { isBrowser } from '../utils/platform';

const isDevelopment = process.env.NODE_ENV === 'development';

// This script assumes there is a DIV with the id "cicc-template"
export function CookiePolicyScript() {
    const scriptRef = useRef<HTMLDivElement>(null);

    // Inject script manually to ensure correct placement and content loading in DOM
    useEffect(() => {
        const currentTarget = scriptRef?.current;

        if (!currentTarget) {
            return;
        }

        const script = globalThis.document.createElement('script');

        script.defer = true;
        script.id = 'CookiePolicy';
        script.src = '//policy.app.cookieinformation.com/cid.js';
        script.type = 'text/javascript';
        script.setAttribute('data-culture', 'DA');

        currentTarget.appendChild(script);

        return () => {
            currentTarget.removeChild(script);
        };
    }, [scriptRef]);

    if (isDevelopment || !isBrowser) {
        // Cookieinformation can only be tested on whitelisted domains
        return null;
    }

    return <div ref={scriptRef}></div>;
}
