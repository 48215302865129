export const config = {
    MAX_DELIVERY_DAYS_DIFFERENCE: 60,
    PRODUCTS_PER_PAGE: 30,
    SMART_SUPP_KEY: '02216f877f57c23bada0e0bc9a4965b38bfead49',
    ACTIVE_CAMPAIGN_ACCOUNT: '223699663',
    GTM_ID: 'GTM-TW3MSVL',
};

export default function useConfig() {
    return config;
}
