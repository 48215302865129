import shop from '~/lib/api/shop';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';

type Args = {
    customerId: string | null;
    email: string | null;
    token: string | null;
};

export default function getExportPriceList({ customerId, email, token }: Args) {
    if (!customerId || !email) {
        return Promise.reject(new Error(Shop.errors.exportPriceList.message));
    }
    return shop
        .post(Shop.utils.getUrl(`/customers/${customerId}/export-price-list?email=${email}`), {
            json: {
                customerId,
                email,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .json<ShopTypes.ProblemDetails>();
}
