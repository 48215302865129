import shop from '~/lib/api/shop';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';
import { minutes } from '~/shared/utils/helpers';

interface GetCheckoutArguments extends ShopTypes.CheckoutOptions {
    reference: string | undefined;
}

export default function getCheckout({ reference, ...rest }: GetCheckoutArguments) {
    if (!reference) {
        return Promise.reject(new Error(Shop.errors.referenceNotFound.message));
    }

    return shop
        .post(Shop.utils.getUrl(`/checkout/checkout/${reference}`), {
            json: rest as ShopTypes.CheckoutOptions,
            timeout: minutes(5),
        })
        .json<ShopTypes.CheckoutQueryResult>();
}
