import shop from '~/lib/api/shop';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';

export default function setDeliveryDate({
    deliveryDate,
    deliveryId,
    reference,
}: SetDeliveryMethodArguments) {
    if (!reference) {
        return Promise.reject(new Error(Shop.errors.referenceNotFound.message));
    }

    const url = Shop.utils.getUrl(`/baskets/${reference}/update/deliverydate/${deliveryId}`);

    if (deliveryDate) {
        url.searchParams.set('deliveryDate', deliveryDate.toDateString());
    }

    return shop.post(url).json<ShopTypes.BasketResponse>();
}

interface SetDeliveryMethodArguments {
    deliveryDate?: Date;
    deliveryId: number;
    reference: string | undefined;
}
