import { useQuery } from '@tanstack/react-query';
import { HTTPError } from 'ky';
import { useSession } from 'next-auth/react';
import { ShopTypes } from '~/lib/data-contract';
import { Shop, useUserProfile } from '~/services';

export default function useMyOrders() {
    const { data: profile } = useUserProfile();
    const { data } = useSession();
    const token = data?.accessToken ?? null;
    const customerId = profile?.customer?.id ?? null;

    return useQuery<ShopTypes.OrderHeader[], HTTPError | Error>(
        ['myOrders', { customerId, token }],
        ({ signal }) => Shop.operations.getMyOrders({ signal, customerId, token }),
        {
            enabled: Boolean(data?.accessToken),
        },
    );
}
