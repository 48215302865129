import { ShopTypes } from '~/lib/data-contract';
import { Loop54Product, useTranslations } from '~/services';
import { ProductOnlineStatus } from '~/shared/components/ProductOnlineStatus';
import { Stock } from './styled';

interface Props {
    onlineStatus: Loop54Product['StockStatus'];
    warehouseStatus: Loop54Product['Stocks_Object'];
    freight: Loop54Product['Freight_Object'];
    translate: ReturnType<typeof useTranslations>['translate']; // Pass hooks in to avoid to many listeners to react query
    className?: string;
}

export const StockStatus = ({
    warehouseStatus,
    onlineStatus,
    className,
    freight,
    translate,
}: Props) => {
    const isOnlyRetrieval = freight?.[0]?.FreightType === ShopTypes.FreightType.OnlyRetrieval;
    const isOnlyOnline =
        freight?.[0]?.FreightType === ShopTypes.FreightType.DirectDelivery &&
        !warehouseStatus?.some((w) => w.Quantity > 0);
    const warehouseStockStatus = useWarehouseStockStatus(warehouseStatus);

    return (
        <Stock className={className}>
            {!isOnlyRetrieval ? (
                <ProductOnlineStatus
                    text={translate('product.availableOnline')}
                    status={onlineStatus}
                />
            ) : null}

            {!isOnlyOnline ? (
                <ProductOnlineStatus
                    text={translate('product.availableInStore')}
                    status={warehouseStockStatus}
                />
            ) : null}
        </Stock>
    );
};

// Get stock status based on warehouse with the most stock
function useWarehouseStockStatus(warehouseStatus: Props['warehouseStatus']) {
    const maximumStock = warehouseStatus
        ? Math.max(...warehouseStatus.map((stock) => stock.Quantity))
        : 0;

    if (maximumStock > 5) {
        return ShopTypes.StockStatus.InStock;
    }

    if (maximumStock >= 1) {
        return ShopTypes.StockStatus.LowStock;
    }

    return ShopTypes.StockStatus.OutOfStock;
}
