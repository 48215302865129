import umbraco from '~/lib/api/umbraco';
import { TranslationsTypes } from '~/lib/data-contract';
import { Umbraco } from '~/services';

export default function getTranslations(signal: AbortSignal | undefined, locale?: string) {
    const url = Umbraco.utils.getUrl('/content/translations');

    if (locale) {
        url.searchParams.set('culture', locale);
    }

    return umbraco.get(url, { signal }).json<Translations>();
}

type Translations = {
    [key in TranslationsTypes.TranslationKey]: string;
};
