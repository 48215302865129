import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { breakpoints } from '~/theme';
import { TextVariants } from '.';

export type StyledTextProps = {
    variant?: TextVariants;
};

export const StyledText = styled.p<StyledTextProps>(
    ({ theme }) => ({
        fontFamily: theme.fonts.body,
        color: theme.colors.black,

        img: {
            maxWidth: '100%',
            height: 'auto',
        },
    }),
    switchProp('as', {
        i: {
            fontStyle: 'italic',
        },
        em: {
            fontStyle: 'italic',
        },
        strong: ({ theme }) => ({
            fontWeight: theme.fontWeights.medium,
        }),
        b: ({ theme }) => ({
            fontWeight: theme.fontWeights.medium,
        }),
    }),
    switchProp(
        'variant',
        {
            paragraph: ({ theme }) => ({
                fontSize: theme.fontSizes.rat,
                lineHeight: theme.lineHeights.fox,
                [breakpoints.md]: {
                    fontSize: theme.fontSizes.guinea,
                    lineHeight: theme.lineHeights.dog,
                },
            }),
            caption: ({ theme }) => ({
                fontSize: theme.fontSizes.beetle,
                lineHeight: theme.lineHeights.frog,
                [breakpoints.lg]: {
                    fontSize: theme.fontSizes.mouse,
                    lineHeight: theme.lineHeights.chicken,
                },
            }),
            display1: ({ theme }) => ({
                fontWeight: theme.fontWeights.medium,
                fontSize: theme.fontSizes.hippo,
                lineHeight: theme.lineHeights.elephant,
                [breakpoints.lg]: {
                    fontSize: theme.fontSizes.elephant,
                    lineHeight: theme.lineHeights.mammoth,
                },
            }),
            display2: ({ theme }) => ({
                fontWeight: theme.fontWeights.medium,
                fontSize: theme.fontSizes.bear,
                lineHeight: theme.lineHeights.hippo,
                [breakpoints.lg]: {
                    fontSize: theme.fontSizes.hippo,
                    lineHeight: theme.lineHeights.elephant,
                },
            }),
            display3: ({ theme }) => ({
                fontWeight: theme.fontWeights.medium,
                fontSize: theme.fontSizes.wolf,
                lineHeight: theme.lineHeights.bear,
                [breakpoints.lg]: {
                    fontSize: theme.fontSizes.bear,
                    lineHeight: theme.lineHeights.hippo,
                },
            }),
            display4: ({ theme }) => ({
                fontWeight: theme.fontWeights.medium,
                fontSize: theme.fontSizes.cat,
                lineHeight: theme.lineHeights.wolf,
                [breakpoints.lg]: {
                    fontSize: theme.fontSizes.wolf,
                    lineHeight: theme.lineHeights.bear,
                },
            }),
            display5: ({ theme }) => ({
                fontWeight: theme.fontWeights.medium,
                fontSize: theme.fontSizes.squirrel,
                lineHeight: theme.lineHeights.cat,
                [breakpoints.lg]: {
                    fontSize: theme.fontSizes.cat,
                    lineHeight: theme.lineHeights.wolf,
                },
            }),
            display6: ({ theme }) => ({
                fontWeight: theme.fontWeights.medium,
                fontSize: theme.fontSizes.rat,
                lineHeight: theme.lineHeights.cat,
                [breakpoints.lg]: {
                    fontSize: theme.fontSizes.squirrel,
                    lineHeight: theme.lineHeights.cat,
                },
            }),
            display6Discount: ({ theme }) => ({
                fontWeight: theme.fontWeights.medium,
                fontSize: theme.fontSizes.rat,
                lineHeight: theme.lineHeights.cat,
                color: theme.colors.alert, // Apply the alert color here
                [breakpoints.lg]: {
                    fontSize: theme.fontSizes.squirrel,
                    lineHeight: theme.lineHeights.cat,
                },
            }),

            // New UI
            uiBtn: ({ theme }) => ({
                fontWeight: theme.fontWeights.medium,
                fontSize: theme.fontSizes.mouse,
                lineHeight: theme.lineHeights.mouse,
            }),
            uiInputField: ({ theme }) => ({
                fontWeight: theme.fontWeights.medium,
                fontSize: theme.fontSizes.mouse,
                lineHeight: theme.lineHeights.mouse,
            }),
            uiLinks: ({ theme }) => ({
                fontWeight: theme.fontWeights.normal,
                fontSize: theme.fontSizes.mouse,
                lineHeight: theme.lineHeights.mouse,

                [breakpoints.lg]: {
                    fontSize: theme.fontSizes.rat,
                    lineHeight: theme.lineHeights.rat,
                },
            }),
            uiSplashes: ({ theme }) => ({
                fontWeight: theme.fontWeights.normal,
                fontSize: theme.fontSizes.beetle,
                lineHeight: theme.lineHeights.beetle,
            }),
        },
        ({ theme }) => ({
            fontSize: theme.fontSizes.rat,
            lineHeight: theme.lineHeights.fox,

            [breakpoints.md]: {
                fontSize: theme.fontSizes.guinea,
                lineHeight: theme.lineHeights.dog,
            },
        })
    )
);
