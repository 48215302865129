import { pushToDataLayer } from '../utils/pushToDataLayer';
import { GAEcommerceEvent, GAEcommerceItem } from '../utils/types';

export function removeFromCart(items: GAEcommerceItem[], value: number) {
    pushToDataLayer({ ecommerce: null });
    pushToDataLayer({
        event: GAEcommerceEvent.RemoveFromCart,
        ecommerce: {
            currency: 'DKK',
            value,
            items,
        },
    });
}
